import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Row,
  Col, 
  Form,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import ComicsButton from "../../custom/widget/comics/ComicsButton";

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}



class LoginModal extends React.Component<ILoginModalProps> {

    handleSubmit = (event) => {
    const { handleLogin } = this.props;
    let form=event.target;
    let remember=(form["rememberMe"].value==="on"?"true":form["rememberMe"].value==="off"?"false":form["rememberMe"].value);
    handleLogin(form["username"].value, form["password"].value, remember);
  };

  handleClose = () => {
    window.location.href = "/";
  };

  render() {
    const { loginError } = this.props;
    return (
      <Modal
      style={{"top":"200px"}}
        isOpen={this.props.showModal}
        toggle={() => {
          window.location.href = "/";
        }}
        backdrop="static"
        id="login-page"
        autoFocus={false}
      >
        <Form onSubmit={this.handleSubmit}>
          <ModalHeader
            id="login-title"
            toggle={() => {
              window.location.href = "/";
            }}
          >
            Connexion
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                {loginError ? (
                  <Alert color="danger"><strong>Erreur lors de la connexion!</strong> Veuillez
                      vérifier vos informations puis réessayez.
                  </Alert>
                ) : null}
              </Col>
              </Row>
              <Row>
              <Col md="12">
                <Label>Identifiant</Label>
                <Input
                  name="username"
                  label="Identifiant"
                  placeholder="placeholder"
                  required
                  autoFocus
                />
                </Col>
                </Row>
                <Row>
                <Col md="12">
                <Label>Mot de passe</Label>
                <Input
                  name="password"
                  type="password"
                  label="Mot de passe"
                  placeholder="placeholder"
                  required
                />
                </Col>
                </Row>
                <Row>
                <Col md="12">
                    <Input style={{"margin":"3px"}} type="checkbox" name="rememberMe"/>{" "}
                  <Label>Rester connecté</Label>
                
              </Col>
            </Row>
            <div className="mt-1">&nbsp;</div>
            <Link to="/reset/request">Mot de passe oublié</Link>
          </ModalBody>
          <ModalFooter>
            <div className="width100Perc">
              <span className="loginButtonSpan">
                <ComicsButton title={"Annuler"} onClick={this.handleClose} />
              </span>
              <span className="loginButtonSpan">
                <ComicsButton title={"Valider"} />
              </span>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default LoginModal;
