require("isomorphic-fetch");
require("es6-promise").polyfill();
const wikiFetch = async searchQuery => {
  try {
    const config: RequestInit = {
      method: "GET",
      mode: "cors"
    };
    const result = await fetch("/api/proxy/wiki/" + searchQuery, config);
    if (result.status !== 200) {
      return Promise.reject(result.statusText);
    }
    return await result.json();
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export default wikiFetch;
