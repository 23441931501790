
import GeekiLayout from "./GeekiLayout";

  export const geekiCenteredLayout = (title,component) =>
(
  <GeekiLayout title={title} component={<div className="centered-out">
  <div className="centered-in">
  {component}
  </div>
</div>}/>);
