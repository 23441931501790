import pick from "lodash/pick";
import { IGeeki } from "../model/geeki.model";
import { IAdminNews } from "../model/news.model";

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(
    k =>
      !(entity[k] instanceof Object) ||
      (entity[k]["id"] !== "" && entity[k]["id"] !== -1)
  );

  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) =>
  idList
    .filter((entityId: any) => entityId !== "")
    .map((entityId: any) => ({ id: entityId }));

export const getGeekiByLogin = (geekies: ReadonlyArray<IGeeki>, geekiLogin: string) =>
  geekies.filter((geeki: IGeeki) => geeki.login === geekiLogin)[0];

  export const getAdminGeekiByNewsLogin = (geekies: ReadonlyArray<IAdminNews>, geekiLogin: string) =>
  geekies.filter((entity: IAdminNews) => entity.entity.login === geekiLogin)[0];