import React from "react";
import ReactDOM from 'react-dom';
import { TextareaAutosize } from "@mui/material";
import { Button, Col, Row } from "reactstrap";


interface IProps {
    ref: any,
    onChange: any,
    maxRows: number,
    minRows?: number,
    defaultValue ?: string
}

interface IState {
  }

export default class DescriptionEditor extends React.Component<IProps,IState> {

    htmlRef;
    textRef;

    constructor(props: any) {
        super(props);

        this.htmlRef = React.createRef();
        this.textRef = React.createRef();
    }

     addP = () =>{
        this.textRef.current.value=this.textRef.current.value+"<p></p>";
    }
    addUL = () =>{
        this.textRef.current.value=this.textRef.current.value+"<p><ul><li></li><li></li><li></li></ul></p>";
    }

    setHtml(html){
        this.htmlRef.current.value=html;
        const refShortNode = ReactDOM.findDOMNode(this.htmlRef.current);
        while (refShortNode.firstChild) {
            refShortNode.removeChild(refShortNode.firstChild);
        }
        refShortNode.appendChild(html);
    }
    setText(text){
        this.textRef.current.value=text;
    }

    render(){

        return (<Row className="justify-content-center"
        style={{minHeight:"100px"}}>
            <Col md="8">
                <Button onClick={this.addP} className='editorButton'>{'<p/>'}</Button>
                <Button onClick={this.addUL} className='editorButton'>{'<ul/>'}</Button>
                <TextareaAutosize
                    className="textArAuDescription"
                    ref={this.textRef}
                    onChange={this.props.onChange}
                    maxRows={this.props.maxRows}
                    minRows={this.props.minRows}
                    defaultValue={this.props.defaultValue}
                    style={{ width: "100%" }}/>
            </Col>
            <Col md="4">
                <div ref={this.htmlRef} style={{background:"lightgrey", width:"100%"}}/>
            </Col>
        </Row>);
    }
}