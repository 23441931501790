import "../footer/Footer.scss";

import React from "react";

export default class Copyright extends React.Component {
  render() {
    return (
        <div className="footer-link" key={"crd" + Math.random()}>
          Geeki @2024
        </div>
    );
  }
}
