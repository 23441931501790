import "./Playlist.scss";
import "../comics/Comics.scss";

import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';

import ModernComicsCell from "../../../custom/widget/comics/ModernComicsCell";
import { getEntityByPlaylistId } from "../../../reducers/video.reducer";
import SkeletonImage from "../image/SkeletonImage";
import GeekiPlaylistScroller2 from "./GeekiPlaylistScroller2";
import { imgUrl } from "../../../shared/util/url-utils";

interface IProps extends StateProps, DispatchProps{
  playlistId: string;
  playlistName?: string;
  name: string;
  loadFirst: boolean;
  source: string;
  className?: string;
  isInProduction:boolean;
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  loaded: boolean;
  error: boolean;
  expanded: boolean;
  items: any[];
  videoList?: any;
}

class GeekiPlaylist extends React.Component<Props,IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      items: [],
      expanded: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if (this.state.videoList === undefined) {
      const vList: any = await (this.props as any).getEntityByPlaylistId(
        (this.props as any).playlistId
      );
      const videoList = vList.value.data;

      this.setState({
        loaded: true,
        videoList: videoList
      });
    }
  };
  showHidePlaylist = () => {
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
  }

  render() {
    const items1 = [];
    let imgUrl;
    const videoList = this.state.videoList;
    if (videoList !== undefined && videoList.length > 0) {
      const value = (this.props as any).loadFirst
        ? videoList[0]
        : videoList[Math.floor(Math.random() * videoList.length)];
      imgUrl = this.getImgUrl(value);
    } else {
      return "";
    }
    const imgCss = this.state.expanded ? " expanded" : "";

    if (imgUrl !== undefined) {
      items1.push(
        <div
          className={"playlistContainer" + imgCss}
          key={"gp" + Math.random()}
        >
          <button onClick={this.showHidePlaylist} className={"ytPreview" + imgCss}>
            <SkeletonImage
              key={"skelImgYoutubePlaylist" + (this.props as any).playlistId}
              src={imgUrl}
              height={195}
              width={320}
            />
          </button>
          {videoList && (
            <div
              key={"gpdd" + Math.random()}
              className="videoCount"
              title={videoList.length + " vidéos"}
            >
              {videoList.length}
            </div>
          )}
          {(this.props as any).playlistName && (
            <button onClick={this.showHidePlaylist} className={"ytPreview" + imgCss}>
              <div key={"gpddd" + Math.random()} className="playlistContainer1">
                {" "}
                {(this.props as any).playlistName}{" "}
              </div>
            </button>
          )}
          <GeekiPlaylistScroller2
            key="youtubePlaylistScroller"
            isInProduction={this.props.isInProduction}
            videoList={this.state.videoList}
            expanded={this.state.expanded}
            closeButton={
              <span
                className="ytPlaylistPreviewScrollerClose"
                onClick={this.showHidePlaylist}
              >
                Fermer
              </span>
            }
            loadFirst={(this.props as any).loadFirst}
          />
        </div>
      );
    }

    const content = (
      <div className="playlist-content" key={"gpc" + Math.random()}>
        <article className="comic">{items1}</article>
      </div>
    );
    return (
      <ModernComicsCell
        fullWidth={true}
        title={(this.props as any).name}
        content={content}
        className={"playlistComicsCell " + (this.props as any).className}
      />
    );
  }

  getAlt() {
    if ("GP" === (this.props as any).source) {
      return "Geekipédia playlist";
    } else if ("IN" === (this.props as any).source) {
      return "Geekipédia Archives INA";
    } else if ("YT" === (this.props as any).source) {
      return "Geekipédia réseau social Youtube";
    } else {
      return "";
    }
  }

  getImgUrl(video) {
    if ("GP" === (this.props as any).source) {
      return imgUrl("/content/images/geekiplaylist.png",this.props.isInProduction);
    } else if ("IN" === (this.props as any).source) {
      return imgUrl("/content/images/social/ina.svg",this.props.isInProduction);
    } else if ("YT" === (this.props as any).source) {
      return "https://i1.ytimg.com/vi/" + video.videoId + "/mqdefault.jpg";
    } else {
      return "";
    }
  }
  getTitle() {
    if ("GP" === (this.props as any).source) {
      return "Vidéos Geeki";
    } else if ("IN" === (this.props as any).source) {
      return "Vidéos INA";
    } else if ("YT" === (this.props as any).source) {
      return "Vidéos Youtube";
    } else {
      return "";
    }
  }
  getClassName() {
    if ("GP" === (this.props as any).source) {
      return "social pl geeki";
    } else if ("IN" === (this.props as any).source) {
      return "social pl ina";
    } else if ("YT" === (this.props as any).source) {
      return "social pl youtube";
    } else {
      return "";
    }
  }
}
const mapStateToProps = ( IRootState) => ({});

const mapDispatchToProps = {
  getEntityByPlaylistId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GeekiPlaylist));
