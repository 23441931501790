import "./admin.scss";
import React from "react";
import { connect } from "react-redux";
import { getAdminDataQualityEntities } from "../../reducers/admin-reducer";
import { IRootState } from "../../reducers";
import { adminLayout } from "./util/admin-utils";

interface IProps extends StateProps, DispatchProps {
  getAdminDataQualityEntities: any;
  isInProduction: boolean;
}

interface IState {
  entityList: any;
}

class AdmDataQuality extends React.Component<IProps,IState> {

  constructor(props) {
    super(props);
    this.state = {
        entityList: null
    };
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminDataQualityEntities();
    this.setState({entityList:tmpEntityList.value.data});
  };


  render() {
    return adminLayout("Data Quality",<div>
  {this.state.entityList && this.renderList()}</div>,this.props.isInProduction);
  }

  renderList(){
    return (<>{this.state.entityList.map((entity, i) => (
      entity.entity.entities.map((entity2, i) =>(<div>{entity2}</div>))
    ))}</>);
  }
}

const mapStateToProps = ({ authentication,applicationProfile }: IRootState) => ({
  account: authentication.account,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminDataQualityEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmDataQuality);
