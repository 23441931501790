import React from "react";
import { Navigate  } from "react-router-dom";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { connect } from "react-redux";
import { getEntity } from "../../reducers/convention.reducer";
import { getEntities as getGeekiEntities } from "../../reducers/geeki.reducer";
import GeekiLayout from "../../shared/util/GeekiLayout";
import ConventionLight from "./ConventionLight";

export interface IProps  extends StateProps, DispatchProps{ 
  isInProduction: any
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  loaded: boolean;
  notFound: boolean;
  convention?: any;
  geekies: any[];
}

class Convention extends React.Component<Props, IState> {
  carRef;
  TAB_PRODUCTS = -1;
  TAB_GEEKIS = -1;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      notFound: false,
      geekies: []
    };
    this.carRef = React.createRef();
  }

  componentDidMount() {
    this.getConventionById();
  }

  componentDidUpdate() {
    if (
      this.state.convention &&
      String(this.state.convention.id) !== this.props.match.params['id']
    ) {
      this.getConventionById();
    }
  }



  render() {

    if (this.state.notFound) {
      return <Navigate to="/PageNotFound" />;
    }
    const { convention } = this.state;
    if(convention){
      return (<GeekiLayout title="Convention" component={
      <ConventionLight convention={convention} geekies={this.state.geekies} isInProduction={this.props.isInProduction}/>}/>);
    }else{
      return <></>;
    }
  }
  getConventionById = async () => {
    try {
      const convention: any = await (this.props as any).getEntity(
        this.props.match.params['id']
      );
      const result: any = await (this.props as any).getGeekiEntities();

      this.setState({
        loaded: true,
        convention: convention.value.data,
        geekies: result.value.data
      });


    } catch (e) {
      //TODO CST tester err 404
      console.log(e);

      this.setState({
        notFound: true
      });
    }
  };


}
const mapStateToProps = ({convention, applicationProfile}: IRootState) => ({
  convention: convention.entity,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntity,
  getGeekiEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Convention));
