import { combineReducers } from "redux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import authentication, { AuthenticationState } from "./authentication";
import applicationProfile, { ApplicationProfileState } from "./application-profile";
import administration, { AdministrationState } from "./administration.reducer";
import userManagement, { UserManagementState } from "./user-management.reducer";
import register, { RegisterState } from "./register.reducer";
import activate, { ActivateState } from "./activate.reducer";
import password, { PasswordState } from "./password.reducer";
import settings, { SettingsState } from "./settings.reducer";
import passwordReset, { PasswordResetState } from "./password-reset.reducer";
import geeki, { GeekiState } from "./geeki.reducer";
import convention, { ConventionState } from "./convention.reducer";
import sponsor, { SponsorState } from "./sponsor.reducer";
import playlist, { PlaylistState } from "./playlist.reducer";
import playlistcandidate, { PlaylistCandidateState } from "./playlistcandidate.reducer";
import linkStats, { LinkStatsState } from "./link-stats.reducer";
import product, { ProductState } from "./product.reducer";
import rssFeed, { RssFeedState } from "./rss-feed.reducer";
import news, { NewsState } from "./news.reducer";
import adminExt, { AdminStateExt } from "./admin-reducer";
import activity, { ActivityState } from "./activity.reducer";

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly playlist: PlaylistState;
  readonly playlistcandidate: PlaylistCandidateState;
  readonly linkStats: LinkStatsState;
  readonly product: ProductState;
  readonly geeki: GeekiState;
  readonly convention: ConventionState;
  readonly sponsor: SponsorState;
  readonly rssFeed: RssFeedState;
  readonly news: NewsState;
  readonly loadingBar: any;
  readonly adminExt: AdminStateExt;
  readonly activity: ActivityState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  playlist,
  playlistcandidate,
  linkStats,
  product,
  geeki,
  convention,
  sponsor,
  rssFeed,
  news,
  loadingBar,
  adminExt,
  activity
});

export default rootReducer;
