import { useState } from "react";
import "../CryptoTable.css";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import TargetEditor from "./TargetEditor";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { StatusEnum } from "../data/CryptoDataType";
import StatusEditor from "./StatusEditor";
import SellIcon from '@mui/icons-material/Sell';
import { toFixed } from "../data/CryptoData";


const CryptoRowTarget = (props: any) => {

    let [updated, setUpdated] = useState(false);

    const toggleUpdated = () => {
        setUpdated(!updated);
        props.partialRefresh(false);
    }
    const partialRefresh = () => {
        props.partialRefresh(false);
    }

    const onRemove = () => {
        props.onRemoveTarget(props.targetPos);
    }

    let row = props.row;
    let targetPos = props.targetPos;
    let item = props.item;
    
    if (!row.targets) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if ((!row.targets[targetPos])) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if (row.targets[targetPos].sold && props.viewMode) {
        return (<TableCell key={"key01" + item.name + item.site + Math.random()} align="right"
            className="rowTarget sold" colSpan={row.targets[targetPos].bagPerc}>vendu</TableCell>);
    } else if (row.targets.length > targetPos) {
        let numPrice = row.targets[targetPos].price;
        let price = (numPrice == null || numPrice === undefined ? null : numPrice.toFixed(2));
        let numBag = row.targets[targetPos].bag;
        let bag = (numBag == null || numBag === undefined ? null : numBag.toFixed(2));

        let target1Perc = row.targets[targetPos].perc;
        let rowColor = "rowColorDefault";
        if (props.viewMode) {
            if (target1Perc && target1Perc > row.targets[targetPos].notificationHigh2) {
                rowColor = "notificationHigh2";
            } else if (target1Perc && target1Perc > row.targets[targetPos].notificationHigh1) {
                rowColor = "notificationHigh1";
            }else if (target1Perc && target1Perc > row.targets[targetPos].notificationHigh0) {
                rowColor = "notificationHigh0";
            }
        }
        let cssTarget1Perc=toFixed(100-target1Perc,0);
        let gradiant="rgba(100,100,100,0.1)";
        if(target1Perc>80){
            gradiant="rgba(100,255,100,0.1)";
        }else if(target1Perc>50){
            gradiant="rgba(238, 173, 0,0.1)";
        }

        /*mui:
        TrackChanges
        */
        return (<TableCell  align="right" className={"rowTarget removeTarget " + rowColor}
        style={{background:"linear-gradient( rgba(0,0,0,0) 0%,rgba(0,0,0,0) "+cssTarget1Perc+"%, "+gradiant+" "+cssTarget1Perc+"%, "+gradiant+" 100%) "}}
            colSpan={row.targets[targetPos].bagPerc}>

            <TargetEditor
                tooltip="Cours de vente en USDT"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="rate"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key12" + item.name + item.site + Math.random()} />
                <div className="fieldEditor-container"><TargetEditor
                    tooltip="Pourcentage du bag"
                    className="fixedWidth"
                    targetPos={targetPos} val={row} prop="bagPerc"
                    viewMode={props.viewMode}
                    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                    onChange={toggleUpdated} type="percentage" key={"key131" + item.name + item.site + Math.random()} /></div>
            {props.viewMode ? <Tooltip title="Montant de l'objectif"><div>$ {price}</div></Tooltip>: <br />}

            {props.viewMode &&(row.targets[targetPos].status === StatusEnum.Order)
                && (            <div className="fixedWidth">{row.targets[targetPos].status === StatusEnum.Order && <span className="targetOrdered">
                <Tooltip title="Ordre de vente"><SellIcon />
                </Tooltip>
            </span>}
            
                {props.viewMode ? <Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip>: <br />}</div>)}
                

            {

                (!props.viewMode) && <Tooltip title="Statut de l'ordre"><div><StatusEditor targetPos={targetPos} val={row} /></div></Tooltip>}

{(!props.viewMode) && <TargetEditor
                tooltip="Notification basse"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="notificationHigh0"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key132" + item.name + item.site + Math.random()} /> }
{(!props.viewMode) && <TargetEditor
                tooltip="Notification intermédiaire"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="notificationHigh1"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key132" + item.name + item.site + Math.random()} /> }
                {(!props.viewMode) && <TargetEditor
                tooltip="Notification haute"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="notificationHigh2"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key132" + item.name + item.site + Math.random()} /> }

            {row.targets.length - 1 === targetPos &&
                <Tooltip title="Supprimer objectif"><IconButton onClick={onRemove} className="removeTarget">
                    <RemoveIcon />
                </IconButton></Tooltip>
            }
            <Tooltip title="% Objectif réalisé"><span className="percTarget">{toFixed(row.targets[targetPos].perc,2)}%</span></Tooltip>
        </TableCell>
        );
    } else {
        return (<TableCell key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    }

}

export default CryptoRowTarget;