import "./Product.scss";
import React from "react";
import SkeletonImage from "../image/SkeletonImage";
import SocialItem from "../social/SocialItem";
import { imgUrl } from "../../../shared/util/url-utils";

interface IProductProps {
  product: any;
  isInProduction: boolean;
}

export default class ProductWidget extends React.Component<IProductProps> {

  render() {
    const rand = Math.floor(Math.random() * 3) + 1;
    const imgrUrl = imgUrl("/api/img/product/" + this.props.product.id,this.props.isInProduction);
    return (
      <div className="productWidget" key={"prd" + Math.random()}>
        <div key={"prddd" + Math.random()} className="title"><span>
          {this.props.product.title}</span>
        </div>
        <div key={"prddd" + Math.random()} className="bottom">
        <div
          className={"middle geekiImg geekiImg" + rand}
          key={"prdd" + Math.random()}
        >
          <a href={"https://amzn.to/" + this.props.product.url} target="_new">
            <SkeletonImage
              key={"skelImPrd" + this.props.product.id}
              src={imgrUrl}
              width={180}
              height={250}
            />
          </a>
        </div>
          {"Année: " + this.props.product.from}
          <br />

          <SocialItem
            key="urlamazon"
            name="Acheter sur Amazon"
            linkType="URL"
            param="URL"
            encodeUrl={true}
            url={"https://amzn.to/" + this.props.product.url}
            svg="amazon.svg"
            css="amazon"
            isInProduction={this.props.isInProduction}
          />
        </div>
      </div>
    );
  }
}
