export interface IPlaylistCandidate {
  id?: number;
  playlistId?: string;
  processed?: boolean;
  name?: string;
}

export interface IAdminPlaylistCandidate {
  status: string;
  entity: IPlaylistCandidate;
}

export const defaultValue: Readonly<IPlaylistCandidate> = {};
