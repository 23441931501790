import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AddCircle } from "@mui/icons-material";

function CryptoTableHeader(props: any) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property)
  }


  return (<TableHead>
    <TableRow className="crypto-row-header">
      {(!props.viewMode) &&
        <TableCell align="center" padding={'normal'} className="addRow percLeft">
          <Tooltip title="Ajouter ligne"><IconButton onClick={props.onAdd}>
            <AddCircle />
          </IconButton></Tooltip>
        </TableCell>}
      <TableCell
        key={"symbol"}
        align="center"
        padding={'normal'}
        className="percLeft"
        sortDirection={orderBy === "symbol" ? order : false}
      >
        <TableSortLabel
          direction={orderBy === "symbol" ? order : 'asc'}
          onClick={createSortHandler("symbol")}
        >
          Crypto-monnaie
          {orderBy === "symbol" ? (
            <Box component="span">
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
      {props.viewMode && <TableCell align="center" padding={'normal'} className="percLeft">Tendance
      </TableCell>}

      <TableCell
        key={"target1Perc"}
        align="center"
        padding={'normal'}
        sortDirection={orderBy === "target1Perc" ? order : false}
      >
        <TableSortLabel
          direction={orderBy === "target1Perc" ? order : 'desc'}
          onClick={createSortHandler("target1Perc")}
        >
          Objectifs
          {orderBy === "target1Perc" ? (
            <Box component="span">
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    </TableRow>
  </TableHead>);
}


export default CryptoTableHeader;