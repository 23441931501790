import "./Product.scss";
import "../comics/Comics.scss";

import React from "react";

import ComicsPage from "../../../custom/widget/comics/ComicsPage";
import ProductsCategories from "./ProductsCategories";
import { IRootState } from "../../../reducers";
import { getEntities } from "../../../reducers/product.reducer";
import { connect } from "react-redux";
import SkeletonImage from "../image/SkeletonImage";
import { imgUrl } from "../../../shared/util/url-utils";
import ProductsFilter from "./ProductsFilter";
import GeekiLayout from "../../../shared/util/GeekiLayout";
import ModernComicsCell from "../comics/ModernComicsCell";

export interface IProps extends StateProps, DispatchProps {
  isInProduction:boolean
}

class Products extends React.Component<IProps> {

  listRef;

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.props.getEntities();
  }

  filterProducts = (selectedOptions) => {
    this.listRef.current.filterProducts(selectedOptions);
 }

  render() {
    const itemsGeeki = [];
    itemsGeeki.push(
      <div key={"pcsd" + Math.random()}>
        <a
          href="https://teespring.com/fr/geekipedia?pid=389&cid=100029"
          target="_new"
        >
          <SkeletonImage
            key="skelImPrdsTSM"
            src={imgUrl("/api/img/shop/TSM",this.props.isInProduction)}
            width={300}
            height={400}
            className="boxedImage geekiImg"
          />
        </a>
      </div>
    );
    itemsGeeki.push(
      <div key={"pcsd" + Math.random()}>
        <a
          href="https://teespring.com/fr/geekipedia?pid=659&cid=102968"
          target="_new"
        >
          <SkeletonImage
            key="skelImPrdsTSF"
            src={imgUrl("/api/img/shop/TSF",this.props.isInProduction)}
            width={300}
            height={400}
            className="boxedImage geekiImg"
          />
        </a>
      </div>
    );
    itemsGeeki.push(
      <div key={"pcsd" + Math.random()}>
        <a
          href="https://teespring.com/fr/geekipedia?pid=388&cid=101812"
          target="_new"
        >
          <SkeletonImage
            key="skelImPrdsMUG"
            src={imgUrl("/api/img/shop/MUG",this.props.isInProduction)}
            width={300}
            height={400}
            className="boxedImage geekiImg"
          />
        </a>
      </div>
    );
    return (<GeekiLayout title="Shop" component={<>
    <ModernComicsCell
          className="presentation"
          content={<span className="modernCC-widget-content"><p>
          Sur Geeki, retrouvez toutes les créations en vente de vos auteurs préférés.
        </p>
        <p>Vous voulez nous soutenir? Vous pouvez le faire en achetant nos goodies.</p></span>}
        />
      <ComicsPage content={itemsGeeki} />
    <ProductsFilter filterProducts={this.filterProducts}/>
    <ProductsCategories ref={this.listRef} activities={this.props.productList} isInProduction={this.props.isInProduction} /></>}/>);

  }
}
const mapStateToProps = ({ product,applicationProfile }: IRootState) => ({
  productList: product.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
