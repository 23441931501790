import "./Social.scss";
import React from "react";
import axios from "axios";
import SkeletonImage from "../image/SkeletonImage";
import { axiosGet, imgUrl } from "../../../shared/util/url-utils";

interface ISocialItemInputProps {
  svg: string;
  css: string;
  url?: string;
  param?: string;
  name?: string;
  linkType?: string;
  entityType?: string;
  entityLogin?: string;
  encodeUrl?: boolean;
  isInProduction:boolean;
}

interface ISocialItemState {
  params: string[];
  popupDisplay: boolean;
}

class SocialItem extends React.Component<
  ISocialItemInputProps,
  ISocialItemState
> {
  constructor(props) {
    super(props);
    this.state = {
      params: props.param ? props.param.split("|") : [],
      popupDisplay: false
    };
  }

  render() {
    const imgSrc = imgUrl("/content/images/social/" + this.props.svg,this.props.isInProduction);
    let divCss = "social " + this.props.css;
    if (this.state.params.length > 1) {
      divCss = divCss + " multi";
    }
    if (!this.props.param) {
      divCss = divCss + " disabled";
    }
    // todo cst improve replace img by svgPathData; https://www.npmjs.com/package/react-svg

    const img = (
      <SkeletonImage
        key={"skelImgSoIt" + this.props.linkType + this.props.entityLogin}
        src={imgSrc}
        alt={
          this.props.name ? "Geekipédia réseau social " + this.props.name : ""
        }
        title={this.props.name}
        width={25}
        height={25}
      />
    );

    if (!this.props.param) {
      return (
        <div key={"si" + Math.random()} className={divCss}>
          {img}
        </div>
      );
    } else if (this.state.params.length > 1) {
      let result = [];
      for (let i = 0; i < this.state.params.length; i++) {
        result.push(
          <div
            key={
              "socialItem" +
              this.props.linkType +
              this.props.entityLogin +
              i +
              Math.random()
            }
            className={divCss}
          >
            <a
              onClick={event => {
                this.goUrlI(i, event);
              }}
              target="_new"
            >
              {img}
              <span key={"socialItemSp" + i} className="socialNumber">
                {i + 1}
              </span>
            </a>
          </div>
        );
      }
      return result;
    } else {
      return (
        <div key={"si" + Math.random()} className={divCss}>
          <a onClick={this.goUrl} target="_new">
            {img}
          </a>
        </div>
      );
    }
  }
  goUrlI = async (i, event) => {
    let url =
      "/api/link/go/" +
      this.props.linkType +
      "/" +
      this.props.entityType +
      "/" +
      this.props.entityLogin +
      "/" +
      i;
    const responseUrl = await axios.get(url);
    window.open(responseUrl.data, "_blank");
  };

  goUrl = async () => {
    let url;
    if (this.props.entityLogin) {
      url =
        "/api/link/go/" +
        this.props.linkType +
        "/" +
        this.props.entityType +
        "/" +
        this.props.entityLogin;
    } else if (this.props.encodeUrl) {
      url = "/api/link/redirect?url=" + encodeURIComponent(this.props.url);
    } else {
      url = "/api/link/redirect/" + this.props.url;
    }
    const responseUrl = await axiosGet(url);
    window.open(responseUrl.data, "_blank");
  };

  switchPopup = async () => {
    this.setState({ popupDisplay: !this.state.popupDisplay });
  };
}

export default SocialItem;
