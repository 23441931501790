import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { getAdminPlaylistEntities } from "../../../reducers/admin-reducer";

import { getEntity, updateEntity } from "../../../reducers/playlist.reducer";
import { IRootState } from "../../../reducers";
import AdminFilteredList from "../AdminFilteredList";
import AdminPlaylistList from "./AdminPlaylistList";
import AdminPlaylistFilter from "./AdminPlaylistFilter";

export interface IProps extends StateProps, DispatchProps { 
  isInProduction:boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class AdminPlaylist extends React.Component<Props> {

  listRef;
  filterRef;
  editorRef;
  entityList;

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.editorRef = React.createRef();
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminPlaylistEntities();
    this.entityList = tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  };

  filterMethod = (filter) => {
    this.listRef.current.filterEntities(this.entityList, filter);
  }



  render() {
    return (<AdminFilteredList screenName="Playlist"
      filterComponent={<AdminPlaylistFilter filterMethod={this.filterMethod} />}
      filterRef={this.filterRef}
      listComponent={<AdminPlaylistList/>}
      listRef={this.listRef}
      versionIndicator={true}
      isInProduction={this.props.isInProduction}
    />);
  }
}


const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminPlaylistEntities,
  getEntity,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPlaylist));
