import axios from "axios";
import { Storage } from "../lib/storage-util";
import { SERVER_API_URL } from "../custom/config/constants";

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token =
      Storage.local.get("geeki-authenticationToken") ||
      Storage.session.get("geeki-authenticationToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
    /*
    const token = Storage.local.get('geeki-authenticationToken') || Storage.session.get('geeki-authenticationToken');
    
    if (token) {
     // return Promise.
    }else{
      return Promise.reject(err)
    }*/
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
