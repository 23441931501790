import React from "react";
import "./Comics.scss";

interface IInfoComicsCellInputProps {
  speech: any;
  style?: any;
  innerStyle?: any;
  footer?: any;
  className?: string;
  innerClassName?: string;
}

export default class InfoComicsCell extends React.Component<
  IInfoComicsCellInputProps
> {
  render() {
    return (
      <div
        style={this.props.style}
        className={this.props.className}
        key={"icc" + Math.random()}
      >
        <div
          key={"iccd" + Math.random()}
          className={
            "overflowHidden panel" +
            (this.props.innerClassName ? " " + this.props.innerClassName : "")
          }
          style={this.props.innerStyle}
        >
          <p className="speech">{this.props.speech}</p>
          {this.props.footer && (
            <p className="text bottom-right">{this.props.footer}</p>
          )}
        </div>
      </div>
    );
  }
}
