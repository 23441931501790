import "./SkeletonImage.scss";
import React from "react";
import GeekiSkeleton from "./GeekiSkeleton";

interface IProps {
  src: string;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
  title?: string;
}
interface IState {
  loaded: boolean;
  src: string;
}

export default class SkeletonImage extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      src:(this.props.src.startsWith("//")?'http:'+this.props.src:this.props.src)
    };
  }

  handleImageLoaded = () => {
    this.setState({ loaded: true });
  }

  render() {
    let result = [];
    let className = "";
    if (!this.state.loaded) {
      className = "skImg-hidden";
    } else if (this.props.className) {
      className = this.props.className;
    }
    result.push(
      <img
        key={"imgSkIm" + this.state.src}
        src={this.state.src}
        className={className}
        alt={this.props.alt}
        title={this.props.title}
        width={this.props.width}
        height={this.props.height}
        onLoad={this.handleImageLoaded}
      />
    );
    if (!this.state.loaded) {
      result.push(
        <GeekiSkeleton
          key={"skelSkIm" + this.state.src}
          className={this.props.className}
          width={this.props.width}
          height={this.props.height}
        />
      );
    }
    return result;
  }
}
