// FUNCTIONS
export const authorLabel = object =>
  object.nickName
    ? object.nickName + " (" + object.firstName + " " + object.lastName + ")"
    : object.firstName + " " + object.lastName;

export const geekiLabelShort = object =>
  object?
      (object.firstName?object.firstName + " " + object.lastName:
      (object.name?object.name:authorNickNames(object.nickName, false)))
      :"";

export const geekiLabelWithNickName = object =>
object.name 
? object.name
: object.nickName
    ? object.firstName
      ? object.firstName +
        " " +
        object.lastName +
        " " +
        authorNickNames(object.nickName, true)
      : authorNickNames(object.nickName, false)
    : object.firstName + " " + object.lastName;

export var authorNickNames = function(nickname, withAkaForFirst) {
  if (nickname) {
    let result = "";
    let splitted = nickname.split("\\|");
    for (let i = 0; i < splitted.length; i++) {
      let name = splitted[i];
      if (withAkaForFirst || result !== "") {
        result = result + " ( a.k.a. " + name + ")";
      } else {
        result = name;
      }
    }
    return result;
  } else {
    return "";
  }
};
