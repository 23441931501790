
import React from "react";
import { Link } from "react-router-dom";
import { imgUrl, routeUrl } from "../../../shared/util/url-utils";
import IconWithSub from "../icon/IconWithSub";
import "./CollapsableSideBar.scss";
import { Stack } from "@mui/material";
//import { DarkModeSwitch } from "react-toggle-dark-mode";

export interface IProps {
  isInProduction:boolean
}
export interface IState {
  expanded: boolean;
  darkMode: boolean;
}

export default class CollapsableSideBar extends React.Component<IProps, IState> {


  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      darkMode: false
    };    
  }

  toggleSidebar = () => {
//    this.setState({ expanded: !this.state.expanded });
  };

  onscrollContent = e => {
    if (this.state.expanded) {
      this.toggleSidebar();
    }
  }
  onMouseOverContent = e => {
    if (!this.state.expanded) {
      this.toggleSidebar();
    }
  }
  toggleDarkMode = (e) => {
    this.setState({ darkMode: !this.state.darkMode });

    let body = document.querySelector('body');

    if(body.dataset.theme!=='darkMode'){
      body.dataset.theme='darkMode';
    }else{
      body.dataset.theme='lightMode';

    }
  }

  searchGeeki(e) {
    window.location.replace('/#/geeki/' + e.login);
  }

  /*
              <br/>
              <Link to="/login" className="alert-link">
                Connexion
              </Link>*/
/*
              <span className="darkModeIcon">
              <DarkModeSwitch
      style={{ marginBottom: '2rem' }}
      checked={this.state.darkMode}
      onChange={this.toggleDarkMode}
      moonColor={"rgb(33, 186, 69)"}
      sunColor={"rgb(33, 186, 69)"}
      size={55}
    /></span>*/
  render() {
    let selectedCategory = '';
    let categories = [];
    categories.push({ name: 'Accueil',css:'home', path: routeUrl("/"), icon: <IconWithSub size="large" className="home" title="Accueil" isInProduction={this.props.isInProduction} /> });
    categories.push({ name: 'Geekipedia',css:'geekipedia', path: routeUrl("/geekipedia"), icon: <IconWithSub size="large" className="world" title="Geekipedia" isInProduction={this.props.isInProduction} /> });
    categories.push({ name: 'News',css:'news', path: routeUrl("/news"), icon: <IconWithSub size="large" className="newspaper outline" title="News" isInProduction={this.props.isInProduction} /> });
    categories.push({ name: 'Agenda',css:'agenda', path: routeUrl("/conventions"), icon: <IconWithSub size="large" className="calendar alternate outline" title="Agenda" isInProduction={this.props.isInProduction} /> });
    categories.push({ name: 'Boutique', css:'shop', path: routeUrl("/boutique"), icon: <IconWithSub size="large" className="cart" title="Boutique" isInProduction={this.props.isInProduction} /> });

    return (
      <div className={`geekiLayout-sidebar ${this.state.expanded ? 'open' : ''}`}>
        <div className={`sidebar ${this.state.expanded ? 'open' : ''}`}>
          <Stack
            className="sidebar2 sidebar-content"
            direction="row"
            sx={{
              background: "rgb(35, 111, 94)",
              height: { sx: "auto", md: "100%" },
              flexDirection: { md: "column" },
            }}
          >

            <div className="toggle-button geekiLogo" onClick={this.toggleSidebar} style={{
              alignItems: "center",
              height: "90px"
            }}>
              {this.state.expanded ? <img src={imgUrl("/content/images/geekiLogo.png",this.props.isInProduction)} alt="logo" height={90} width={230} />
                : <img src={imgUrl("/content/images/geekiLogo.png",this.props.isInProduction)} alt="logo" height={90} width={230} />}
            </div>
            <div className="sidebar-menu" onMouseOver={this.onMouseOverContent}>
              <br />

              {
                categories.map((category) => (
                  <a
                    className={"sidebarLink "+category.css}
                    href={category.path}
                    style={{
                      background: category.name === selectedCategory && 'green',
                      color: "white"
                    }}
                    key={category.name}
                  >
                    <span style={{
                      color: category.name === selectedCategory ? 'white' : 'darkgreen',
                      marginRight: "15px"
                    }}>
                      {category.icon}
                    </span>
                  </a>
                ))}
              <br/>
           
            </div>
          </Stack>

        </div>

      </div>
    );
  }
}