import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { getAdminConventionEntities } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import AdminFilteredList from "../AdminFilteredList";
import AdminConventionFilter from "./AdminConventionFilter";
import AdminConventionList from "./AdminConventionList";

export interface IProps extends StateProps, DispatchProps{
  isInProduction: boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class AdminConvention extends React.Component<Props> {

  listRef;
  filterRef;
  entityList

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminConventionEntities();
    this.entityList=tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  };

  filterMethod = (filter) => {
    this.listRef.current.filterEntities(this.entityList,filter);
 }

  render() {
    return (<AdminFilteredList screenName="Conventions"
            filterComponent={<AdminConventionFilter filterMethod={this.filterMethod}/>}
            listComponent={<AdminConventionList/>}
            listRef={this.listRef}
            filterRef={this.filterRef}
            versionIndicator={true}
            isInProduction={this.props.isInProduction}
    />);
  }
}


const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminConventionEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminConvention));
