import "./Product.scss";
import "../comics/Comics.scss";

import React from "react";
import ProductsCategory from "./ProductsCategory";
import DbParseUtils2Mappings from "../util/DbParseUtils2Mappings";
import { includesUpperCaseWithoutAccents } from "../../../shared/util/string-utils";

export interface IProductProps {
  activities: any;
  isInProduction: boolean;
}

interface IState {
  categoryList: any;
  geekiName: string;
  productName: string;
}

class ProductsCategories extends React.Component<IProductProps,IState> {

  constructor(props) {
    super(props);
    this.state = {
      categoryList: null,
      geekiName:null,
      productName:null
    };
  }

  filterProducts(selectedOptions){
    this.setState({categoryList: selectedOptions.list, geekiName:selectedOptions.geekiName, productName:selectedOptions.productName});
  }

  render() {
    let categories;
    if(this.state.categoryList== null || this.state.categoryList.length===0){
      categories= DbParseUtils2Mappings.productCategories;
    }else{
      categories=this.state.categoryList;
    }

    let { activities } = this.props;
    if(this.state.geekiName!=null && this.state.geekiName!==''){
      let activitiesFiltered=[];
      for (const value of activities.values()) {
        if(includesUpperCaseWithoutAccents(value.geekiLogin,this.state.geekiName)){//todo cst improve on firstname,last, nick and nmae
          activitiesFiltered.push(value);
        }else{
          for (const value2 of value.roles) {
            if(includesUpperCaseWithoutAccents(value2.geekiLogin,this.state.geekiName)){//todo cst improve on firstname,last, nick and nmae
              activitiesFiltered.push(value);
              break;
            }
          }
        }
      }
      activities=activitiesFiltered;
    }
    if(this.state.productName!=null && this.state.productName!==''){
      let activitiesFiltered=[];
      for (const value of activities.values()) {
        if(includesUpperCaseWithoutAccents(value.title,this.state.productName)){
          activitiesFiltered.push(value);
        }
      }
      activities=activitiesFiltered;
    }
    const items = [];
    let i = 0;
    let key;
    for (const cat of categories.values()) {
      //TODO CST optimize loop
      const catItems = [];
      for (const value of activities.values()) {
        if (value.img && value.type === cat) {
          let trouve = false;
          for (const catItem of catItems) {
            if (value.url === catItem.url) {
              trouve = true;
            }
          }
          if (!trouve) {
            catItems.push(value);
          }
        }
      }
      if (catItems.length > 0) {
        i = i + 1;
        key = "cat" + i;
        items.push(
          <ProductsCategory key={key} category={cat} products={catItems} isInProduction={this.props.isInProduction}/>
        );
      }
    }
    return (
      <div className="productCategories" key={"pcs" + Math.random()}>
        {items}
      </div>
    );
  }
}

export default ProductsCategories;
