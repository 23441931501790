import axios from "axios";
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from "../lib/redux-action.type";
import { cleanEntity } from "../shared/util/entity-utils";
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";

import { INews, defaultValue } from "../shared/model/news.model";

export const ACTION_TYPES = {
  FETCH_RSSFEED_LIST: "news/FETCH_RSSFEED_LIST",
  FETCH_RSSFEED: "news/FETCH_RSSFEED",
  CREATE_RSSFEED: "news/CREATE_RSSFEED",
  UPDATE_RSSFEED: "news/UPDATE_RSSFEED",
  DELETE_RSSFEED: "news/DELETE_RSSFEED",
  SET_BLOB: "news/SET_BLOB",
  RESET: "news/RESET",
  FETCH_NEWS_LIST_NEW: "news/FETCH_NEWS_LIST_NEW",
  FETCH_BIRTHDAY: "api/birthday",
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INews>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type NewsState = Readonly<typeof initialState>;

// Reducer

const newsState = (state: NewsState = initialState, action): NewsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RSSFEED_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RSSFEED):
      
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RSSFEED):
    case REQUEST(ACTION_TYPES.UPDATE_RSSFEED):
    case REQUEST(ACTION_TYPES.DELETE_RSSFEED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RSSFEED_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RSSFEED):
    case FAILURE(ACTION_TYPES.CREATE_RSSFEED):
    case FAILURE(ACTION_TYPES.UPDATE_RSSFEED):
    case FAILURE(ACTION_TYPES.DELETE_RSSFEED):
      
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RSSFEED_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_RSSFEED):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };

      
    case SUCCESS(ACTION_TYPES.CREATE_RSSFEED):
    case SUCCESS(ACTION_TYPES.UPDATE_RSSFEED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RSSFEED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case REQUEST(ACTION_TYPES.FETCH_NEWS_LIST_NEW):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_NEWS_LIST_NEW):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_NEWS_LIST_NEW):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
      case REQUEST(ACTION_TYPES.FETCH_BIRTHDAY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BIRTHDAY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BIRTHDAY):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };

      
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + "ContentType"]: contentType
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default newsState;

const apiUrl = "api/news";

// Actions

export const getEntities: ICrudGetAllAction<INews> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_RSSFEED_LIST,
  payload: axios.get<INews>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<INews> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RSSFEED,
    payload: axios.get<INews>(requestUrl)
  };
};





export const createEntity: ICrudPutAction<INews> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RSSFEED,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<INews> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RSSFEED,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<INews> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RSSFEED,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const getNewEntities: ICrudGetAllAction<INews> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_NEWS_LIST_NEW,
  payload: axios.get<INews>(`api/newsnew?cacheBuster=${new Date().getTime()}`)
});

export const getBirthdayEntities: ICrudGetAllAction<INews> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_BIRTHDAY,
  payload: axios.get<INews>(`api/birthday?cacheBuster=${new Date().getTime()}`)
});


export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
