import React from "react";
import { geekiLabelWithNickName } from "../../config/GeekiUtil";
import GeekiSkeleton from "../image/GeekiSkeleton";
import DbParseUtils from "../util/DbParseUtil2";
import DbParseUtils2Mappings from "../util/DbParseUtils2Mappings";
import { countImages } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import { connect } from "react-redux";

import ReactDOM from 'react-dom';
import GeekiPhoto from "../image/GeekiPhoto";
import CommentBox from "../comment/CommentBox";

interface IProps extends StateProps,DispatchProps {
  wiki: any;
  entity?: any;
  countImages: any;
  isInProduction:boolean;
}

interface IState {
  imgCount: number;
  wikiDiv: any;
}

class Description extends React.Component<IProps,IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      imgCount:0,
      wikiDiv: null
    };
  }

  componentDidMount() {
    this.initWikiWithImages();
  }
  async initWikiWithImages(){
    if (this.props.entity) {
    const result:any=await this.countImgs();
    let imgCount= result===undefined?0:result.value===undefined?0:result.value.data;
    this.initWiki(imgCount);
    }
  }

  countImgs = async () => {
      const imgCount = await this.props.countImages(this.props.entity.login);
      this.setState({
        imgCount: imgCount.value.data
      });
  };

  initWiki(imgCount){
    let wiki: any;
  
      if (this.props.wiki) {
        if (typeof this.props.wiki === "string") {
          wiki=(
            <div
              key={"desdsih" + Math.random()}
              dangerouslySetInnerHTML={{ __html: this.props.wiki }}
            />
          );
          this.setState({wikiDiv:this.props.wiki,imgCount: imgCount});
          
        } else if (typeof this.props.wiki === "object") {
          if(this.state && this.state.imgCount){
            let wikiDiv = document.createElement('div');
            const tempContainer = document.createElement('div');
            tempContainer.className="geekiImgDiv";
            ReactDOM.render(<GeekiPhoto geekiEntity={this.props.entity} 
              isInProduction={this.props.isInProduction}
                imgCount={this.state.imgCount} />, tempContainer,
            () => {
              wikiDiv.appendChild(tempContainer);
              return this.renderTexts(wikiDiv,wiki,imgCount);
            });   
          }
       }else {
          wiki=(this.props.wiki);
          this.setState({wikiDiv:this.props.wiki,imgCount: imgCount});
        }
        
      } else {
        this.setState({wikiDiv:<GeekiSkeleton height={200} />});
      }
  }


  render() {
    if(this.state.wikiDiv!=null){
      return (<div key={"wik" + Math.random()} className="width100Perc">
       
              <div key={"wikd" + Math.random()} className="width100Perc" dangerouslySetInnerHTML={{ __html: this.state.wikiDiv.innerHTML }}/>
              {this.props.entity && <CommentBox cols={50} geekiLogin={this.props.entity.login}/>}
            </div>);
    }else{
      return "";
    }
  }

  renderTexts(wikiDiv,wiki,imgCount){
    if(this.props.entity){
      let authorData=this.extractAuthor();
      if(authorData!=null){
        wikiDiv.appendChild(authorData);
      }
    }
    wiki= DbParseUtils.doInnerHTML(this.props.wiki,wikiDiv);
    if(imgCount){
    this.setState({wikiDiv:wikiDiv,imgCount: imgCount});
    }else{
      this.setState({wikiDiv:wikiDiv});

    }
    return wiki;
  }

  



  extractAuthor() {
    if (this.props.entity.sex) {
      const authorTitle = geekiLabelWithNickName(this.props.entity);
      let result = "";
      if (this.props.entity.birthDate) {
        result =
          authorTitle +
          (this.props.entity.sex === "M" ? " est né le " : " est née le ") +
          DbParseUtils.formatDate(this.props.entity.birthDate) +
          ".";
      }
      if (this.props.entity.deathDate) {
        if (result === "") {
          result =
            authorTitle +
            (this.props.entity.sex === "M"
              ? " est décédé le "
              : " est décédée le ") +
            DbParseUtils.formatDate(this.props.entity.deathDate) +
            ".";
        } else {
          result =
            result +
            (this.props.entity.sex === "M"
              ? " Il est décédé le "
              : " Elle est décédée le ") +
            DbParseUtils.formatDate(this.props.entity.deathDate) +
            ".";
        }
      }
      if (this.props.entity.skills && this.props.entity.skills.length > 0) {
        let skills = "";
        const splitted = this.props.entity.skills.split(";");
        for (let i = 0; i < splitted.length; i++) {
          let skillLabel = DbParseUtils2Mappings.translateSkill(
            splitted[i],
            this.props.entity.sex
          );
          if (skillLabel != null) {
            if (skills !== "") {
              skills = skills + ", ";
            }
            skills = skills + skillLabel;
          }
        }
      }

      if (result === "") {
        const newDiv = document.createElement('div');
        newDiv.innerHTML="";
        return newDiv;
      } else {
        const newDiv = document.createElement('div');
        newDiv.innerHTML=result;
        return newDiv;
      }
    } else {
      const newDiv = document.createElement('div');
        newDiv.innerHTML="";
        return newDiv;
    }
  }
}

const mapStateToProps = ({ authentication, applicationProfile }: IRootState) => ({
  account: authentication.account,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  countImages
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Description);
