import React from "react";
import YouTube from "react-youtube";
import InfoComicsCell from "../comics/InfoComicsCell";

interface IProps {
  w: any;
  h: any;
  carRef: any;
}

interface IState {
  preview: boolean;
  id: any;
}

class YoutubePlayer extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      preview: true,
      id: 0
    };
  }

  render() {
    const width = this.props.w;
    const height = this.props.h;

    if (this.state.preview) {
      return (
        <div
          id="ytcontainer"
          ref={this.props.carRef}
          key={"yp" + Math.random()}
        >
          <div
            key={"ypd" + Math.random()}
            id="monitor"
            style={{ width: width + 20 + "px", height: height + 40 + "px" }}
          >
            <InfoComicsCell
              className="homeInfo inlineBlock"
              innerClassName="univ0"
              innerStyle={{ width: width - 20 + "px", height: height + "px" }}
              speech="Cliquez sur une vidéo pour la visionner"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={this.state.id}
          ref={this.props.carRef}
          key={"yp" + Math.random()}
        >
          {!this.state.preview && (
            <div id="ytcontainer" key={"ypd" + Math.random()}>
              <div
                key={"ypdd" + Math.random()}
                id="monitor"
                style={{ width: width + 20 + "px", height: height + 40 + "px" }}
              >
                <div
                  className="ytOffButton"
                  onClick={this.stopVideo}
                  key={"ypddd" + Math.random()}
                >
                  X
                </div>
                <div
                  key={"ypdddd" + Math.random()}
                  id="monitorscreen"
                  style={{ width: width + "px", height: height + "px" }}
                >
                  <YouTube
                    videoId={this.state.id}
                    opts={{
                      height: height,
                      width: width,
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 1,
                        rel: 0
                      }
                    }}
                    onReady={this._onReady}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  setVideoId = (videoId) => {
    this.setState(state => ({
      preview: false,
      id: videoId
    }));
  }

  startVideo = () => {
    this.setState(state => ({
      preview: false
    }));
  }

  stopVideo = () => {
    this.setState(state => ({
      preview: true
    }));
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default YoutubePlayer;
