import React, { useState, useEffect } from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { IRootState } from "../../reducers";
import { isUserAuthenticated, login } from "../../reducers/authentication";
import LoginModal from "../../modules/login/login-modal";
import { adminLayout } from "./util/admin-utils";

export interface IProps
  extends StateProps,
    DispatchProps
    {
      isInProduction:boolean,
      location: any,
      login

    }
    type Props = IProps & WithRouterProps<IProps>;

export const AdminNotAuthenticated = (props: Props) => {
  let [showModal, setShowModal] = useState(props.showModal);
  showModal = true;

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal]);

  const handleLogin = (username, password, rememberMe = false) =>
    props.login(username, password, rememberMe);

  const handleClose = () => setShowModal(false);

  const { location } = props;
  const { from } = (location.state as any) || {
    from: { pathname: "/", search: location.search }
  };
  if (isUserAuthenticated()) {
    return <Navigate to={from} />;
  }
  return adminLayout("Connexion",<LoginModal
  showModal={showModal}
  handleLogin={handleLogin}
  handleClose={handleClose}
  loginError={props.loginError}
/>,props.isInProduction);
};

const mapStateToProps = ({ authentication,applicationProfile }: IRootState) => ({
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNotAuthenticated));
