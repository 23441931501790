import "./Activity.scss";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import ActivityUtils from "./ActivityUtils";
import DbParseUtils from "../widget/util/DbParseUtil2";
import DbParseUtils2Mappings from "../widget/util/DbParseUtils2Mappings";
import { getGeekiByLogin } from "../../shared/util/entity-utils";

export interface IProps {
  activities: any[];
  geekies: any;
  page: string;
  displayType: boolean;
  sex: string;
  geekiLogin: string;
  geekiType: string;
}

export default class ActivityTimeLineElementCard extends React.Component<IProps> {

  render() {
    if (this.props.geekies.length > 0) {
      return (<div className="tlCard" key={"atlec" + Math.random()+"-"+Math.random()}>{this.renderActivities(this.props.activities)}</div>);
    } else {
      return "";
    }
  }
  renderActivities(activities) {
    let result = [];
    result.push(this.renderTitle(activities[0]));
    for (let i = 0; i < activities.length; i++) {
      let activity = activities[i];
      result.push(<div className="tlCard"  key={"atlec" + Math.random()+"-"+Math.random()}>
        {this.renderSkillsWithPeriod(activity, activity.roles)}
        <span key={"atlec" + Math.random()+"-"+Math.random()}>
          {this.renderEpisode(activity)}
          {this.renderRecompense(activity)}
        </span>
        {this.renderComment(activity)}
      </div>);
    }
    return result;
  }

  renderTitle(activity) {
    if (activity) {
      return (<div className="geekiLink" key={"atlec" + Math.random()+"-"+Math.random()}>
        {this.props.geekiLogin!==activity.geekiLogin ?ActivityUtils.createGeekiLoginLink(
        true,
        activity.geekiLogin,
        this.props.geekies
      ):''}
        <>{activity.title} {activity.guest}</>
      </div>);
    } else {
      return "";
    }
  }

  renderComment(activity) {
    return activity.comment ? (
      <div className="comment" key={"atlec" + Math.random()+"-"+Math.random()}>{activity.comment}</div>
    ) : (
      ""
    );
  }

  renderType(activity, activityRole) {
    if (this.props.geekiType === "EVENEMENT" && activity.type === "SALON") {
      return "";
    } else if (this.props.displayType && activityRole !== undefined) {
      let geekiLink;
      if (
        this.props.geekiLogin !== activityRole.geekiLogin &&
        this.props.geekiType === "AUTEUR"
      ) {
        geekiLink = ActivityUtils.createGeekiLink(
          true,
          activityRole,
          this.props.geekiLogin,
          this.props.geekies
        );
      }
      if (this.props.geekiType === "AUTEUR") {
        return (
          <span className="type" key={"atlec" + Math.random()+"-"+Math.random()}>(
            {DbParseUtils2Mappings.translateActivity(activity.type)}
            {geekiLink ? ": " : ""}
            {geekiLink}
            )</span>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  renderSkillsWithPeriod(activity, activityRoles) {
    let result = [];
    for (let i = 0; i < activityRoles.length; i++) {
      result.push(this.renderSkillsWithPeriodRow(activity, activityRoles[i]));
    }
    return result;
  }

  renderSkillsWithPeriodRow(activity, activityRole) {
    if (this.props.geekiType === "AUTEUR") {
      return (
        <div key={"atlec" + Math.random()+"-"+Math.random()}>
          <span className="type" key={"atlec" + Math.random()+"-"+Math.random()}>
            {activity.type === "FAMILLE" &&
              ActivityUtils.createGeekiLink(
                true,
                activityRole,
                this.props.geekiLogin,
                this.props.geekies
              )}&nbsp;
              {this.createSkill(activity, activityRole)}
          </span>
          <span className="year" key={"atlec" + Math.random()+"-"+Math.random()}>
            {activityRole.to ? " jusqu'en " + activityRole.to + " " : ""}
          </span>
        </div>
      );
    } else {
      return (
        <div key={"atlec" + Math.random()+"-"+Math.random()}>
          {this.props.geekiLogin !== activityRole.geekiLogin &&

            ActivityUtils.createGeekiLink(
              true,
              activityRole,
              this.props.geekiLogin,
              this.props.geekies
            )}
            {this.props.geekiLogin !== activityRole.geekiLogin &&
              this.createWorkSkill(activity, activityRole)}
          {activity.type === "SALON" &&
            ActivityUtils.generateGeekiLinkByLogin(
              this.props.geekiLogin,
              this.props.geekies
            )}
          <span className="year" key={"atlec" + Math.random()+"-"+Math.random()}>
            {activityRole.to ? " jusqu'en " + activityRole.to + " " : ""}
          </span>
        </div>
      );
    }
    // }
  }

  renderEpisode(activity) {
    return activity.episode ? " épisode " + activity.episode : "";
  }

  renderRecompense(activity) {
    return activity.recompense ? " récompense: " + activity.recompense : "";
  }

  renderEditor(activityRole) {
    if (activityRole.editor && activityRole.editor !== "" && activityRole.editor !== undefined) {
      return (
        <div key={"atlec" + Math.random()+"-"+Math.random()}>
          {"Publié par " + activityRole.editor}
        </div>
      );
    } else {
      return "";
    }
  }

  createWorkSkill(activity, activityRole) {
    return activityRole.skills.length <= 0 ? (
      ""
    ) : (
      <span className="type" key={"atlec" + Math.random()+"-"+Math.random()}>
        {activity.type === "SALON"
          ? this.createSalonSkill(activity, activityRole)
          : this.createSkill(activity, activityRole)}
        &nbsp;
      </span>
    );
  }

  createSalonSkill(activity, activityRole) {
    if (activityRole.skills === "INVITE" && activity.guest == null) {
      return "Invité(s):";
    } else {
      return this.createSkill(activity, activityRole);
    }
  }

  createSkill(activity, activityRole) {
    let geeki = getGeekiByLogin(this.props.geekies, activityRole.geekiLogin);
    let sex = geeki.geekiType === 'AUTEUR' ? geeki.sex : this.props.sex;
    if ("BIRTH" === activity.type ||"CREATION" === activity.type) {
      return (geeki.geekiType === 'AUTEUR'?geeki.sex === "M" ? "Né le " : "Née le ": "Créé le ") +DbParseUtils.formatDate(activityRole.skills);
    }else if ("DEATH" === activity.type ||"END" === activity.type) {
      return (geeki.geekiType === 'AUTEUR'?geeki.sex === "M" ? "Décédé le " : "Décédée le ": "Fin le ") +DbParseUtils.formatDate(activityRole.skills);
    }  else {
      return DbParseUtils2Mappings.translateSkills(activityRole.skills, sex, "|");
    }
  }

  createLink(s) {
    if (s) {
      if (s.startsWith("http")) {
        return (<a className="link" href={s} target="_new" key={"atlec" + Math.random()+"-"+Math.random()}>{s}</a>);
      } else {
        return s;
      }
    } else {
      return "";
    }
  }
}
