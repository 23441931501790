import "../admin.scss";
import React from "react";
import LazyLoad from 'react-lazyload';
import { includesUpperCaseWithoutAccents } from "../../../shared/util/string-utils";
import { Button, Table } from "reactstrap";

interface IProps {
  desactivatePlaylist: any;
  activatePlaylist: any;
  detectNewPlaylists: any;
}
interface IState {
  entityList: any;
  isNew: boolean;
}

export default class AdminPlaylistCandidateList extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      entityList: null, isNew: false
    };
  }

  setIsNewVersion(isNew: boolean) {
    this.setState({ isNew: isNew });
  }

  updateEntityList(entityList) {
    this.setState({ entityList: entityList });
  }

  filterEntities(entityList, filter) {
    let filteredEntityList = [];
    for (let i = 0; i < entityList.length; i++) {
      let entity = entityList[i].entity;
      let isValid = true;
      if (filter.title != null && filter.title !== undefined && filter.title !== '') {
        if (!includesUpperCaseWithoutAccents(entity.title, filter.title)) {
          isValid = false;
        }
      }
      if (filter.id != null && filter.id !== undefined && filter.id !== '') {
        if (!includesUpperCaseWithoutAccents(entity.id, filter.id)) {
          isValid = false;
        }
      }
      /*
      if(dateFrom !=null && dateFrom!== undefined && dateFrom!==''){
          let date=new Date(convention.startDate);
          if(date <dateFrom){
              isValid=false;
          }
      } 
      */
      if (isValid) {
        filteredEntityList.push(entityList[i]);
      }
    }
    this.setState({ entityList: filteredEntityList });
  }

  onClickRejectHandler = e => {
    const entityId = e.target.getAttribute("data-item");
    this.props.desactivatePlaylist(entityId).then(xxx => {
      this.setState({ entityList: this.state.entityList });
    });
  };
  onClickValidateHandler = e => {
    const entityId = e.target.getAttribute("data-item");
    for (let i = 0; i < this.state.entityList.length; i++) {
      if (this.state.entityList[i].entity.id.toString() === entityId) {
        this.props.activatePlaylist(this.state.entityList[i].entity);
        break;
      }
    }
  };


  render() {
    return (
      <div className="width100Perc">
        <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
          {this.renderEntities()}
        </LazyLoad>
      </div>);
  }

  renderEntities() {
    return (this.state.isNew && this.state.entityList && this.state.entityList.length > 0 ? (
      <Table responsive aria-describedby="playlist-heading">
        <thead>
          <tr>
            <th colSpan={4}><Button onClick={this.props.detectNewPlaylists} className="geekiButton">Detect Playlists</Button></th>
          </tr>
          <tr>
            <th>Position</th>
            <th>Nom</th>
            <th>Traité</th>
            <th>Youtube ID</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.entityList.map((entity, i) => (
            <tr key={`entity-${i}`}>
              <td>{entity.entity.id}</td>
              <td>{entity.entity.name}</td>
              <td>{entity.entity.processed ? "true" : "false"}</td>
              <td>
                <a
                  href={
                    "https://www.youtube.com/playlist?list=" +
                    entity.entity.playlistId
                  }
                  target="_new"
                >
                  {entity.entity.playlistId}
                </a>
              </td>
              <td>
                <button data-item={entity.entity.id}
                  disabled={!this.state.isNew}
                  className="geekiButton" onClick={this.onClickRejectHandler}>
                  Rejeter
                </button>
                <button data-item={entity.entity.id}
                  disabled={!this.state.isNew}
                  className="geekiButton" onClick={this.onClickValidateHandler}>
                  Accepter
                </button>
              </td>

            </tr>
          ))}
        </tbody>
      </Table>) : <div><Button onClick={this.props.detectNewPlaylists} className="geekiButton">Detect Playlists</Button><br />Aucune Playlist trouvée</div>);
  }

}