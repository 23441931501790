import "../modules/home/home.scss";
import { connect } from "react-redux";
import ContactWidget from "../custom/widget/contact/ContactWidget";
import { geekiCenteredLayout } from "../shared/util/layout-util";

export type IContactProp = StateProps;

export const Contact = (props: IContactProp) => {
  return geekiCenteredLayout("Contact",<ContactWidget />);
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Contact);
