import React from "react";
import { connect } from "react-redux";
import { Link }  from "react-router-dom";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { Button, Row, Col, Label, Form, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../../reducers";

import {
  getEntity,
  updateEntity,
  createEntity,
  reset
} from "../../../reducers/link-stats.reducer";

export interface IProps extends StateProps, DispatchProps{
      history?: any
}
type Props = IProps & WithRouterProps<IProps>;

export interface ILinkStatsUpdateState {
  isNew: boolean;
}

export class LinkStatsUpdate extends React.Component<
Props,
  ILinkStatsUpdateState
> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params["id"]
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.updateSuccess !== (this.props as any).updateSuccess &&
      nextProps.updateSuccess
    ) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      (this.props as any).reset();
    } else {
      (this.props as any).getEntity(this.props.match.params["id"]);
    }
  }

  //saveEntity = (event, errors, values) => {
    saveEntity = (event) => {
  /*  if (errors.length === 0) {
      const { linkStatsEntity } = this.props;
      const entity = {
        ...linkStatsEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }*/
  };

  handleClose = () => {
    this.props.history.push("/admin/link-stats");
  };

  render() {
    const { linkStatsEntity, loading, updating } = (this.props as any);
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="geekiApp.linkStats.home.createOrEditLabel">Créer ou éditer un Linkstats</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Chargement...</p>
            ) : (
              <Form
                model={isNew ? {} : linkStatsEntity}
                onSubmit={this.saveEntity}
              >
                {!isNew ? (
                  <>
                    <Label for="link-stats-id">ID</Label>
                    <Input
                      id="link-stats-id"
                      type="text"
                      className="form-control"
                      name="id"
                      required
                      readOnly
                    />
                  </>
                ) : null}
                
                  <Label id="entityLoginLabel" for="link-stats-entityLogin">Identifiat entité</Label>
                  <Input
                    id="link-stats-entityLogin"
                    type="text"
                    className="form-control"
                    name="entityLogin"
                  />
                
                
                  <Label id="entityTypeLabel" for="link-stats-entityType">Type entité</Label>
                  <Input
                    id="link-stats-entityType"
                    type="text"
                    name="entityType"
                    validate={{
                      maxLength: {
                        value: 50,
                        errorMessage: "Longueur maximum type entité 50"
                      }
                    }}
                  />
                
                
                  <Label id="facebookLabel" for="link-stats-facebook">Facebook</Label>
                  <Input
                    id="link-stats-facebook"
                    type="text"
                    className="form-control"
                    name="facebook"
                  />
                
                
                  <Label id="twitterLabel" for="link-stats-twitter">X ex Twitter</Label>
                  <Input
                    id="link-stats-twitter"
                    type="text"
                    className="form-control"
                    name="twitter"
                  />
                
                
                  <Label id="wikiLabel" for="link-stats-wiki">Wiki</Label>
                  <Input
                    id="link-stats-wiki"
                    type="text"
                    className="form-control"
                    name="wiki"
                  />
                
                
                  <Label id="nolifewikiLabel" for="link-stats-nolifewiki">Nolife Wiki</Label>
                  <Input
                    id="link-stats-nolifewiki"
                    type="text"
                    className="form-control"
                    name="Nolife wiki"
                  />
                
                
                  <Label id="instagramLabel" for="link-stats-instagram">Instagram</Label>
                  <Input
                    id="link-stats-instagram"
                    type="text"
                    className="form-control"
                    name="instagram"
                  />
                
                
                  <Label id="dailymotionLabel" for="link-stats-dailymotion">DailyMotion</Label>
                  <Input
                    id="link-stats-dailymotion"
                    type="text"
                    className="form-control"
                    name="dailymotion"
                  />
                
                
                  <Label id="twitchLabel" for="link-stats-twitch">Twitch</Label>
                  <Input
                    id="link-stats-twitch"
                    type="text"
                    className="form-control"
                    name="twitch"
                  />
                
                
                  <Label id="pinterestLabel" for="link-stats-pinterest">Pinterest</Label>
                  <Input
                    id="link-stats-pinterest"
                    type="text"
                    className="form-control"
                    name="pinterest"
                  />
                
                
                  <Label id="linkedInLabel" for="link-stats-linkedIn">LinkedIn</Label>
                  <Input
                    id="link-stats-linkedIn"
                    type="text"
                    className="form-control"
                    name="linkedIn"
                  />
                
                
                  <Label id="tipeeeLabel" for="link-stats-tipeee">Tipeee</Label>
                  <Input
                    id="link-stats-tipeee"
                    type="text"
                    className="form-control"
                    name="tipeee"
                  />
                
                
                  <Label id="imdbLabel" for="link-stats-imdb">Imdb</Label>
                  <Input
                    id="link-stats-imdb"
                    type="text"
                    className="form-control"
                    name="imdb"
                  />
                
                
                  <Label id="tiktokLabel" for="link-stats-tiktok">TikTok</Label>
                  <Input
                    id="link-stats-tiktok"
                    type="text"
                    className="form-control"
                    name="tiktok"
                  />
                
                
                  <Label id="soundCloudLabel" for="link-stats-soundCloud">SoundCloud</Label>
                  <Input
                    id="link-stats-soundCloud"
                    type="text"
                    className="form-control"
                    name="soundCloud"
                  />
                
                
                  <Label id="podCloudLabel" for="link-stats-podCloud">PodCloud</Label>
                  <Input
                    id="link-stats-podCloud"
                    type="text"
                    className="form-control"
                    name="podCloud"
                  />
                
                
                  <Label id="discordLabel" for="link-stats-discord">Discord</Label>
                  <Input
                    id="link-stats-discord"
                    type="text"
                    className="form-control"
                    name="discord"
                  />
                
                
                  <Label id="youtubeLabel" for="link-stats-youtube">Youtube</Label>
                  <Input
                    id="link-stats-youtube"
                    type="text"
                    className="form-control"
                    name="youtube"
                  />
                
                <Button
                  tag={Link}
                  id="cancel-save"
                  to="/admin/link-stats"
                  replace
                  color="info"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Retour</span>
                </Button>
                &nbsp;
                <Button
                  color="primary"
                  id="save-entity"
                  type="submit"
                  disabled={updating}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp;Sauver
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  linkStatsEntity: storeState.linkStats.entity,
  loading: storeState.linkStats.loading,
  updating: storeState.linkStats.updating,
  updateSuccess: storeState.linkStats.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkStatsUpdate));
