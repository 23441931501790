import { useState, forwardRef, useImperativeHandle } from 'react';
import "../Crypto.css";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { porttcfolio, updateExchange } from "../data/CryptoData";
import { updatePortfolioInBrowserCache } from '../data/CryptoCache';
import PasswordField from '../array/PasswordField ';

const initAdminExchanges = () => {
    let myArray = [
        { exchange: 'BITGET', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore' }
        ,{ exchange: 'BITMART', key: 'Votre clé Bitmart', secret: 'Votre secret Bitmart',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule' }
        ,{ exchange: 'BITRUE', key: 'Votre clé Bitrue', secret: 'Votre secret Bitrue',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule' }
        ,{ exchange: 'GATEIO', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore' }
        ,{ exchange: 'MEXC', key: 'Votre clé MEXC', secret: 'Votre secret MEXC',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule' }
         ];
    if (porttcfolio.exchanges) {
        for (let j = 0; j < myArray.length; j++) {
            let myObj=myArray[j];
            let trouve = false;
            for (let i = 0; i < porttcfolio.exchanges.length; i++) {
                if (porttcfolio.exchanges[i].exchange.toUpperCase()===myObj.exchange.toUpperCase()) {
                    trouve=true;
                    break;
                }
            }
            if(!trouve){
                porttcfolio.exchanges.push(myObj);
            }
        }
    } else {
        return myArray;
    }
return porttcfolio.exchanges;
}

// Utilisation de forwardRef pour exposer des méthodes via ref
const Admin = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState(initAdminExchanges());

    // Exposer la fonction handleOpen via ref
    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));


    const handleChange = (index: any, field: any, value: any) => {
        if(rows!==undefined){
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
        }
    };

    const handleApply = () => {
        console.log("Données appliquées:", rows);
        if(rows!==undefined){
        for (let i = 0; i < rows.length; i++) {
            updateExchange(rows[i]);
        }
        updatePortfolioInBrowserCache();
        setOpen(false);
    }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>Administration</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Exchange</TableCell>
                                <TableCell>Clé</TableCell>
                                <TableCell>Secret</TableCell>
                                <TableCell>Passphrase</TableCell>
                                <TableCell>Exclusion</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rows!==undefined) && rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {row.exchange}
                                    </TableCell>
                                    <TableCell>
                                        <PasswordField value={row.key}
                                            label="Votre access key"
                                            onChange={(e:any) =>
                                                handleChange(index, 'key', e.target.value)
                                            }/>
                                    </TableCell>
                                    <TableCell>
                                        <PasswordField
                                            value={row.secret}
                                            label="Votre secret"
                                            onChange={(e:any) =>
                                                handleChange(index, 'secret', e.target.value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <PasswordField
                                            value={row.passphrase}
                                            label="Votre passphrase"
                                            onChange={(e:any) =>
                                                handleChange(index, 'passphrase', e.target.value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.exclusions}
                                            onChange={(e) =>
                                                handleChange(index, 'exclusions', e.target.value)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <button onClick={() => setOpen(false)} className="ttcButton">
                    Annuler
                </button>
                <button onClick={handleApply} className="ttcButton">
                    Appliquer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default Admin;
