export interface IVideo {
  id?: number;
  videoId?: string;
  playlistId?: string;
  title?: string;
  publishedDate?: string;
  source?: string;
}

export const defaultValue: Readonly<IVideo> = {};
