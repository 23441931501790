import { Moment } from "moment";
import { IRssFeed } from "../../shared/model/rss-feed.model";
import { IPlaylist } from "../../shared/model/playlist.model";
import { IUniverse } from "../../shared/model/universe.model";
import { IProduct } from "../../shared/model/product.model";
import { INews } from "./news.model";
import { INetwork } from "./activity.model";

export interface IGeeki {
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  title?: string;
  shortDescription?: string;
  longDescription?: string;
  skills?: string;
  geekiType?: string;
  login?: string;
  url?: string;
  facebook?: string;
  twitter?: string;
  wiki?: string;
  nolifeWiki?: string;
  instagram?: string;
  dailymotion?: string;
  twitch?: string;
  pinterest?: string;
  rss?: string;
  createdDate?: Moment;
  tipeee?: string;
  imdb?: string;
  tiktok?: string;
  soundCloud?: string;
  podCloud?: string;
  comment?: string;
  modifiedDate?: Moment;
  validatedDate?: Moment;
  discord?: string;
  youtube?: string;
  rssFeeds?: IRssFeed[];
  playlists?: IPlaylist[];
  universes?: IUniverse[];
  products?: IProduct[];
  network?: INetwork[];
  news?: INews[];
  activities?: any[];
  sex?: string;
}



export const defaultValue: Readonly<IGeeki> = {};
