import "./Playlist.scss";
import "../comics/Comics.scss";

import React from "react";

import YoutubePlaylist from "./YoutubePlaylist";
import DailyMotionPlayer from "./DailyMotionPlayer";
import SkeletonImage from "../image/SkeletonImage";
import GeekiPlaylist from "./GeekiPlaylist";
import GeekiSkeleton from "../image/GeekiSkeleton";
import ModernComicsCell from "../comics/ModernComicsCell";

interface IYoutubePlaylistInputProps {
  playlistId: string;
  playlistName?: string;
  source: string;
  name: string;
  loadFirst: boolean;
  className?: string;
  isInProduction:boolean;
}

interface IYoutubePlaylistState {
  loaded: boolean;
  error: boolean;
  items: any[];
}

class PlaylistWidget extends React.Component<
  IYoutubePlaylistInputProps,
  IYoutubePlaylistState
> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      items: []
    };
  }

  componentDidMount() {
    try {
      if (this.props.source === "DM") {
        this.fetchDailyMotion();
      }
    } catch (e) {
      console.log(
        "Could not load playlist[" + this.props.playlistId + "][" + e + "]"
      );
      this.setState({
        error: true
      });
    }
  }

  fetchDailyMotion = () => {
    const items1 = [];
    items1.push(
      <div className="playlistContainer" key={"ypwddp" + Math.random()}>
        <DailyMotionPlayer h={195} w={320} v={this.props.playlistId} />
        <div className="playlistContainer1" key={"ypwwddpd" + Math.random()}>
          {this.props.playlistName}
        </div>
      </div>
    );
    this.setState({
      loaded: true,
      items: items1
    });
  };

  render() {
    let published;
    let bottomLeft;
    let content;
    if (this.props.source === "YT") {
      return (
        //<PlaylistViewer
        <YoutubePlaylist
        isInProduction={this.props.isInProduction}
          playlistId={this.props.playlistId}
          className={this.props.className}
          playlistName={this.props.playlistName}
          loadFirst={this.props.loadFirst}
          name={this.props.name} />
      );
    } else if (this.props.source === "GP" || this.props.source === "IN") {
      return (
        <GeekiPlaylist
          playlistId={this.props.playlistId}
          className={this.props.className}
          isInProduction={this.props.isInProduction}
          source={this.props.source}
          playlistName={this.props.playlistName}
          loadFirst={this.props.loadFirst}
          name={this.props.name} />
      );
    } else if (this.props.source === "") {
      return "";
    } else {
      console.log("Unknown playlist source:" + this.props.source);
    }

    if (!this.state.loaded) {
      content = <GeekiSkeleton width={320} height={195} />;
    } else if (this.props.source === "DM") {
      if (this.state.error) {
        return this.renderError();
      }
      bottomLeft = (
        <div className="social pl dailymotion" key={"pwd" + Math.random()}>
          <SkeletonImage
            key={"skelImPlaWi"}
            src="content/images/social/dailymotion.svg"
            alt="Geekipédia réseau social DailyMotion"
            title="Vidéo DailyMotion"
            width={25}
            height={25}
          />
        </div>
      );
      content = (
        <div className="playlist-content" key={"pwc" + Math.random()}>
          <article className="comic">{this.state.items}</article>
        </div>
      );
    }
    return (
      <ModernComicsCell
        className="modernCC-widget"
        title={this.props.name}
        content={content}
        footer={published}
        bottomLeft={bottomLeft}
      />
    );
  }

  renderError() {
    return <div key={"pwe" + Math.random()} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default PlaylistWidget;
