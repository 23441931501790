import "./rss.scss";

import React from "react";
import ModernComicsCell from "../comics/ModernComicsCell";

export interface IRssItemInputProps {
  rss?: any;
  title: string;
  link: string;
  content: string;
  logo: any;
}

export interface IRssItemState {
  loaded: boolean;
}

class RssItem extends React.Component<IRssItemInputProps, IRssItemState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  render() {
    const title = this.props.title;
    const description = this.props.content;
    const link = this.props.link;

    const css = "rss comicsCellTitle large icon";
    const content = (
      <div className="rssItem" key={"rssi" + Math.random()}>
        <span className="rssItem-logo">{this.props.logo}</span>
        <span className="rssItem-content">
          <div
            key={"rssid" + Math.random()}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </span>
      </div>
    );
    const footer = (
      <a href={link} target="_new">
        Voir l'article sur le site
      </a>
    ); //TODO CST improve ComicsCell links
    return (
      <ModernComicsCell
        cssPanel="rssPanel stretchparent width100Perc"
        className="width100Perc-fullPage"
        cssTitle={css}
        title={title}
        content={content}
        footer={footer}
      />
    );
  }
}

export default RssItem;
