import "./Footer.scss";
import React from "react";
import { Row, Col } from "reactstrap";
import Copyright from "../copyright/Copyright";
import { Link } from "react-router-dom";
import GeekiSocials from "../social/GeekiSocials";

interface IProps {
  isInProduction: boolean;
}

class Footer extends React.Component<IProps> {
  render() {
    return (
      <Row className="justify-content-center width100Perc footer">
        <Col md="3">
          <Link to="/apropos" className="footer-link">A propos</Link>
          <br></br>
          <Link to="/contact" className="footer-link">Contact</Link>
        </Col>
        <Col md="3">
        <Link to="/legal" className="footer-link">Mentions légales</Link>    
        <br></br>    
        <Link to="/charte" className="footer-link">Charte</Link>   
        </Col>
        <Col md="4">
        <GeekiSocials key="geekiSocials" page={false} isInProduction={this.props.isInProduction}/>
        </Col>
        <Col md="2">
        <br></br><Copyright />
        </Col>
      </Row>);
  }
}

export default Footer;
