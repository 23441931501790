import "./Convention.scss";

import React from "react";
import ModernComicsCell from "../widget/comics/ModernComicsCell";
import DbParseUtils from "../widget/util/DbParseUtil2";
import ConventionDate from "./ConventionDate";
import { Link } from "react-router-dom";
import GeekiPhoto from "../widget/image/GeekiPhoto";
import { getGeekiByLogin } from "../../shared/util/entity-utils";
import { Col, Row } from "reactstrap";


interface IProps {
  convention: any;
  geekies: any[];
  isInProduction: boolean;
}

export default class ConventionLight extends React.Component<IProps> {

    render() {
        let convention= this.props.convention;
        const description = DbParseUtils.parseDescription2(convention.description,false);
        let descriptionDiv=DbParseUtils.toDiv(description);
        const geeki = (convention.geekiLogin?getGeekiByLogin(this.props.geekies, convention.geekiLogin):null);

        
        let content=(<span
        key={"autwidphSPSP" + Math.random()}
        className="modernCC-widget-content convention"
      >
      <div key={"autwidphSPSPD" + Math.random()}>
          <ConventionDate from={convention.startDate} to={convention.endDate}/>
          <span key={"autwidphSPSPD1-" + Math.random()}>          
            <div key={"autwidphSPSPD2-" + Math.random()} className="convention-title">{convention.name}</div>
            <div key={"autwidphSPSPD3-" + Math.random()}
          className="convention-description"
        >
        {geeki && <GeekiPhoto geekiEntity={geeki} imgCount={1} isInProduction={this.props.isInProduction}/>}<span
          dangerouslySetInnerHTML={{ __html: descriptionDiv }}></span>
            </div>
            <div className="width100Perc" key={"autwidphSPSPD4-" + Math.random()}>
            <Row className="width100Perc">
                <Col md="2"className="convention-country">{convention.country && <span>Pays: </span>}</Col>
                <Col md="10">{convention.country}</Col>
              </Row>
            <Row className="width100Perc">
                <Col md="2"className="convention-postalCode">{convention.postalCode && <span>Code postal: </span>}</Col>
                <Col md="10">{convention.postalCode}</Col>
              </Row>
            <Row className="width100Perc">
                <Col md="2"className="convention-city">{convention.city && <span>Ville: </span>}</Col>
                <Col md="10">{convention.city}</Col>
              </Row>
            <Row className="width100Perc">
                <Col md="2"className="convention-address">{convention.address && <span>Adresse: </span>}</Col>
                <Col md="10">{convention.address}</Col>
              </Row>
            <Row className="width100Perc">
                <Col md="2"className="convention-web">{convention.website && <span>Site web: </span>}</Col>
                <Col md="10">{convention.website}</Col>
              </Row>
            <Row className="width100Perc">
                <Col md="2"className="convention-geeki">{convention.geekiLogin && <span>Page Geeki: </span>}</Col>
                <Col md="10">{convention.geekiLogin && <Link className="link" to={"/geeki/"+convention.geekiLogin}>c'est ici</Link>}</Col>
              </Row>
            </div>
          </span>
        </div>     
      </span>);
        
        return (<ModernComicsCell
        key={"autwidphMCC" + Math.random()}
        content={content}
        className="convention-content"
        cssContent="newsWidget"
      />);
      
    }
}
