import React from "react";
import { geekiCenteredLayout } from "../../shared/util/layout-util";

export default class Charte extends React.Component {
  render() {
    const content = (
      <div key={"ch" + Math.random()}>
        <h2 className="geekiText">
          <p>
            Pour pouvoir postuler à une place dans Geekipedia, vous devez
            remplir un des critères suivant:
          </p>
          <p>
            Youtubeur: être présent sur Youtube depuis au moins 3 ans et avoir
            au moins 20 000 followers
          </p>
          <p>
            Presse papier:participer ou avoir participé à un magazine pendant au
            moins 3 ans
          </p>
          <p>Ecrivain: avoir écrit au moins 3 livres</p>
          <p>
            Activité mixte (Youtube, presse papier, TV, ...): être dans le
            milieu Geek depuis au moins 3 ans
          </p>
          <p>Seront exclus d'office tous contenus à caractère haineux.</p>
        </h2>
      </div>
    );

    return geekiCenteredLayout("Charte Geeki",content);
  }
}
