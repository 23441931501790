import "./home.scss";
import "../../custom/widget/instagram/Instagram.scss";

import React from "react";
import { connect } from "react-redux";
import BirthdayComicsCell from "../../custom/widget/news/BirthdayComicsCell";
import GeekiLayout from "../../shared/util/GeekiLayout";


interface IProps extends StateProps {
}

class Home extends React.Component<IProps> {
  render() {
    return (<GeekiLayout title="" component={<><BirthdayComicsCell isTwitter={false} /></>} />);
  }
}

  const mapStateToProps = storeState => ({
    account: storeState.authentication.account
  });

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
