import "../admin.scss";
import React from "react";
import LazyLoad from 'react-lazyload';
import { includesUpperCaseWithoutAccents } from "../../../shared/util/string-utils";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

interface IProps {
}
interface IState {
    entityList: any;
}

export default class AdminNewsList extends React.Component<IProps,IState> {

    constructor(props) {
        super(props);
        this.state = {
            entityList: null
        };
      }

      setIsNewVersion(isNew: boolean) {
      }

      updateEntityList(entityList){
        this.setState({entityList: entityList});
      }

      filterEntities(entityList,filter){
         let filteredEntityList=[];

         for (let i=0;i<entityList.length;i++){
            let entity=entityList[i].entity;
            let isValid=true;
            if(filter.title !=null && filter.title!== undefined && filter.title!==''){
              if(!includesUpperCaseWithoutAccents(entity.title,filter.title)){
                  isValid=false;
              }
          }
          if(filter.id !=null && filter.id!== undefined && filter.id!==''){
            if(!includesUpperCaseWithoutAccents(entity.id,filter.id)){
                isValid=false;
            }
        }
            /*
            if(dateFrom !=null && dateFrom!== undefined && dateFrom!==''){
                let date=new Date(convention.startDate);
                if(date <dateFrom){
                    isValid=false;
                }
            } 
            */
            if(isValid){
                filteredEntityList.push(entityList[i]);
            }
          }
          this.setState({entityList: filteredEntityList});
      }


    render() {
        return ( 
            <div className="width100Perc">
            <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
            {this.renderEntities()}
           </LazyLoad>
           </div>);
    }

    renderEntities(){
      let result=[];
      if(this.state.entityList){
      for (let i=this.state.entityList.length-1;i>=0;i--){
        let entity=this.state.entityList[i];
        result.push(<tr key={`entity-${entity.entity.id}`}>
        <td><Link to={"/admin/news/edit/"+entity.entity.id}>{entity.entity.id}</Link></td>
        <td>{entity.entity.title}</td>
        <td>{entity.entity.createdDate}</td>
        <td>{entity.entity.login}</td>
        <td>{entity.entity.conventionId}</td>
        <td>{entity.entity.img}</td>
        <td>{entity.entity.url}</td>
        <td>{entity.entity.youtube}</td>
      </tr>);
      }
    }

        return (this.state.entityList && this.state.entityList.length > 0 ? (
            <Table responsive aria-describedby="playlist-heading">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Titre</th>
                  <th>Date</th>
                  <th>Geeki</th>
                  <th>Convention</th>
                  <th>Img</th>
                  <th>Url</th>
                  <th>Youtube</th>
                </tr>
              </thead>
              <tbody>
                {
                  result
                }
              </tbody>
            </Table>):<div>Aucune News trouvée</div>);
      }
    
}