import "./Convention.scss";

import React from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { getNewEntities } from "../../reducers/convention.reducer";
import { getEntities as getGeekiEntities } from "../../reducers/geeki.reducer";
import { connect } from "react-redux";
import ConventionsFilter from "./ConventionsFilters";
import ConventionsList from "./ConventionsList";
import Sponsor from "../sponsor/Sponsor";
import GeekiLayout from "../../shared/util/GeekiLayout";
import { Link } from "react-router-dom";

export interface IProps extends StateProps, DispatchProps{
  isInProduction: boolean
}
type Props = IProps & WithRouterProps<IProps>;

export interface IState {
  geekies: any[];
}


class Conventions extends React.Component<Props,IState> {

  listRef;
  conventionList;

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {geekies:[]};
  }

  componentDidMount() {
    this.getConventions();
  }

  filterConventions = (dateFrom,dateTo,name,city,postalCode) => {
     this.listRef.current.filterConventions(this.conventionList,dateFrom,dateTo,name,city,postalCode);
  }

  getConventions = async () => {
    const conventionList: any = await (this.props as any).getNewEntities();
    const result: any = await (this.props as any).getGeekiEntities();
    this.setState({
      geekies: result.value.data
    });
    this.conventionList=conventionList.value.data;
    this.listRef.current.updateConventionList(conventionList.value.data);
  };

  render() {
    return (<GeekiLayout title="Agenda" component={<span className="stretchChild  width100Perc">  
    <Sponsor key="sponsor" />  
      <ConventionsFilter filterConventions={this.filterConventions}/>
      <div style={{width:"100%",
    paddingRight:"15px"}}>
      <Link style={{float:"right"}} to="/conventions/proposal"
                    className="button geekiButton">Proposer un évènement</Link>
                    </div>
      <ConventionsList ref={this.listRef} geekies={this.state.geekies} isInProduction={this.props.isInProduction}/> 
  </span>}/>);
  }
}

const mapStateToProps = ({ convention, applicationProfile }: IRootState) => ({
  conventionList: convention.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getNewEntities,
  getGeekiEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Conventions));
