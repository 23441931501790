import "./CommentBox.scss";
import React from 'react';
import IWithTooltip from '../icon/IWithTooltip';
import { IRootState } from "../../../reducers";
import { connect } from "react-redux";
import { comment } from "../../../reducers/geeki.reducer";
import { alertResult } from "../../admin/util/admin-utils";

export interface IProps
  extends StateProps,
    DispatchProps {
      comment: any;
      geekiLogin: string;
      cols: number;
      year?: number;
}

export interface IState {
  showDiv: boolean;
}

class CommentBox extends React.Component<IProps,IState> {
  comment: string;
  email: string;

  constructor(props) {
    super(props);
    this.state = {
      showDiv: false,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if(name==='comment'){
      this.comment=value;
    }else if(name==='email'){
      this.email=value;
    }
  };

  toggleDiv = (event) => {
    this.setState({showDiv:true});
  };

  // Soumettre le commentaire
  handleSubmit = async (event) => {
    event.preventDefault();

    const result = await this.props.comment(this.props.geekiLogin,this.comment,this.email,this.props.year);
    alertResult(result);
      
      this.comment='';
      this.email='';
  };

render(){
  return (
    <div className="commentbox">
        <IWithTooltip
        className="comment circle commentbox"
        title="Laisser un commentaire"
        onClick={this.toggleDiv}
      />
      {this.state.showDiv && (<div>
      <h3>Laisser un commentaire </h3>
      <form onSubmit={this.handleSubmit}>
        <textarea
          rows={4}
          cols={this.props.cols}
          name="comment"
          value={this.comment}
          onChange={this.handleChange}
          required
          placeholder="Écrivez votre commentaire ici..."
        />
        <br />
        <input
          type="text"
          name="email"
          value={this.email}
          onChange={this.handleChange}
          required
          placeholder="Écrivez votre email ici..."
        />
        <br />
        <button type="submit">Envoyer</button>
      </form>
      </div>)}
    </div>
  );
}
}


const mapStateToProps = ({  }: IRootState) => ({
});

const mapDispatchToProps = {
  comment
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentBox);
