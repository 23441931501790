import React from "react";
import { connect } from "react-redux";
import { IndexRouteProps  } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../../reducers";
import { getEntity, deleteEntity } from "../../../reducers/link-stats.reducer";

export interface ILinkStatsDeleteDialogProps
  extends StateProps,
    DispatchProps,
    IndexRouteProps {
      match:any,
      history:any      
    }

export class LinkStatsDeleteDialog extends React.Component<
  ILinkStatsDeleteDialogProps
> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  confirmDelete = event => {
    this.props.deleteEntity(this.props.linkStatsEntity.id);
    this.handleClose(event);
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    return (
      <Modal isOpen toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>Confirmation suppression</ModalHeader>
        <ModalBody id="geekiApp.linkStats.delete.question">Etes vous sur de vouloir supprimer ce LinkStats?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;Annuler
          </Button>
          <Button
            id="geeki-confirm-delete-linkStats"
            color="danger"
            onClick={this.confirmDelete}
          >
            <FontAwesomeIcon icon="trash" />
            &nbsp;Supprimer
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ linkStats }: IRootState) => ({
  linkStatsEntity: linkStats.entity
});

const mapDispatchToProps = { getEntity, deleteEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkStatsDeleteDialog);
