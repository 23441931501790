import React, { Component, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: { componentStack: string } | null; // Typage correct pour errorInfo
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(_: Error): State {
    // Met à jour l'état pour rendre un fallback UI lors du prochain rendu.
    return { hasError: true, error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    // Vous pouvez aussi logger l'erreur dans un service de reporting
    this.setState({
      error,
      errorInfo,
    });
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Afficher un UI de fallback personnalisé
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </div>
      );
    }

    // Rendre les enfants normalement si pas d'erreur
    return this.props.children;
  }
}

export default ErrorBoundary;

/*
export default class ErrorBoundary extends React.Component<IProps,IState> {

  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    errorInfo: undefined
    };
  }

  componentDidCatch(error:Error, errorInfo:string) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === "development" ? (
          <details className="preserve-space">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : (
          undefined
        );
      return (
        <>
          <h2 className="error">An unexpected error has occurred.</h2>
          {errorDetails}
        </>
      )
      ;
    }
   return <>{this.props.children}</>;
  }
};
*/