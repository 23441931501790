import React from "react";
import ReactPlayer from 'react-player';
import SkeletonImage from "../image/SkeletonImage";

interface IProps {
  v?: string;
  w: number;
  h: number;
  carRef?: any;
}

interface IState {
  preview: any;
  id: any;
}

class DailyMotionPlayer extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      preview: true,
      id: this.props.v ? this.props.v : 0
    };
  }

  render() {
    const width = window.innerWidth <= 481 ? 320 : 640;
    const height = window.innerWidth <= 481 ? 195 : 390;
    const imgUrl =
      "https://www.dailymotion.com/thumbnail/video/" + this.state.id;

    if (this.state.preview) {
      return (
        <button onClick={this.startVideo} className="ytPreview">
          <SkeletonImage
            key="skelImDaMoPlPrev"
            src={imgUrl}
            height={this.props.h}
            width={this.props.w}
          />
        </button>
      );
    } else {
      return (
        <div ref={this.props.carRef} key={"dmp" + Math.random()}>
          {!this.state.preview && (
            <div id="ytcontainer" key={"dmpd" + Math.random()}>
              <div
                key={"dmpdd" + Math.random()}
                id="monitor"
                style={{ width: width + 20 + "px", height: height + 40 + "px" }}
              >
                <div
                  className="ytOffButton"
                  onClick={this.stopVideo}
                  key={"dmpddd" + Math.random()}
                >
                  X
                </div>
                <div
                  key={"dmpdddd" + Math.random()}
                  id="monitorscreen"
                  style={{ width: width + "px", height: height + "px" }}
                >
                  <ReactPlayer url='https://www.dailymotion.com/video/{this.state.id}'
                  width={width}
                  height={height} 
                  playing={true}/>
                </div>
              </div>
            </div>
          )}
          <button onClick={this.stopVideo} className="ytPreview">
            <SkeletonImage
              key={"skelImDaMoPl" + this.state.id}
              src={imgUrl}
              height={this.props.h}
              width={this.props.w}
            />
          </button>
        </div>
      );
    }
  }

  setVideoId = (videoId) => {
    this.setState(state => ({
      preview: false,
      id: videoId
    }));
  }

  startVideo = () => {
    this.setState(state => ({
      preview: false
    }));
  }

  stopVideo = () => {
    this.setState(state => ({
      preview: true
    }));
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default DailyMotionPlayer;
