import Cookies from 'js-cookie';

const cookieName = "TTC_COOKIE_INITIALIZED";

export const isCookieAlreadyRead = () => {
    //Cookies.set(name, value, { expires: days });
    let cookieValue = Cookies.get(cookieName);
    if (cookieValue != null && cookieValue !== undefined) {
        return cookieValue==="true";
    } else {
        return false;
    }
}

export const setCookieAlreadyRead = () => {
    Cookies.set(cookieName, "true", { expires: 365 });//in days
}
