import React from "react";
import "./TableRowEditor.scss";
import GeekiInput from "../../admin/geeki/GeekiInput";
import SkillsEditor from "../skills/SkillsEditor";

interface IProps {
  tableHeader?: string;
  rows: any[];
  def: any[];
  geekies: any;
}
interface IState {
    rows: any[];
  }

export default class TableRowEditor extends React.Component<IProps,IState> {

    constructor(props) {
        super(props);
       this.state={rows:this.props.rows};
      }

      handleChange = idx => e => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        rows[idx][name]=value;
        this.setState({
          rows
        });
      };

      handleAddRow = () => {
        const item = {
        };
        this.props.def.map((def,idx2) =>(item[def]=''));
        this.setState({
          rows: [...this.state.rows, item]
        });
      };
      handleRemoveSpecificRow = (idx) => () => {
        const rows = [...this.state.rows]
        rows.splice(idx, 1)
        this.setState({ rows })
      }

      updateRoleGeeki = (idx,name,e) => {
      const value=e.login;
        const rows = [...this.state.rows];
        rows[idx][name]=value;
        this.setState({
          rows
        });
      };
      updateRoleSkills = (idx,name,skills) => {
          const rows = [...this.state.rows];
          rows[idx][name]=skills;
          this.setState({
            rows
          });
        };
      

      getRows = () =>{
        return this.state.rows;
      }

  render() {
    return (
      <div className="tableRowEditor">
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                  {this.props.tableHeader && <th colSpan={this.props.def.length+2}>{this.props.tableHeader}</th>}
                  </tr>
                  <tr>
                    {this.props.def.map((def,idx2) =>(
                     <th className="text-center">{def.prop}</th>
                    ))}
                    <th><button onClick={this.handleAddRow} className="btn btn-primary geekiButton add">
                  Ajouter ligne
                </button></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      {this.props.def.map((def,idx2) =>(
                        <td>
                          {def.type==='text'?<input
                            type="text"
                            name={def.prop}
                            value={this.state.rows[idx][def.prop]}
                            onChange={this.handleChange(idx)}
                            className="form-control"
                        />:(def.type==='geeki'?<GeekiInput
                        geekies={this.props.geekies}
                        name={def.prop}
                        key={def.prop+'-'+idx+'-'+def.type+"-"+Math.random()}
                        itemLoadKey="login"
                        itemSaveKey="login"
                        callbackFunction={(e) =>  this.updateRoleGeeki(idx,def.prop,e)}
                        disabled={false}
                        value={this.state.rows[idx][def.prop]}
                        defaultValue=""
                        placeholder="Sélectionnez un Geeki"                  
                      />:(def.type==='skills'?
                      <SkillsEditor value={this.state.rows[idx][def.prop]}  
                      key={def.prop+'-'+idx+'-'+def.type+"-"+Math.random()}                   
                        callbackFunction={(e) =>  this.updateRoleSkills(idx,def.prop,e)}
                      />:<div>Type non trouvé</div>))}
                        </td>
                      ))}
                      <td>
                        <button
                          className="btn btn-outline-danger btn-sm geekiButton remove"
                          onClick={this.handleRemoveSpecificRow(idx)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
