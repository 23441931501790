import axios from "axios";
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from "../lib/redux-action.type";
import { cleanEntity } from "../shared/util/entity-utils";
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";

import { IConvention, defaultIConventionValue } from "../shared/model/convention.model";

export const ACTION_TYPES = {
  FETCH_CONVENTION_LIST: "convention/FETCH_CONVENTION_LIST",
  FETCH_CONVENTION: "convention/FETCH_CONVENTION",
  CREATE_CONVENTION: "convention/CREATE_CONVENTION",
  UPDATE_CONVENTION: "convention/UPDATE_CONVENTION",
  DELETE_CONVENTION: "convention/DELETE_CONVENTION",
  RESET: "convention/RESET",   
  FETCH_CONVENTION_BY_LOGIN: "convention/FETCH_CONVENTION_BY_LOGIN",
  CONVENTION_PROPOSAL_SUBMIT: "api/conventions/proposalsubmit",
  FETCH_CONVENTION_LIST_NEW: "convention/FETCH_CONVENTION_LIST_NEW",
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IConvention>,
  entity: defaultIConventionValue,
  updating: false,
  updateSuccess: false,
  submitProposal: String
};

export type ConventionState = Readonly<typeof initialState>;

// Reducer

const convention = (state: ConventionState = initialState, action): ConventionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONVENTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CONVENTION_LIST_NEW):
    case REQUEST(ACTION_TYPES.FETCH_CONVENTION):
    case REQUEST(ACTION_TYPES.FETCH_CONVENTION_BY_LOGIN):
    case REQUEST(ACTION_TYPES.CONVENTION_PROPOSAL_SUBMIT):
    case REQUEST(ACTION_TYPES.CREATE_CONVENTION):
    case REQUEST(ACTION_TYPES.UPDATE_CONVENTION):
    case REQUEST(ACTION_TYPES.DELETE_CONVENTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CONVENTION_LIST):
      case FAILURE(ACTION_TYPES.FETCH_CONVENTION_LIST_NEW):
    case FAILURE(ACTION_TYPES.FETCH_CONVENTION_BY_LOGIN):      
    case FAILURE(ACTION_TYPES.FETCH_CONVENTION):
    case FAILURE(ACTION_TYPES.CREATE_CONVENTION):
    case FAILURE(ACTION_TYPES.UPDATE_CONVENTION):
    case FAILURE(ACTION_TYPES.DELETE_CONVENTION):
    case FAILURE(ACTION_TYPES.CONVENTION_PROPOSAL_SUBMIT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONVENTION_LIST):
      case SUCCESS(ACTION_TYPES.FETCH_CONVENTION_LIST_NEW):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONVENTION):
    case SUCCESS(ACTION_TYPES.FETCH_CONVENTION_BY_LOGIN):  
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CONVENTION_PROPOSAL_SUBMIT):
        return {
          ...state,
          loading: false,
          submitProposal: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONVENTION):
    case SUCCESS(ACTION_TYPES.UPDATE_CONVENTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONVENTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default convention;

const apiUrl = "api/conventions";

// Actions

export const getEntities: ICrudGetAllAction<IConvention> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CONVENTION_LIST,
  payload: axios.get<IConvention>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getNewEntities: ICrudGetAllAction<IConvention> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_CONVENTION_LIST_NEW,
  payload: axios.get<IConvention>(`api/conventionsnew?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<IConvention> = id => {
  const requestUrl = `api/convention/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONVENTION,
    payload: axios.get<IConvention>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<
IConvention
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONVENTION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<
IConvention
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONVENTION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IConvention> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONVENTION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const submitProposal = (country: string,postalCode: string,city: string
  ,address: string,name: string,description: string) => ({
  type: ACTION_TYPES.CONVENTION_PROPOSAL_SUBMIT,
  payload: axios.post(
    ACTION_TYPES.CONVENTION_PROPOSAL_SUBMIT
    , {country:country,postalCode: postalCode,city:city,address:address,name:name
    ,description:description})}
  );

export const reset = () => ({
  type: ACTION_TYPES.RESET
});