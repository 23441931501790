import React from "react";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { IRootState } from "../../../reducers";
import { getNewEntities } from "../../../reducers/news.reducer";
import { getEntities } from "../../../reducers/geeki.reducer";
import { connect } from "react-redux";
import NewsWidget from "./NewsWidget";

import GeekiSkeleton from "../image/GeekiSkeleton";
import ModernComicsCell from "../comics/ModernComicsCell";
import LazyLoad from 'react-lazyload';
import Sponsor from "../../sponsor/Sponsor";

export interface IProps extends StateProps, DispatchProps{
  isTwitter: boolean;
  isInProduction: boolean;
}
type Props = IProps & WithRouterProps<IProps>;

export interface IState {
  newsList?: any;
  geekies: any[];
}

class NewsComicsCell extends React.Component<Props,IState> {
  constructor(props) {
    super(props);
    this.state = {
      newsList: [],
      geekies: []
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = async () => {
    const nList: any = await (this.props as any).getNewEntities();
    let entityList: any = this.props.geekiList;
    if (entityList == null || entityList.length === 0) {
      entityList = await (this.props as any).getEntities();
      entityList = entityList.value.data;
    }
    this.setState({
      newsList: nList.value.data,
      geekies: entityList
    });
  };

  render() {
    if (!this.state.newsList || this.state.newsList.length === 0) {
      return <GeekiSkeleton width="100%" height={300} />;
    }
    var result = [];
    let content = [];
    content.push(
      <p key="n2cc-1">
        Sur Geeki, retrouvez l'actualité de vos auteurs préférés.
      </p>
    );
   
    result.push(
      <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
        <ModernComicsCell
          className="presentation"
          content={<span className="modernCC-widget-content">{content}</span>}
        />
      </LazyLoad>
    );
    result.push(
      <LazyLoad height={300} offset={[-100, 0]} key={"n2ccLL-1"}>
        <Sponsor key="sponsor" />
      </LazyLoad>
    );

    for (var index = 0; index < this.state.newsList.length; index++) {
      result.push(
        <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL" + index}>
          <NewsWidget
            key={"n2ccLLNW" + index}
            news={this.state.newsList[index]}
            index={index}
            isTwitter={(this.props as any).isTwitter}
            geekies={this.state.geekies}
            isInProduction={this.props.isInProduction}
          />
        </LazyLoad>
      );
    }
    return result;
  }
}

const mapStateToProps = ({ news, geeki,applicationProfile }: IRootState) => ({
  newsList: news.entities,
  geekiList: geeki.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getNewEntities,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsComicsCell));
