import React from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { getEntities } from "../../reducers/geeki.reducer";
import { connect } from "react-redux";
import GeekisFilter from "./GeekisFilter";
import GeekisWidget from "./GeekisWidget";
import GeekiLayout from "../../shared/util/GeekiLayout";

export interface IProps extends StateProps, DispatchProps {
  isInProduction: any
 }
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  geekiList?: any;
}

class Geekis extends React.Component<Props, IState> {

  listRef;

  constructor(props) {
    super(props);
    this.state = {};
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.getGeekis();
  }

  filterGeekis = (selectedOptions, name) => {
    this.listRef.current.filterGeekis(selectedOptions, name);
  }

  getGeekis = async () => {

    let entityList: any = this.props.geekiList;
    if (entityList == null || entityList.length === 0) {
      entityList = await (this.props as any).getEntities();
      entityList = entityList.value.data;
    }


    entityList.sort((a, b) => a.title.localeCompare(b.title));
    //TODO CST improve sort
    this.setState({
      geekiList: entityList
    });
  };

  render() {
    return (<GeekiLayout title="PEDIA" component={<div key={"al" + Math.random()}>
      <span className="modernCC-widget-content siteComment">Voici la liste de tous les geekis référencés sur le site. Utilisez les filtres pour personaliser votre recherche.</span>
      <GeekisFilter filterGeekis={this.filterGeekis} />
      <GeekisWidget ref={this.listRef} list={this.state.geekiList} isInProduction={this.props.isInProduction} />
    </div>} />);
  }
}
const mapStateToProps = ({ geeki, applicationProfile }: IRootState) => ({
  geekiList: geeki.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Geekis));
