import "./admin.scss";
import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { liquibaseVersions } from "../../reducers/admin-reducer";
import { IRootState } from "../../reducers";

interface IProps extends StateProps, DispatchProps {
    liquibaseVersions: any;
    fctSetNewVersion: any;
}

interface IState {
  dbVersion: string;
  fileVersion: string;
  }

class AdmLiquibaseIndicator extends React.Component<IProps,IState> {

  constructor(props: any) {
    super(props);    
    this.state={dbVersion:'',fileVersion:''}
    this.getLiquibaseVersions();   
    }

  getLiquibaseVersions = async () => {
    const obj = await this.props.liquibaseVersions();
    this.setState({dbVersion:obj.value.data.dbVersion,fileVersion:obj.value.data.fileVersion});
    
    this.props.fctSetNewVersion(obj.value.data.dbVersion!==obj.value.data.fileVersion);
  } 

 
  render() {
return (<Row className="justify-content-center">
<Col md="3">Liquibase</Col>
<Col md="3">DB: {this.state.dbVersion}</Col>
<Col md="3" className={this.state.fileVersion!==this.state.dbVersion?'admLiquiVersionOk':'admLiquiVersionNok'}>File: {this.state.fileVersion}</Col>
</Row>);
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
});

const mapDispatchToProps = {
    liquibaseVersions
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmLiquibaseIndicator);
