import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form, Input } from "reactstrap";

import { handlePasswordResetInit, reset } from "../../../../reducers/password-reset.reducer";
import ComicsButton from "../../../../custom/widget/comics/ComicsButton";
import InfoComicsCell from "../../../../custom/widget/comics/InfoComicsCell";

export type IPasswordResetInitProps = DispatchProps;

export class PasswordResetInit extends React.Component<
  IPasswordResetInitProps
> {
  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetInit(values.email);
    event.preventDefault();
  };

  render() {
    const content = (
      <div className="width100Perc">
        <Row className="justify-content-center">
          <Col md="8">
            <InfoComicsCell
              style={{ width: "200px" }}
              className="inlineBlock"
              innerClassName="univ0"
              speech="Entrez l'adresse email que vous avez saisi lors de l'enregistrement"
            />
            <Form onValidSubmit={this.handleValidSubmit}>
              <Input
                name="email"
                label="Email"
                placeholder="placeholder"
                type="email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Email obligatoire"
                  },
                  minLength: {
                    value: 5,
                    errorMessage: "Email minimum 5"
                  },
                  maxLength: {
                    value: 254,
                    errorMessage: "Email maximum 254"
                  }
                }}
              />
              <ComicsButton title="Réinitialiser" />
            </Form>
          </Col>
        </Row>
      </div>
    );

    return (
      <div className="centered-out pwdReset">
        <div className="centered-in">{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = { handlePasswordResetInit, reset };

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  null,
  mapDispatchToProps
)(PasswordResetInit);
