
export interface IConvention {
  id?: number;
  startDate?: string;
  endDate?: string;
  country?: string;
  postalCode?: string;
  city?: string;
  address?: string;
  description?: string;
  geekiLogin?: string;
  name?: string;
  website?: string;
}
export interface IAdminConvention {
  status: string;
  entity: IConvention;
}

export const defaultIConventionValue: Readonly<IConvention> = {};
