import "../admin.scss";
import React from "react";
//import { registerPlugin } from "react-filepond";
//import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
//import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { adminLayout } from "../util/admin-utils";

// Register the plugins
//registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface IProps {
  isInProduction: boolean;
}
interface IState {
  files: any[];
  pond?: any;
}

// Our app
export default class Upload extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      /*files: [
        {
          source: "index.html",
          options: {
            type: "local"
          }
        }
      ]*/
      files: []
    };
  }

  handlePondFile(error, file) {
    if (error) {
      console.log(error);
      return;
    }
    console.log("File added", file);
    //   this.fetchImages()
  }

  render() {
 //   const content = "";
    /*
          const content=<div className="width100Perc">
      <FilePond
      name="file"
      required={true}
      allowMultiple={false}
      maxFiles={1}
      allowFileTypeValidation={true}
      acceptedFileTypes={['image/*']}
      fileValidateTypeLabelExpectedTypesMap={{ 'image/png': '.png' }}
      fileValidateTypeLabelExpectedTypes='Ce n est pas un PNG'
      server={
          {
              timeout: 99999,
              process: (fieldName, file, metadata, load, error, progress, abort) => {

                  const formData = new FormData()
                  formData.append('file', file, file.name)

                  // aborting the request
                  const CancelToken = axios.CancelToken
                  const source = CancelToken.source()

                  axios({
                      method: 'POST',
                      url: 'https://www.geeki.fr/api/upload',
                      data: formData,
                      cancelToken: source.token,
                      onUploadProgress: (e) => {
                          // updating progress indicator
                          progress(e.lengthComputable, e.loaded, e.total)
                      }
                  }).then(response => {
                      // passing the file id to FilePond
                   //   load(response.file)
                  }).catch((thrown) => {
                      if (axios.isCancel(thrown)) {
                          console.log('Request canceled', thrown.message)
                      } else {
                          // handle error
                      }
                  })
                  // Setup abort interface
                  return {
                      abort: () => {
                          source.cancel('Operation canceled by the user.')
                          abort()
                      }
                  }
              }

          }
      }
  /></div>;
  */
    const content2 = "temporairement indisponible";
    return adminLayout("Téléchargement",content2,this.props.isInProduction);
  }
}
