import 'semantic-ui-css/semantic.min.css'
import '../../geeki/Geeki.scss'
import React from "react";
import { Icon } from "semantic-ui-react";
import SkeletonImage from '../image/SkeletonImage';
import { imgUrl } from '../../../shared/util/url-utils';

interface IProps {
  className?: string;
  size?: any;
  color?: string;
  title?: string;
  isInProduction:boolean;
}

export default class IconWithSub extends React.Component<IProps> {
  render() {
    const css = this.props.className + " icon textShadow";
    if (this.props.size) {
      if(this.props.className === 'twitter'){
        const imgSrc = imgUrl("/content/images/social/twitterx.svg",this.props.isInProduction);
        return (
          <span className='iconWithSub'>
            <div><SkeletonImage
            src={imgSrc}
            alt={"X ex Twitter"}
            title={"X ex Twitter"}
            width={40}
            height={40}
          /></div>
            <span>{this.props.title}</span>
          </span>
        );
      }
      return (
        <span className='iconWithSub'>
          <div><Icon size={this.props.size} color="green" className={css} /></div>
          <span>{this.props.title}</span>
        </span>
      );
    } else {
      return (
        <span className='iconWithSub'>
          <div><Icon color="green" className={css} /></div>
          <span>{this.props.title}</span>
        </span>
      );
    }
  }
}
