import "./admin.scss";

import React from "react";
import { adminLayout } from "./util/admin-utils";
import AdmLiquibaseIndicator from "./AdmLiquibaseIndicator";

export interface IProps {
    filterComponent: any;
    listComponent: any;
    screenName: string;
    listRef: any;
    filterRef: any;
    editor?: any;
    editorRef?: any;
    versionIndicator: boolean;
    isInProduction:boolean;
}

export default class AdminFilteredList extends React.Component<IProps> {

    setIsNewVersion = (isNew: boolean) => {
        if (this.props.versionIndicator) {
            this.props.listRef.current.setIsNewVersion(isNew);
            this.props.filterRef.current.setIsNewVersion(isNew);
        }
    }

    render() {
        const paramList = {
            ref: this.props.listRef
        };
        const paramFilter = {
            ref: this.props.filterRef
        };
        const paramEditor = {
            ref: this.props.editorRef
        };

        const editor = this.props.editor ? React.cloneElement(this.props.editor, paramEditor) : "";
        const admList = React.cloneElement(this.props.listComponent, paramList);
        const admFilter = React.cloneElement(this.props.filterComponent, paramFilter);

        return adminLayout(this.props.screenName,
            <span className="stretchChild width100Perc" key={'afl-' + Math.random()}>
                {this.props.versionIndicator && <AdmLiquibaseIndicator
                    fctSetNewVersion={this.setIsNewVersion} key={'afl-' + Math.random()} />}
                {editor}
                {admFilter}
                {admList}
            </span>,this.props.isInProduction);
    }
}
