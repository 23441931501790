import React from "react";
import DailyMotionPlayer from "./DailyMotionPlayer";
import InaPlayer from "./InaPlayer";
import YoutubePlayer from "./YoutubePlayer";

interface IProps {
  w: any;
  h: any;
  carRef: any;
  video?: any;
}

interface IState {
  preview: any;
  id: any;
  source: string;
}

class GeekiPlayer extends React.Component<IProps,IState> {
  carRef;

  constructor(props) {
    super(props);
    this.carRef = React.createRef();
    this.state = {
      preview: true,
      id: 0,
      source: undefined
    };

  }

  componentDidUpdate() {
    if (this.state.source && this.state.id) {
      this.carRef.current.setVideoId(this.state.id);
      this.carRef.current.startVideo();
    }
  }

  render() {
    let result = [];
    if (this.state.source && this.state.source === "IN" && this.state.id) {
      result.push(
        <InaPlayer
          h={this.props.h}
          w={this.props.w}
          ref={this.carRef}
          carRef={this.carRef}
          key={"player" + this.state.id}
        />
      );
    } else if (
      this.state.source &&
      this.state.source === "YT" &&
      this.state.id
    ) {
      result.push(
        <YoutubePlayer
          h={this.props.h}
          w={this.props.w}
          ref={this.carRef}
          carRef={this.carRef}
          key={"player" + this.state.id}
        />
      );
    } else if (
      this.state.source &&
      this.state.source === "DM" &&
      this.state.id
    ) {
      result.push(
        <DailyMotionPlayer
          h={this.props.h}
          w={this.props.w}
          ref={this.carRef}
          carRef={this.carRef}
          key={"player" + this.state.id}
        />
      );
    }
    return result;
  }

  setVideoId = (videoId, source) => {
    this.setState(state => ({
      preview: false,
      id: videoId,
      source: source
    }));
  }

  startVideo = () => {
    this.setState(state => ({
      preview: false
    }));
  }

  stopVideo = () => {
    this.setState(state => ({
      preview: true
    }));
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default GeekiPlayer;
