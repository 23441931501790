import { Link } from "react-router-dom";
import { geekiLabelWithNickName } from "../config/GeekiUtil";
import Product from "../widget/product/ProductWidget";
import SocialItem from "../widget/social/SocialItem";
import GeekiPhoto from "../widget/image/GeekiPhoto";
import { getGeekiByLogin } from "../../shared/util/entity-utils";
import ModernComicsCell from "../widget/comics/ModernComicsCell";

export default class ActivityUtils {
  render() {
    //workaround, else: instance.render is not a function
  }

  static createGeekiLink(tl, activityRole, login, geekies) {
    return ActivityUtils.createGeekiLoginLink(tl, activityRole.geekiLogin, geekies);
  }
  static createGeekiLoginLink(tl, geekiLogin, geekies) {
    const className = tl ? "geekiLink" : null;
    return (<span key={"cpL" + Math.random()} className={className}>
      {ActivityUtils.generateGeekiLinkByLogin(geekiLogin, geekies)}{" "}
    </span>);
  }



  static generateGeekiLinkByLogin(login, geekies) {
    for (var i = 0; i < geekies.length; i++) {
      if (geekies[i].login === login) {
        return ActivityUtils.generateGeekiLink(geekies[i]);
      }
    }
    return "";
  }

  static generateGeekiLink(geeki) {
    let name = geekiLabelWithNickName(geeki);
    let path = "/geeki/";
    return (
      <Link
        key={"cpL" + Math.random()}
        className="link"
        to={path + geeki.login}
      >
        {name}
      </Link>
    );
  }

  static render(activity, activityRole, geekiLogin, geekies,isInProduction) {
    let menuItems = [];
    let menuItemsUrl = [];
    if (activity.img) {
      menuItems.push(
        <Product product={activity} key={"biTiLiElSc" + Math.random()} isInProduction={isInProduction}/>
      );
    } else if (activity.youtube) {
      let videos = activity.youtube.split("|");
      for (let i = 0; i < videos.length; i++) {
        menuItemsUrl.push(this.renderVideo(activity, videos[i],isInProduction));
      }
    } else if (activityRole !== undefined && activityRole.geekiLogin && geekiLogin !== activityRole.geekiLogin) {
      const url = "/geeki/" + activityRole.geekiLogin;
      const geeki = getGeekiByLogin(geekies, activityRole.geekiLogin);
      const photo = (
        <GeekiPhoto geekiEntity={geeki} imgCount={1} isInProduction={isInProduction} />

      );
      menuItems.push(
        <div className="productWidget url" key={"biTiLiElScL-" + Math.random()}>
          <ModernComicsCell
            key={"menIt" + Math.random()}
            globalUrl={url}
            cssPanel={"comicsCell"}
            className="stretchChild"
            cssTitle="sign large icon"
            content={photo}
          />
        </div>
      );
    }
    for (let i = 0; i < menuItemsUrl.length; i++) {
      menuItems.push(menuItemsUrl[i]);
    }
    return menuItems;
  }

  static renderVideo(activity, video,isInProduction) {
    let url;
    let src;
    let img;
    if (video.startsWith("DM=")) {
      url = "https://www.dailymotion.com/video/" + video.substring(3);
      src = "dailymotion";
      img = (
        <img
          alt=""
          src={
            "https://www.dailymotion.com/thumbnail/video/" + video.substring(3)
          }
          width="490"
          key={"biTiLiElScI" + Math.random()}
        />
      );
    } else {
      url = "https://www.youtube.com/watch?v=" + video;
      src = "youtube";
      img = (
        <img alt=""
          src={"https://i1.ytimg.com/vi/" + video + "/mqdefault.jpg"}
          width="490"
          key={"biTiLiElScI" + Math.random()}
        />
      );
    }

    return (
      <div className="productWidget url" key={"biTiLiElSc" + Math.random()}>
        <div key={"biTiLiElScD" + Math.random()}>
          <div className="title" key={"biTiLiElScDd" + Math.random()}>
            {activity.title}
          </div>
          {img}
          <br />

          <SocialItem
            key="urlyoutube"
            name="Voir la vidéo"
            linkType="URL"
            param="URL"
            encodeUrl={true}
            url={url}
            svg={src + ".svg"}
            css={src}
            isInProduction={isInProduction}
          />
        </div>
      </div>
    );
  }
}
