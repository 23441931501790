import "./Product.scss";
import "../comics/Comics.scss";

import React from "react";
import ComicsPage from "../../../custom/widget/comics/ComicsPage";
import Product from "./ProductWidget";
import DbParseUtils2Mappings from "../util/DbParseUtils2Mappings";

export interface IProductProps {
  category: String;
  products: any[];
  isInProduction: boolean;
}

class ProductsCategory extends React.Component<IProductProps> {
  render() {
    const items = [];
    let i = 0;
    this.props.products.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    for (const value of this.props.products.values()) {
      i = i + 1;
      items.push(
        <div style={{ order: i }} key={"pc" + value.id + Math.random()}>
          <Product product={value} isInProduction={this.props.isInProduction}/>
        </div>
      );
    }
    return (
      <ComicsPage
        content={items}
        title={"Catégorie " + DbParseUtils2Mappings.translateActivity(this.props.category)}
      />
    );
  }
}

export default ProductsCategory;
