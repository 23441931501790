import "./Geeki.scss";

import React from "react";
import { Row, Col, Input } from "reactstrap";
import DbParseUtils2Mappings from "../widget/util/DbParseUtils2Mappings";
import FiltersBox from "../widget/filter/FiltersBox";

interface IProps {
    filterGeekis: any;
}
interface IState {
    dropdownOpen:boolean;
  }

export default class GeekisFilter extends React.Component<IProps, IState> {

    geekiTypes;
    selectedOptions;
    name;
    

    constructor(props) {
        super(props);
        this.state={dropdownOpen:false};
        this.geekiTypes = DbParseUtils2Mappings.geekiTypes;
        this.selectedOptions=new Map();
      }

    filter = () => {
        let list= [];
        this.selectedOptions.forEach((value, key) => {
            if(value){
                list.push(key);
            }
          });        
        this.props.filterGeekis(list,this.name);
    }

    handleNameChange = (name) => {
        this.name=name.target.value;
        this.filter();
    };

    handleSelectOption = (event) => {
        this.selectedOptions.set(event,!this.selectedOptions.get(event));
        this.filter();
    }

    render() {
        return (<FiltersBox content={this.renderContent()} expanded={true}/>);
    }

    toggleDropdown = () => {
        if(!this.state.dropdownOpen){
        this.setState({dropdownOpen: !this.state.dropdownOpen});
        }
    }
    renderContent(){       
        return (
            <div className="width100Perc filter-box">
                <Row className="justify-content-center width100Perc">
                    <Col md="12">
                    {Array.from(this.geekiTypes.keys()).map((key) => (
                        <span key={"prodFilS"+key}><input id={"prodFil"+key} key={"prodFilI"+key} type="checkbox" onClick={() => this.handleSelectOption(key)} checked={this.selectedOptions.get(key)} readOnly />
                        <label key={"prodFilL"+key}>{this.geekiTypes.get(key)}</label></span>
                      ))}
                    </Col>
                </Row>
                <Row className="justify-content-center width100Perc">
                    <Col md="3">
                    <Input
                            key="ng-name"
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Nom"
                            onChange={this.handleNameChange}
                        />
                    </Col>
                    <Col md="3">
                    </Col>
                    <Col md="6">
                    </Col>
                </Row>
            </div>);
    }
}