import React from "react";
import ComicsButton from "../../../custom/widget/comics/ComicsButton";
import { Label, Form, Input } from "reactstrap";
import ModernComicsCell from "../comics/ModernComicsCell";
import { alertResult } from "../../admin/util/admin-utils";
import { IRootState } from "../../../reducers";
import { connect } from "react-redux";
import { contact } from "../../../reducers/geeki.reducer";

interface IProps 
extends StateProps,
  DispatchProps {
  titleLink?: string;
}

class ContactWidget extends React.Component<IProps> {

  handleValidSubmit =  async (values) => {
    this.setState({ values }); 

    const result = await this.props.contact(values.email,values.title,values.message);
    alertResult(result);

  }

  render() {
    const newsContent = (
        <div key={"cwd" + Math.random()}>
          <h2 className="geekiText">
            Vous avez des questions, des suggestions...
            <br />
            Pour nous contacter, veuillez remplir le formulaire:
          </h2>

          <form onSubmit={this.handleValidSubmit}>
            
              <Label id="titleLabel" for="contact-title">
                Titre
              </Label>
              <Input
                id="contact-title"
                type="text"
                name="title"
                placeholder="Le titre de votre message ici"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: "Le titre ne doit pas dépasser 50 caractères"
                  }
                }}
              />
            
            
              <Label id="titleLabel" for="contact-message">
                Message
              </Label>
              <Input
                id="contact-message"
                type="textarea"
                name="message"
                placeholder="Votre message ici"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage:
                      "Le message ne doit pas dépasser 500 caractères"
                  }
                }}
              />
            
            
              <Label id="emailLabel" for="contact-email">
                Adresse email
              </Label>
              <Input
                id="contact-email"
                type="text"
                name="email"
                placeholder="Votre adresse e-mail"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: "L'email ne doit pas dépasser 50 caractères"
                  }
                }}
              />
            
            
              <ComicsButton title="Envoyer" />
            
          </form>
        </div>
    );

    return (
        <ModernComicsCell
        key={"autwidphMCC" + Math.random()}
        content={newsContent}
        className="convention-content"
        cssContent="newsWidget"
    />
    );
  }
}

const mapStateToProps = ({  }: IRootState) => ({
});

const mapDispatchToProps = {
  contact
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactWidget);
