import React from "react";
import SkeletonImage from "../image/SkeletonImage";
import DbParseUtils from "../util/DbParseUtil2";
import { imgUrl } from "../../../shared/util/url-utils";

interface IProps {
  w: any;
  h: any;
  id?: any;
  player?: any;
  setVideoId: any;
  carRef: any;
  video: any;
  isInProduction:boolean;
}

interface IState {
  preview: any;
}

class GeekiVideoSelector extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      preview: true
    };
  }

  render() {
    const imgUrl = this.getImgUrl();
    const fct = this.state.preview ? this.startVideo : this.stopVideo;
    return (
      <button
        onClick={fct}
        className="ytPreview"
        key={"ytPlaylistItemPreviewLink" + this.props.video.videoId}
      >
        <div
          key={"gvs" + Math.random()}
          className="ytPlaylistItemPreview"
          id={"ytPlaylistItemPreview" + this.props.video.videoId}
        >
          <SkeletonImage
            src={imgUrl}
            height={this.props.h}
            width={this.props.w}
            key={"ytPlaylistItemPreviewSkel" + this.props.video.videoId}
          />
          <div className="playlistContainer1" key={"gwsd" + Math.random()}>
            {this.props.video.title}
          </div>
          <div className="playlistContainer2" key={"gwsdd" + Math.random()}>
            {"Publié le " +
              DbParseUtils.formatDate(this.props.video.publishedDate)}
          </div>
        </div>
      </button>
    );
  }

  getImgUrl() {
    if ("GP" === this.props.video.source) {
      return imgUrl("/content/images/geekiplaylist.png",this.props.isInProduction);
    } else if ("IN" === this.props.video.source) {
      return imgUrl("/content/images/social/ina.svg",this.props.isInProduction);
    } else if ("YT" === this.props.video.source) {
      return (
        "https://i1.ytimg.com/vi/" + this.props.video.videoId + "/mqdefault.jpg"
      );
    } else {
      //TODO fix
      return (
        "https://i1.ytimg.com/vi/" + this.props.video.videoId + "/mqdefault.jpg"
      );
    }
  }

  startVideo = () => {
    this.props.setVideoId(this.props.video.videoId, this.props.video.source);
    this.setState(state => ({
      preview: false
    }));
  }

  stopVideo = () => {
    this.setState(state => ({
      preview: true
    }));
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default GeekiVideoSelector;
