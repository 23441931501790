import AdminSideBar from "../../widget/sidebar/AdminSideBar";
import { hasAnyAuthority } from "../../../shared/auth/private-route";
import { AUTHORITIES } from "../../../custom/config/constants";
import { connect } from "react-redux";
import { IRootState } from "../../../reducers";

interface IProps{
  hasAnyAuthorities?: string[];
}

export const adminLayout = (title,component,isInProduction) =>
(<>
    <div className="geekiLayout-container" id="geekiLayout-container">
    <AdminSideBar isInProduction={isInProduction}/>
            <div className="geekiLayout-content">
                <div className="geekiLayout-content-inner">
                    <div className="geekiLayout-header">{title}</div>
                    {component}
                </div>
            </div>
        </div>
  </>);

  export const alertResult = (result) =>{
    if(result.value){
      if( result.value.status===200){
        alert(result.value.data);
      }else{
        alert("Erreur "+result.value.status+": "+result.value.data);
      }
    }else{
      alert(result);
    }
  }

  export const isAdmin = props => {
    return hasAnyAuthority(props.authentication.account.authorities, [
      AUTHORITIES.ADMIN
    ]);
  }
  export const isGeeki = props => {
    return hasAnyAuthority(props.authentication.account.authorities, [
      AUTHORITIES.GEEKI
    ]);
  }

  const mapStateToProps = (
    {
      authentication: { account, sessionHasBeenFetched }
    }: IRootState,
    { hasAnyAuthorities = [] }: IProps
  ) => ({
    isAuthorized: hasAnyAuthority(account.authorities, hasAnyAuthorities),
    sessionHasBeenFetched,
  });
  
  type StateProps = ReturnType<typeof mapStateToProps>;

  export const AdminUtil = connect<StateProps, undefined, IProps>(
    mapStateToProps,
    null,
    null
  );
  
  export default AdminUtil;