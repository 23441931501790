import "./AdminSideBar.scss";

import React from "react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { routeUrl } from "../../../shared/util/url-utils";
import { imgUrl } from "../../../shared/util/url-utils";

export interface IProps {
  isInProduction:boolean
  }

  export default class AdminSideBar extends React.Component<IProps> {
  
    render() {
        let selectedCategory='';
 let categories=[];
 categories.push({name:'Geekis', path:routeUrl("/admin/geeki")});
 categories.push({name:'News', path:routeUrl("/admin/news")});
 categories.push({name:'Activités', path:routeUrl("/admin/activity")});
 categories.push({name:'Conventions', path:routeUrl("/admin/convention")});
 categories.push({name:'Playlist Candidats', path:routeUrl("/admin/playlistcandidate")});
 categories.push({name:'Playlists', path:routeUrl("/admin/playlist")});
 categories.push({name:'Upload', path:routeUrl("/admin/upload")});
 categories.push({name:'Liquibase', path:routeUrl("/admin/liquibase")});
 categories.push({name:'CSV tools', path:routeUrl("/admin/csv")});
 categories.push({name:'Data Quality', path:routeUrl("/admin/dataquality")});
 categories.push({name:'Test Page', path:routeUrl("/admin/test")});

 

        return <span>
         <Stack
         className="admSidebar geekiLayout-sidebar open"
      direction="row"
      sx={{
        overflowY: "auto",
        height: "100%",
        width: "230px",
        flexDirection: { md: "column" },
        background: "rgb(100,100,100)"
      }}
    >
      <Link to="/" className="geekiLogo" style={{ 
      display: "flex", 
      alignItems: "left"
       }}>
    <img src={imgUrl("/content/images/geekiLogo.png",this.props.isInProduction)} alt="logo" height={90} width={230} />
  </Link>
  
  <br/>
        {
        //button ->  onClick={()=>{setSelectedCategory(category.name)}}
        categories.map((category) => (
        <a
          className="admSidebarLink"
          href={category.path}
          style={{
            background: category.name === selectedCategory && 'green',
            color: "white"
          }}
          key={category.name}
        >
          <span style={{
            color: category.name === selectedCategory ? 'white' : 'darkgreen',
            marginRight: "15px"
          }}>
            {category.icon}
          </span>
          <span style={{ opacity: category.name === selectedCategory ? "1" : "0.8" }}>
            {category.name}
          </span>
        </a>
      ))}
        </Stack>
       </span>;
    }
}