import React from "react";
import InfoComicsCell from "../comics/InfoComicsCell";

interface IProps {
  w: any;
  h: any;
  carRef: any;
}

interface IState {
  preview: any;
  id: any;
}

class InaPlayer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      preview: true,
      id: 0
    };
  }

  render() {
    const width = this.props.w;
    const height = this.props.h;

    //  const imgUrl = 'https://i1.ytimg.com/vi/' + this.state.id + '/mqdefault.jpg';
  //  const imgUrl = "content/images/social/ina.svg";
    // host="http://www.youtube.com"
    if (this.state.preview) {
      return (
        <div
          id="ytcontainer"
          ref={this.props.carRef}
          key={"ip1" + Math.random()}
        >
          <div
            key={"ip1d" + Math.random()}
            id="monitor"
            style={{ width: width + 20 + "px", height: height + 40 + "px" }}
          >
            <InfoComicsCell
              className="homeInfo inlineBlock"
              innerClassName="univ0"
              innerStyle={{ width: width - 20 + "px", height: height + "px" }}
              speech="Cliquez sur une vidéo pour la visionner"
            />
          </div>
        </div>
      );
    } else {
      /*<iframe width='100%' height='100%' frameBorder='0' marginHeight ={0} marginWidth={0} scrolling ='no' src='https://player.ina.fr/player/embed/I08066312/1/1b0bd203fbcd702f9bc9b10ac3d0fc21/wide/1' allow ='fullscreen,autoplay'></iframe>
       */
      return (
        <div
          key={"ip" + Math.random()}
          id={this.state.id}
          ref={this.props.carRef}
          className="ytcontainertop"
        >
          {!this.state.preview && (
            <div id="ytcontainer" key={"ipd" + Math.random()}>
              <div
                key={"ipdd" + Math.random()}
                id="monitor"
                style={{ width: width + 20 + "px", height: height + 40 + "px" }}
              >
                <div
                  className="ytOffButton"
                  onClick={this.stopVideo}
                  key={"ipddd" + Math.random()}
                >
                  X
                </div>
                <div
                  key={"ipdddd" + Math.random()}
                  id="monitorscreen"
                  style={{ width: width + "px", height: height + "px" }}
                >
                  <iframe
                  title={this.state.id }
                    width={width + "px"}
                    height={height + "px"}
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                    scrolling="no"
                    src={
                      "https://player.ina.fr/player/embed/" +
                      this.state.id +
                      "/wide/1"
                    }
                    allow="fullscreen,autoplay"
                  ></iframe>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  setVideoId = (videoId) => {
    this.setState(state => ({
      preview: false,
      id: videoId
    }));
  }

  startVideo = () => {
    this.setState(state => ({
      preview: false
    }));
  }

  stopVideo = () => {
    this.setState(state => ({
      preview: true
    }));
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

export default InaPlayer;
