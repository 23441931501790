import { Moment } from "moment";

export interface IRssFeed {
  id?: number;
  modifiedDate?: Moment;
  index?: number;
  title?: string;
  link?: string;
  content?: any;
}

export const defaultValue: Readonly<IRssFeed> = {};
