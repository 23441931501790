import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { getAdminNewsEntities } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import AdminFilteredList from "../AdminFilteredList";
import AdminNewsList from "./AdminNewsList";
import AdminNewsFilter from "./AdminNewsFilter";

export interface IProps extends StateProps, DispatchProps{
  isInProduction: boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class AdminNews extends React.Component<Props> {

  listRef;
  filterRef;
  entityList

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminNewsEntities();
  //  tmpEntityList.value.data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    this.entityList=tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  };

  filterMethod = (filter) => {
    this.listRef.current.filterEntities(this.entityList,filter);
 }

  render() {
    return (<AdminFilteredList screenName="News"
            filterComponent={<AdminNewsFilter filterMethod={this.filterMethod}/>}
            listComponent={<AdminNewsList/>}
            listRef={this.listRef}
            filterRef={this.filterRef}
            versionIndicator={true}
            isInProduction={this.props.isInProduction}
    />);
  }
}

const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminNewsEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNews));
