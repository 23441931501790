import React from "react";
import PlaylistWidget from "./PlaylistWidget";
import ComicsPage from "../comics/ComicsPage";

interface IProps {
  playlists: any;
  className?: string;
  transparent?: boolean;
  collapsable?: boolean;
  title?: string;
  isInProduction:boolean;
}
export default class PlaylistsWidget extends React.Component<IProps> {

  render() {
    const content = [];
    if (this.props.playlists) {
      let cpt = 0;
      for (const playlist of this.props.playlists.values()) {
        content.push(
          <PlaylistWidget
            key={"playlistWid" + playlist.playlistId + "-"+cpt++}
            playlistId={playlist.playlistId}
            isInProduction={this.props.isInProduction}
            name={playlist.name}
            source={playlist.source}
            loadFirst={true}
          />
        );
      }
    }
    return (
      <ComicsPage
        title={this.props.title}
        content={content}
        className={"playlists " + this.props.className}
        transparent={this.props.transparent}
        collapsable={this.props.collapsable}
      />
    );
  }
}
