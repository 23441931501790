import React from "react";
import YoutubePlayer from "./YoutubePlayer";
import GeekiVideoSelector from "./GeekiVideoSelector";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import IWithTooltip from "../icon/IWithTooltip";

interface IProps {
  videoList?: any;
  expanded: boolean;
  loadFirst: boolean;
  closeButton: any;
  isInProduction:boolean;
}

interface IState {
  videos: any[];
  menu: any;
  selected: any;
}

export default class YoutubePlaylistScroller extends React.Component<IProps,IState> {
  player;
  carRef;

  constructor(props) {
    super(props);
    this.carRef = React.createRef();
    this.state = {
      videos: [],
      menu: undefined,
      selected: undefined
    };
  }

  onSelect = key => {
    //  this.setState({ selected: key });
  };

  componentDidMount() {
    const width =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 240 : 320) : 640;
    const height =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 145 : 195) : 390;
    const selectorWidth =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 160 : 240) : 320;
    const selectorHeight =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 97 : 145) : 195;

    const vids = [];
    const videoList = this.props.videoList;
    let ytPlayer = (
      <YoutubePlayer
        h={height}
        w={width}
        ref={this.carRef}
        carRef={this.carRef}
      />
    );
    if (videoList !== undefined && videoList.length > 0) {
     /* const value = this.props.loadFirst
        ? videoList[0]
        : videoList[Math.floor(Math.random() * videoList.length)];*/
      let video;

      for (let i = 0; i < videoList.length; i++) {
        video = videoList[i];
        let selector = (
          <GeekiVideoSelector
          isInProduction={this.props.isInProduction}
            h={selectorHeight}
            w={selectorWidth}
            player={ytPlayer}
            video={video}
            carRef={this.carRef}
            setVideoId={this.setVideoId}
          />
        );
        vids.push(selector);
      }

      //  imgUrl = "https://i1.ytimg.com/vi/" + value.videoId + "/mqdefault.jpg";
    }
    this.setState({
      videos: vids
    });
    this.player = ytPlayer;
  }

  setVideoId = (videoId) => {
    this.carRef.current.setVideoId(videoId);
  }

  render() {
    let menuItems = [];
    for (let i = 0; i < this.state.videos.length; i++) {
      menuItems.push(
        <div className="menu-item" key={"pcsv" + i + Math.random()}>
          {this.state.videos[i]}
        </div>
      );
    }
    const ArrowLeft = (
      <IWithTooltip
        className="chevron circle left comicsCellTitle large link"
        title={"Précédent"}
      />
    );
    const ArrowRight = (
      <IWithTooltip
        className="chevron circle right comicsCellTitle large link"
        title={"Suivant"}
      />
    );
  //  const { selected } = this.state;
    
    /*
    selectedKey={selected}
    onSelect={this.onSelect}
    hideSingleArrow={true}
    alignCenter={false}
    scrollBy={1}
    inertiaScrolling={true}
    */
    return (
      <div className="ytPlaylistPreview" key={"yps" + Math.random()}>
        <div
          key={"ypsd" + Math.random()}
          className="ytPlaylistPreviewScroller"
          id="ytPlaylistPreviewScroller"
        >
          <div className="menu-bar" key={"ypsdd" + Math.random()}>
            <ScrollMenu
              LeftArrow={ArrowLeft}
              RightArrow={ArrowRight}
            >{menuItems}</ScrollMenu>
            {this.player}
            {this.props.closeButton}
          </div>
        </div>
      </div>
    );
  }
}
