

export const isStableCoin = (symbol: string) => {
  if (symbol == null || symbol === undefined) {
    return false;
  } else if (symbol.toUpperCase() === 'USDT' || symbol.toUpperCase() === 'USDC') {
    return true;
  }
  else {
    return false;
  }
}

export const generateTtcId = () => {
  return '' + (new Date()).getTime() + '-' + Math.random();
}
export const usedBagPerc = (row: CryptoTableRow) => {
  let total: number = 0;
  if (row) {
    let targets = row.targets;
    if (targets) {
      for (let i = 0; i < targets.length; i++) {
        let target = targets[i];
        let bagPerc: number = (target.bagPerc === undefined || target.bagPerc == null) ? 0 : Number(target.bagPerc);
        total = total + bagPerc;
      }
    }
  }
  return total;
}

export const mapCoinGeckoId = (id: string | null) => {
  if (id == null) {
    return null;
  } else if (id === "0x0.ai") {
    return "0x0-ai-ai-smart-contract";
  } else {
    return id;
  }
}



export interface CryptoTableRow {
  ttcId?: string,
  name?: string,
  site: string,
  image?: any,
  symbol: string,
  bag: number,
  bagPrice?: number | null,
  unitPrice?: number,
  invested?: number;
  price_change_percentage_24h?: number,
  targets: TargetData[],
  comment?: string | null,
  coingeckoId?: string | null,
  history?: string,
  market_cap?: number,
  market_cap_rank?: number,
  ath?: number,
  loaded: boolean,
  automaticUpdate:boolean
}

export interface TargetData {
  rate: number,
  price?: number,
  sold?: boolean | null,
  bag?: number,
  bagPerc?: number,
  perc?: number | null,
  status?: StatusEnum,
  graphProcessed?: boolean,
  notificationHigh0?: number,
  notificationHigh1?: number,
  notificationHigh2?: number
}


export interface CryptoTableRows {
  items: CryptoTableRow[]
}

export interface Porttcfolio {
  items: CryptoTableRow[];
  investment: number;
  impotsPerc: number;
  exchanges: Exchange[];
  currentTotal: number;
}
export interface Exchange{
  exchange: string;
  key: string;
  secret: string;
  passphrase: string;
  exclusions:string;
}

export interface TotalTargetData {
  label: string;
  totalTarget: number;
  impots: number;
  gain: number;
  totalSold: number;
}


export enum StatusEnum {
  Init = 0,
  Order = 1,
  Sold = 2
}

export const colors2 = [
  'rgb(234,27,0)',
  'rgb(238,173,0)'
]

export const colors = [

  '#8dd3c7',

  '#ffffb3',

  '#bebada',

  '#fb8072',

  '#80b1d3',

  '#fdb462',

  '#b3de69',

  '#fccde5',

  '#d9d9d9',

  '#bc80bd',

  '#ccebc5',

  '#ffed6f',
  'orange'
];