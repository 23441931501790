import "./Activity.scss";
import "react-vertical-timeline-component/style.min.css";
import React from "react";
import ActivityUtils from "./ActivityUtils";
import Carousel from "react-material-ui-carousel";

export interface IProps {
  geekiLogin: string;
  activities: any[];
  geekies: any[];
  isInProduction:boolean;
}

export interface IState {}

class ActivityTimeLineElementScroller extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      activities: []
    };
  }

  render() {
    let menuItems = [];
    if (this.props.activities && this.props.activities.length > 0) {
      for (let i = 0; i < this.props.activities.length; i++) {
        let activityList = ActivityUtils.render(this.props.activities[i],
          this.props.activities[i].roles[0], this.props.geekiLogin,this.props.geekies,this.props.isInProduction);
        for (let j = 0; j < activityList.length; j++) {
          menuItems.push(activityList[j]);
        }
      }
      return (    <Carousel
      className="withScrollerCarousel"
        key={"gcc" + Math.random()}  
        next={ () => {/* Do stuff */} }
        prev={ () => {/* Do other stuff */} }
        swipe={true}
        autoPlay={true}
        stopAutoPlayOnHover={true}
        animation="fade"
      >
        {menuItems}
      </Carousel>);
    }
    return "";
  }
}

export default ActivityTimeLineElementScroller;
