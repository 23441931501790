import React from "react";
import Twitch from "./Twitch";
import ModernComicsCell from "../comics/ModernComicsCell";

interface IProps {
  channel: string;
  parent: any;
}

interface IState {
  expanded: boolean;
}

export default class TwitchComicsCell extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }
  render() {
    const content = (
      <div className="twitchContent" key={"tcc" + Math.random()}>
        <span
          className="ytPlaylistPreviewScrollerClose"
          onClick={this.showHidePlaylist}
        >
          Fermer
        </span>
        <div className="twitchContentInner" key={"tccd" + Math.random()}>
          <Twitch channel={this.props.channel} />
        </div>
      </div>
    );
    const css = "sign comicsCellTitle large icon";
    const footerLink = "https://www.twitch.tv/" + this.props.channel;
    return (
      <ModernComicsCell
        cssPanel={"authorTwitch" + (this.state.expanded ? " expanded" : "")}
        cssTitle={css}
        className="width100Perc-fullPage"
        title={"Chaine Twitch de @" + this.props.channel}
        footer="+ d'info sur Twitch"
        footerLink={footerLink}
        content={content}
      />
    );
  }

  showHidePlaylist = () => {
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
    this.props.parent.current.resetIndex();
  }
}
