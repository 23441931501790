import { monthTo3Letters } from "../../shared/util/date-utils";
import "./Convention.scss";

import React from "react";


interface IProps {
  from: string;
  to?: string;
}

export default class ConventionDate extends React.Component<IProps> {

    render() {
        let dateFrom=this.props.from.split("-");
        let yearFrom=dateFrom[0];
        let yearTo=null;
        let dayFrom=dateFrom[2];
        let dayTo=null;
        let monthFrom=dateFrom[1];
        let monthTo=null;
        if(this.props.to){
            let dateTo=this.props.to.split("-");
            if(dateTo[0]!==yearFrom){
                yearTo=dateTo[0];
            }
            if(dateTo[0]!==yearFrom || dateTo[1]!==monthFrom){
                monthTo=dateTo[1];
            }
            if(dateTo[0]!==yearFrom || dateTo[1]!==monthFrom || dateTo[2]!==dayFrom){
                dayTo=dateTo[2];
            }
        }

        return (<span className="convention-date">
            <div className="convention-date-year"><span>{yearFrom}</span>{yearTo && <span> - {yearTo}</span>}</div>
            <div className="convention-date-day"><span>{dayFrom}</span>{dayTo &&<span> - {dayTo}</span>}</div>
            <div className="convention-date-month"><span>{monthTo3Letters(monthFrom)}</span>{monthTo && <span> - {monthTo3Letters(monthTo)}</span>}</div>
        </span>);
    }


}