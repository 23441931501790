import "./Social.scss";
import React from "react";
import SocialItem from "./SocialItem";
import ModernComicsCell from "../comics/ModernComicsCell";

interface IProps {
  wiki: string;
  now: string;
  th: string;
  fb: string;
  ins: string;
  tw: string;
  pi: string;
  li?: string;
  di: string;
  yo: string;
  dai: string;
  ti: string;
  tito: string;
  sc: string;
  pc: string;
  im?: string;
  entityType: string;
  entityLogin: string;
  entityUrl: string;
  isInProduction: boolean;
}

class Links extends React.Component<IProps> {
  render() {
    let items = [];
    this.props.entityUrl && items.push(
      <SocialItem
        key="socialURL"
        linkType="URL"
        param={this.props.entityUrl}
        svg="url.svg"
        css="url"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );

    this.props.wiki && items.push(
      <SocialItem
        key="socialWIKI"
        name="Wiki"
        linkType="WIKI"
        param={this.props.wiki}
        svg="wikipedia.svg"
        css="wiki"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.now && items.push(
      <SocialItem
        key="socialNOW"
        name="Nolife Wiki"
        linkType="NOW"
        param={this.props.now}
        svg="wikipedia.svg"
        css="wiki"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.fb && items.push(
      <SocialItem
        key="socialFB"
        name="Facebook"
        linkType="FB"
        param={this.props.fb}
        svg="facebook.svg"
        css="facebook"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.tw && items.push(
      <SocialItem
        key="socialTWI"
        name="X ex Twitter"
        linkType="TWI"
        param={this.props.tw}
        svg="twitterx.svg"
        css="twitter"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.ins && items.push(
      <SocialItem
        key="socialINS"
        name="Instagram"
        linkType="INS"
        param={this.props.ins}
        svg="instagram.svg"
        css="instagram"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.th && items.push(
      <SocialItem
        key="socialTWC"
        name="Twitch"
        linkType="TWC"
        param={this.props.th}
        svg="twitch.svg"
        css="twitch"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.pi && items.push(
      <SocialItem
        key="socialPI"
        name="Pinterest"
        linkType="PI"
        param={this.props.pi}
        svg="pinterest.svg"
        css="pinterest"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.di && items.push(
      <SocialItem
        key="socialDI"
        name="Discord"
        linkType="DI"
        param={this.props.di}
        svg="discord.svg"
        css="discord"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.li && items.push(
      <SocialItem
        key="socialLI"
        name="LinkedIn"
        linkType="LI"
        param={this.props.li}
        svg="linkedin.svg"
        css="linkedin"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.im && items.push(
      <SocialItem
        key="socialIM"
        name="Imdb"
        linkType="IM"
        param={this.props.im}
        svg="imdb.svg"
        css="imdb"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.yo && items.push(
      <SocialItem
        key="socialYO"
        name="Youtube"
        linkType="YO"
        param={this.props.yo}
        svg="youtube.svg"
        css="youtube"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.dai && items.push(
      <SocialItem
        key="socialDAI"
        name="DailyMotion"
        linkType="DAI"
        param={this.props.dai}
        svg="dailymotion.svg"
        css="dailymotion"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.tito && items.push(
      <SocialItem
        key="socialTITO"
        name="TikTok"
        linkType="TITO"
        param={this.props.tito}
        svg="tiktok.svg"
        css="tiktok"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.ti && items.push(
      <SocialItem
        key="socialTI"
        name="Tipeee"
        linkType="TI"
        param={this.props.ti}
        svg="tipeee.svg"
        css="tipee"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.sc && items.push(
      <SocialItem
        key="socialSC"
        name="SoundCloud"
        linkType="SC"
        param={this.props.sc}
        svg="soundcloud.svg"
        css="soundcloud"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );
    this.props.pc && items.push(
      <SocialItem
        key="socialPC"
        name="PodCloud"
        linkType="PC"
        param={this.props.pc}
        svg="podcloud.svg"
        css="podcloud"
        entityType={this.props.entityType}
        entityLogin={this.props.entityLogin}
        isInProduction={this.props.isInProduction}
      />
    );

    return (
      <ModernComicsCell
        key="linksCC"
        inline="true"
        fullWidth={true}
        className="width100Perc stretchparent"
        cssPanel="overflowHidden links"
        cssTitle="comicsCellTitle sign large icon"
        content={items}
      />
    );
  }
}

export default Links;
