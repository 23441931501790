export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: "",
  login: "",
  firstName: "",
  lastName: "",
  email: "",
  activated: false,
  langKey: "",
  authorities: [],
  createdBy: "",
  createdDate: null,
  lastModifiedBy: "",
  lastModifiedDate: null,
  password: ""
};
