
import "./GeekiLayout.scss";
import React from "react";
import CollapsableSideBar from "../../custom/widget/sidebar/CollapsableSideBar";
import Footer from "../../custom/widget/footer/Footer";
import GeekiInput from "../../custom/admin/geeki/GeekiInput";
import { IRootState } from "../../reducers";
import { WithRouterProps, withRouter } from "./withRouter";
import { connect } from "react-redux";

export interface IProps extends StateProps, DispatchProps {
    title: string;
    component: any;
    isInProduction: any;
}
type Props = IProps & WithRouterProps<IProps>;

export interface IState {
    expanded: boolean;
}

class GeekiLayout extends React.Component<Props, IState> {

    sidebarRef;

    constructor(props) {
        super(props);
        this.state = {
            expanded: true
        };
        this.sidebarRef = React.createRef();
    }
    onscrollContent = (e) => {
        this.sidebarRef.current.onscrollContent(e);
    }


    searchGeeki(e) {
        window.location.replace('/#/geeki/' + e.login);
    }

    render() {

        return (<div className="geekiLayout-container" id="geekiLayout-container">
            <CollapsableSideBar ref={this.sidebarRef} isInProduction={this.props.isInProduction}/>
            <div className="geekiLayout-content" onMouseMove={this.onscrollContent}>
                <div className="geekiLayout-content-inner">
                    <div className="geekiLayout-header">
                        <span className="geekiLayout-header-title">{this.props.title}</span>
                        <span className="geekiLayout-header-search">
                            <GeekiInput
                                geekies={this.props.geekiList}
                                itemLoadKey="login"
                                itemSaveKey="login"
                                callbackFunction={(e) => this.searchGeeki(e)}
                                disabled={false}
                                defaultValue=""
                                value=""
                                placeholder="Sélectionnez un Geeki"
                            /></span></div>
                    <div className="geekiLayout-component">{this.props.component}</div>
                    <Footer  isInProduction={this.props.isInProduction}/>
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = ({ geeki,applicationProfile }: IRootState) => ({
    geekiList: geeki.entities,
    isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GeekiLayout));
