import { routeUrl } from "../../../shared/util/url-utils";

export default class DbParseUtils {

  render(){
    //workaround, else: instance.render is not a function
  }

  static parse(data: string) {
    const parts = data.split("</table>");
    if (parts.length === 1) {
      return DbParseUtils.parseNolifeWikiRemoveLinks(parts[0]);
    } else if (parts[0].indexOf("<table ") > 0) {
      return DbParseUtils.parseNolifeWikiRemoveLinks(
        parts[0].split("<table ")[0] + parts[1]
      );
    } else {
      return DbParseUtils.parseNolifeWikiRemoveLinks(parts[1]);
    }
  }
  static parseNolifeWikiRemoveLinks(data) {
    if (data.indexOf("</a>") > 0) {
      return DbParseUtils.parseNolifeWikiRemoveLinks(
        data.replace(/<a\b[^>]*>/i, "").replace(/<\/a>/i, "")
      );
    } else {
      return DbParseUtils.parseThumb(data.trim());
    }
  }

  static parseThumb(data) {
    if (data.indexOf('<div class="thumb tright">') >= 0) {
      return "<p>" + this.parseNolifeWikiRemoveH2(data.split("<p>")[1]);
    } else {
      return DbParseUtils.parseNolifeWikiRemoveH2(data);
    }
  }

  static parseNolifeWikiRemoveH2(data) {
    if (data.indexOf("<h2>") > 0) {
      return data
        .split("<h2>")[0]
        .replace("☒", "")
        .split(" Lien Externe ---------")[0];
    } else {
      return data.replace("☒", "").split(" Lien Externe ---------")[0];
    }
  }

  static parseDescription(desc, isTwitter) {
    const newDiv = document.createElement('div');
    if(desc!=null){
    const paragraphs = desc.split("<p>");
    const content = [];

    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = this.parseParagraph(paragraphs[i], i, isTwitter);
      if (paragraph !== "") {
        content.push(paragraph);
        newDiv.appendChild(paragraph as any);
      }
    }
    }
    return newDiv;
  }
  static parseParagraph(p, idx, isTwitter) {
    const paragraph = p.replace("</p>", "");
    const content = [];
    if (paragraph.indexOf("<Link") > -1) {
      const parts = paragraph.split("<Link");
      this.parseLinks(parts, content, isTwitter);
    } else {
      content.push(paragraph);
    }
    if (content.length === 0) {
      return "";
    } else {
      const newP = document.createElement('p'); 
      let brs=this.parseBR(content);
      for(let i=0;i<brs.length;i++){        
        newP.appendChild(brs[i]);
      }
      return newP;
    }
  }
  static parseLinks(parts, content, isTwitter) {
    if (parts.length > 0) {
      if (parts[0].indexOf("</Link>") > -1) {
        this.processLink(parts[0], content, isTwitter);
        parts.splice(0, 1);
        this.parseLinks(parts, content, isTwitter);
      } else {
        content.push(parts[0]);
        parts.splice(0, 1);
        DbParseUtils.parseLinks(parts, content, isTwitter);
      }
    }
  }
  static parseBR(content) {
    const result = [];
    let splitted;
    for (let i = 0; i < content.length; i++) {
      if (typeof content[i] === "string") {
        splitted = content[i].split("<br/>");
        let myVar=document.createElement('span');
        myVar.innerHTML=splitted[0];
        result.push(myVar);
        for (let j = 1; j < splitted.length; j++) {
          result.push(document.createElement('br'));
          myVar=document.createElement('span');
        myVar.innerHTML=splitted[j];
        result.push(myVar);
     //     result.push(<>{splitted[j]}</>);
        }
      }else if (typeof content[i] === "object") {
        result.push(content[i]);
      } else {
        let myVar=document.createElement('span');
      myVar.innerHTML=content[i];
      result.push(myVar);
    //    result.push(<>{content[i]}</>);
      }
    }

    return result;
  }

  static processLink(str, content, isTwitter) {
    const strArray = str.split("</Link>");
    const data = strArray[0].split(">");
    const label = data[1];
    if (isTwitter) {
      const stTW = data[0].split('twitter="');
      if (stTW.length > 1) {
        const twitter = stTW[1].split('"')[0];
        content.push(<span>@{twitter}</span>);
      } else {
        content.push(<span>{label}</span>);
      }
    } else {
      const stTO = data[0].split('to="');
      if (stTO.length > 1) {
        const href = routeUrl(stTO[1].split('"')[0]);
     //   let link=document.createElement('link');
     let link=document.createElement('a');
        link.className="link";
      //  link.setAttribute("to",href);
        link.setAttribute("href",href);
        link.setAttribute("key","lnk" +
        Math.floor(Math.random() * 999999) +
        "-" +
        Math.floor(Math.random() * 999999));
        link.innerHTML=label;
        content.push(link);
      } else {
        content.push(<span>{label}</span>);
      }
    }

    if (strArray.length > 1 && strArray[1] !== "") {
      content.push(strArray[1]);
    }
  }
}
