import React from "react";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { IRootState } from "../../../reducers";
import { connect } from "react-redux";
import NewsWidget from "./NewsWidget";

import GeekiSkeleton from "../image/GeekiSkeleton";
import LazyLoad from 'react-lazyload';
import { IGeeki } from "../../../shared/model/geeki.model";
import { getEntities } from "../../../reducers/geeki.reducer";

export interface IProps extends StateProps, DispatchProps{
  geeki: IGeeki;
  isTwitter: boolean;
  isInProduction: boolean;
}
type Props = IProps & WithRouterProps<IProps>;

export interface IState {
  geekies: any[];
}

class NewsComicsCell extends React.Component<Props,IState> {

  constructor(props) {
    super(props);
    this.state = {
      geekies: []
    };
  }

  componentDidMount() {
    this.initGeekies();
  }
  initGeekies = async () => {
    let entityList: any = this.props.geekiList;
    if (entityList == null || entityList.length === 0) {
      entityList = await (this.props as any).getEntities();
      entityList = entityList.value.data;
    }
      this.setState({
        geekies: entityList
      });
}

  render() {
    if (!this.props.geeki.news || this.props.geeki.news.length === 0) {
      return <GeekiSkeleton width="100%" height={300} />;
    }
    var result = [];

    for (var index = 0; index < this.props.geeki.news.length; index++) {
      result.push(
        <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL" + index}>
          <NewsWidget
            key={"n2ccLLNW" + index}
            news={this.props.geeki.news[index]}
            index={index}
            isTwitter={(this.props as any).isTwitter}
            geekies={this.state.geekies}
            isInProduction={this.props.isInProduction}
          />
        </LazyLoad>
      );
    }
    return result;
  }
}

const mapStateToProps = ({ geeki, applicationProfile }: IRootState) => ({
  geekiList: geeki.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsComicsCell));
