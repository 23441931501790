import "./Convention.scss";
import React from "react";
import ConventionLight from "./ConventionLight";
import LazyLoad from 'react-lazyload';
import { includesUpperCaseWithoutAccents } from "../../shared/util/string-utils";

interface IProps {
    geekies: any[];
    isInProduction: boolean;
}
interface IState {
    conventionList: any;
}

export default class ConventionsList extends React.Component<IProps,IState> {

    constructor(props) {
        super(props);
        this.state = {
            conventionList: null
        };
      }

      updateConventionList(conventionList){
        this.setState({conventionList: conventionList});
      }

      filterConventions(conventionList,dateFrom,dateTo,name,city,postalCode){
         let filteredConventionList=[];
         for (let i=0;i<conventionList.length;i++){
            let convention=conventionList[i];
            let isValid=true;
            if(dateFrom !=null && dateFrom!== undefined && dateFrom!==''){
                let date=new Date(convention.startDate);
                if(date <dateFrom){
                    isValid=false;
                }
            }  
            if(dateTo !=null && dateTo!== undefined && dateTo!==''){
                let date=new Date(convention.endDate);
                if(date > dateTo){
                    isValid=false;
                }
            }  
            if(name !=null && name!== undefined && name!==''){
                if(!includesUpperCaseWithoutAccents(convention.name,name)){
                    isValid=false;
                }
            }
            if(city !=null && city!== undefined && city!==''){
                if(!includesUpperCaseWithoutAccents(convention.city,city)){
                    isValid=false;
                }
            }
            if(postalCode !=null && postalCode!== undefined && postalCode!==''){
                if(!includesUpperCaseWithoutAccents(convention.postalCode,postalCode)){
                    isValid=false;
                }
            }
            
            if(isValid){
                filteredConventionList.push(convention);
            }
          }
          this.setState({conventionList: filteredConventionList});
      }


    render() {
        return ( 
            <div className="width100Perc">
            <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
            {this.renderConventions()}
           </LazyLoad>
           </div>);
    }

    renderConventions(){
        if(this.state.conventionList){
          let content = [];
          for (let i=0;i<this.state.conventionList.length;i++){
            let convention=this.state.conventionList[i];
    
            content.push(<ConventionLight convention={convention} geekies={this.props.geekies} isInProduction={this.props.isInProduction}/>);
          }
    
          return content;
        }else{
          return "Chargement...";
        }
      }
    
}