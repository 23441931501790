import "../../widget/comics/Comics.scss";
import "../../author/Author.scss";
import "../../geeki/Geeki.scss";

import "react-image-gallery/styles/css/image-gallery.css";

import React from "react";
import { geekiLabelShort } from "../../config/GeekiUtil";
import IWithTooltip from "../icon/IWithTooltip";
import ModernComicsCell from "../comics/ModernComicsCell";

import ImageGallery from "react-image-gallery";
import GeekiSkeleton from "./GeekiSkeleton";
import { imgUrl } from "../../../shared/util/url-utils";

interface IProps {
  geekiEntity: any;
  imgCount: number;
  isInProduction: boolean;
}

interface IState {
  loaded: boolean;
}

class GeekiPhoto extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  render() {
    if (!this.props.geekiEntity) {
      return (
        <GeekiSkeleton width={300} height={400} />
      );
    }
    const imgCount = this.props.imgCount;
    if (imgCount === 0) {
      return "";
    }
    const login = this.props.geekiEntity.login;
    const images = [];
    const authorTitle = geekiLabelShort(this.props.geekiEntity);
    for (var i = 1; i <= imgCount; i++) {
      images.push({
        original: imgUrl("/api/img/geeki/" + login + "/" + i,this.props.isInProduction),
        thumbnail: imgUrl("/api/img/geeki/" + login + "/" + i,this.props.isInProduction),
        originalAlt: authorTitle,
        thumbnailAlt: authorTitle,
        originalTitle: authorTitle,
        thumbnailTitle: authorTitle,
        originalClass: "geekiImg",
        thumbnailClass: "geekiImg",
        thumbnailWidth: 60
      });
    }
    let photoFooter;
    let bottomLeft;
    let topright;
    if (this.props.geekiEntity.validatedDate) {
      bottomLeft = (
        <IWithTooltip
          className="certified large thumbs up outline"
          title={
            "Validé par l'auteur le " + this.props.geekiEntity.validatedDate
          }
        />
      );
    }
    if(images.length===1){
        return <img alt="" src={images[0].original} className="geekiImg geekiPhotoInner"/>;
    }

   let content=images.length>1?<ImageGallery items={images} showPlayButton={false} />:images.length===1?
   <img alt="" src={images[0].original} className="geekiImg geekiPhotoInner"/>:'';
   
    return (      
      <ModernComicsCell
        inline="true"
        className="geekiPhoto "
        bottomLeftClassName="tooltipvalidated"
        content={content}
        footer={photoFooter}
        topright={topright}
        bottomLeft={bottomLeft}
      />
    );
  }
}
export default GeekiPhoto;
