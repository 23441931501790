import "../CryptoTable.css";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { CryptoTableRow, isStableCoin } from "../data/CryptoDataType";
import FieldEditor from "./FieldEditor";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import CryptoRowTarget from "./CryptoRowTarget";
import CryptoGauge from "./CryptoGauge";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CryptoRowTargetScroller from "./CryptoRowTargetScroller";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { IconButton, Tooltip } from "@mui/material";
import { AreaChart, Area } from 'recharts';
import CryptoRowTargetSummary from "./CryptoRowTargetSummary";
import { porttcfolio, toFixed } from "../data/CryptoData";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BackHandIcon from '@mui/icons-material/BackHand';
import { useState } from "react";
import { updatePortfolioInBrowserCache } from "../data/CryptoCache";

export const calculPrice = (bag: number | undefined, rate: number | null) => {
  if (rate == null) {
    return 0;
  }
  if (bag !== undefined) {
    let result = bag * rate;
    if (Number.isNaN(result) || result === Infinity) {
      return 0;
    } else {
      return result;
    }
  } else {
    return 0;
  }
}

const CryptoRow = (props: any) => {
  const [updated, setUpdated] = useState(true);

  let item = props.item;
  let groupedRowsCount=props.groupedRowsCount;

  const onRemove = () => {
    props.onRemove(props.item.ttcId);
  }
  const onRemoveTarget = (targetPos: number) => {
    props.onRemoveTarget(props.item.ttcId, targetPos);
  }

  const partialRefresh = () => {
    props.partialRefresh(true);
  }

  let row: CryptoTableRow = item;
  if (isStableCoin(row.symbol)) {
    row.targets = [];
  }


  let targets = [];
  for (let i = 0; i < row.targets.length; i++) {
    targets.push(<CryptoRowTarget key={"key02" + item.name + item.site + Math.random()}
      row={row} targetPos={i} item={item} onAdd={props.onAddTarget}
      rows={props.rows}
      viewMode={props.viewMode}
      onRemoveTarget={onRemoveTarget}
      partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
    />);
  }
  targets.push(<CryptoRowTarget key={"key02" + item.name + item.site + Math.random()}
    isAddTarget={true}
    className="addTarget"
    row={row} targetPos={row.targets.length + 1} item={item} onAdd={props.onAddTarget}
    rows={props.rows}
    viewMode={props.viewMode}
    onRemoveTarget={onRemoveTarget}
    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
  />);
  let coeff = null;
  if (row.invested && row.bagPrice) {
    coeff = row.bagPrice / row.invested;
  }



  let history: number[] = [];
  let history2 = [];
  let strHistory = row.history ? row.history.split('_') : [];
  for (let i = 0; i < strHistory.length; i++) {
    history.push(Number(strHistory[i]));
    history2.push({ value: Number(strHistory[i]) });
  }
  let historyColor = "green";
  if (history.length > 1) {
    if (history[0] > history[history.length - 1]) {
      historyColor = "red";
    }
  }

  const handleOpen = (id: any) => {
    if(props.buyPopupRef.current!=null){
    (props.buyPopupRef.current as any).handleOpen(id);
    }
  }

  const switchAutomatic = (row:any) =>{
    console.log("switch automatic");
    row.automaticUpdate=true;
    for(let i=0;i<porttcfolio.items.length;i++){
      let obj=porttcfolio.items[i];
      if(obj.ttcId===row.ttcId){
        obj.automaticUpdate=true;
      }
    }

    updatePortfolioInBrowserCache();
    setUpdated(!updated);
  }
  const switchManual = (row:any) =>{
    row.automaticUpdate=false;
    for(let i=0;i<porttcfolio.items.length;i++){
      let obj=porttcfolio.items[i];
      if(obj.ttcId===row.ttcId){
        obj.automaticUpdate=false;
      }
    }    
    updatePortfolioInBrowserCache();
    setUpdated(!updated);
  }


  let totalAmount=0;
    for (let i = 0; i < row.targets.length; i++) {
        let rate = (row.targets[i].rate == null || row.targets[i].rate === undefined ? null : row.targets[i].rate);
        let targetPrice = calculPrice(row.targets[i].bag, rate);
        totalAmount+=targetPrice;
    }

  return (
    <TableRow
      key={"key07" + item.name + item.site + Math.random()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      className="crypto-row"
    >
      {(!props.viewMode) &&
        <TableCell key={"key13" + item.name + item.site + Math.random()} align="center" className="removeRow firstCol percLeft">

          <Tooltip title="Supprimer ligne">
            <IconButton onClick={onRemove}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      }
      <TableCell key={"key06" + item.name + item.site + Math.random()} component="td" scope="row" className="crypto-cell firstCol">

        {row.image && props.firstRow && <img src={row.image} alt="crypto" />}
        {row.coingeckoId && props.firstRow && <a className="coingeckoId crypto-row-symbol"
          href={"https://www.coingecko.com/en/coins/" + row.coingeckoId} target="_new">{row.symbol} - {row.name} <Tooltip title="Rang(Market Cap)">
            <span>{(row.market_cap_rank == null || row.market_cap_rank === 0) ? '' : '('+row.market_cap_rank+')'}</span></Tooltip></a>}
        {(!row.coingeckoId) && props.firstRow && <span><FieldEditor
          tooltip="Code de la cryptomonnaie"
          className="crypto-row-symbol fixedWidth"
          val={row} prop="symbol"
          rows={props.rows}
          viewMode={props.viewMode}
          onChange={partialRefresh} type="text" key={"key111" + item.name + item.site + Math.random()} />

          <br />{props.viewMode ? row.coingeckoId : " "}</span>}
          {(!props.viewMode) && props.firstRow && <span><FieldEditor
          tooltip="Nom de la cryptomonnaie"
          className="crypto-row-symbol fixedWidth"
          val={row} prop="name"
          rows={props.rows}
          viewMode={props.viewMode}
          onChange={partialRefresh} type="text" key={"key112" + item.name + item.site + Math.random()} />

          <br />{props.viewMode ? row.coingeckoId : " "}</span>}
          
          
        <div className="crypto-row-symbol">
        { row.automaticUpdate && <span><IconButton onClick={() => switchManual(row)}>
        <AutorenewIcon/></IconButton></span>}
        {(!row.automaticUpdate) && <span><IconButton onClick={() => switchAutomatic(row)}>
        <BackHandIcon/></IconButton></span>}
        <FieldEditor
          tooltip="Plateforme"
          className="fixedWidth"
          ttcIf={item.ttcId} val={row} prop="site"
          viewMode={props.viewMode}
          rows={props.rows}
          onChange={partialRefresh} type="text" key={"key11" + item.name + item.site + Math.random()} />
        </div>

        <div style={{ width: "100%" }}>
          <span style={{ width: "75%", display: "inline-block" }}><FieldEditor
            className="fixedWidth"
            tooltip="Quantité de cryptomonnaie"
            val={row} prop="bag" label="bag:"
            rows={props.rows}
            viewMode={props.viewMode}
            onChange={partialRefresh}
            type="number" key={"key10" + item.name + item.site + Math.random()} /></span>
          <span style={{ width: "25%", display: "inline-block" }}> {(props.viewMode) &&
            <div> <Tooltip title="Acheter crypto">
              <IconButton onClick={() => handleOpen(row.symbol)}>
                <ShoppingBasketIcon />
              </IconButton>
            </Tooltip>
            </div>

          }</span>
        </div>


        <div style={{ width: "100%" }}>
          <span style={{width:"20%",display:"inline-block"}}>
          <FieldEditor
            tooltip="Montant investi en USDT"
            className={props.viewMode ? "" : "fixedWidth"}
            val={row} prop="invested"
            rows={props.rows}
            viewMode={props.viewMode}
            onChange={partialRefresh}
            type="currency" key={"key101" + item.name + item.site + Math.random()} />
            </span>
            {props.viewMode && <Tooltip title="Gains"><span style={{width:"20%",display:"inline-block"}}><CryptoGauge viewMode={props.viewMode} key={"key811" + item.name + item.site + Math.random()}
              reverse={true} perc={coeff} twoLines={true}/></span></Tooltip>}
            {props.viewMode && <Tooltip title="Montant actuel du bag"><span style={{width:"20%",display:"inline-block",textAlign:"right"}}>${row.bagPrice && row.bagPrice.toFixed(2)}</span></Tooltip>}

            <Tooltip title="Pourcentage de l'objectif défini"><span  style={{width:"20%",display:"inline-block",textAlign:"right"}}>{row.invested && totalAmount>0 && <span>{'('+toFixed(row.invested*100/totalAmount,0)+'%)'}</span>}</span></Tooltip>
            <Tooltip title="Total montant objectif défini"><span  style={{width:"20%",display:"inline-block",textAlign:"right"}}>${toFixed(totalAmount,0)}</span></Tooltip>
</div>
        <br />
        <FieldEditor
          tooltip="Commentaire"
          val={row} prop="comment"
          viewMode={props.viewMode}
          rows={props.rows}
          onChange={partialRefresh} type="text" key={"key10" + item.name + item.site + Math.random()} />

      </TableCell>
      {props.viewMode && props.firstRow && <TableCell key={"key8" + item.name + item.site + Math.random()} 
        rowSpan={groupedRowsCount}
        align="right" className="percLeft">
          <br/>{(row.price_change_percentage_24h != null && row.price_change_percentage_24h !== undefined) && (typeof row.price_change_percentage_24h === 'number') &&
         <Tooltip title="Evolution sur 24h">{(row.price_change_percentage_24h === 0) ? (
            <span className="crypto-percent">= {row.price_change_percentage_24h.toFixed(2)}%</span>
          ) : (row.price_change_percentage_24h < 0) ? (
            <span className="crypto-percent red"><ArrowDropDownIcon />{row.price_change_percentage_24h.toFixed(2)}%</span>
          ) : (
            <span className="crypto-percent green"><ArrowDropUpIcon />{row.price_change_percentage_24h.toFixed(2)}%</span>
          )
          }
          </Tooltip>}&nbsp;<Tooltip title="Cours actuel"><span>${row.unitPrice && toFixed(row.unitPrice,6)}</span></Tooltip>
          <br/><Tooltip title="Pourcentage de l'ATH"><div>{row.unitPrice && row.ath && row.ath>0 && <span>{'('+toFixed(row.unitPrice*100/row.ath,0)+'%)'}</span>}&nbsp;${row.ath && toFixed(row.ath,6)}</div></Tooltip>
      
        

        {history && (history.length > 0) &&
          <Tooltip title="Evolution sur 14 jours">
            <div>
              <AreaChart data={history2} width={100} height={50}>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={historyColor}
                  fill={historyColor}
                  fillOpacity={0.3}
                />
              </AreaChart>
            </div>
          </Tooltip>
         
        }
        
      </TableCell>
      }
      <TableCell key={"key9" + item.name + item.site + Math.random()} style={{ maxWidth: "800px",height:(props.viewMode?"150px":"250px") }}>
        <table>
          <tbody>
          <TableRow style={{ height:(props.viewMode?"150px":"250px"),width:"100%" }}>
          <CryptoRowTargetSummary row={row} key={"key03" + item.name + item.site + Math.random()} />
          <TableCell colSpan={10}><CryptoRowTargetScroller item={item}
            rows={props.rows}
            viewMode={props.viewMode}
            onRemoveTarget={onRemoveTarget}
            partialRefresh={partialRefresh}
            fullRefresh={props.fullRefresh}
            onAddTarget={props.onAddTarget} />
          </TableCell>
        </TableRow>
        </tbody>
        </table>
      </TableCell>
    </TableRow>
  );
};



export default CryptoRow;
