import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";

import { getEntityByLogin } from "../../../reducers/geeki.reducer";
import { formatText, updateDescription, getDescription } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import GeekiInput from "./GeekiInput";
import { adminLayout, alertResult } from "../util/admin-utils";
import DbParseUtils from "../../widget/util/DbParseOldUtil";
import DescriptionEditor from "./DescriptionEditor";
import AdmLiquibaseIndicator from "../AdmLiquibaseIndicator";
import AdminNotAuthenticated from "../AdminNotAuthenticated";
import { isUserAuthenticated } from "../../../reducers/authentication";

interface IProps extends StateProps, DispatchProps {
  formatText: any;
  updateDescription: any;
  applicationProfile: boolean;
}

interface IState {
  dbVersion: string;
  fileVersion: string;
  isNewVersion: boolean;
}

class AdminGeekiEditDescription extends React.Component<IProps, IState> {
  geekiEntity;
  geekies;
  srcShortDescription: string;
  targetShortDescription: string;
  srcLongDescription: string;
  targetLongDescription: string;

  shortRef;
  longRef;



  constructor(props: any) {
    super(props);
    this.srcShortDescription = '';
    this.targetShortDescription = '';
    this.srcLongDescription = '';
    this.targetLongDescription = '';

    this.shortRef = React.createRef();
    this.longRef = React.createRef();

    this.state = { dbVersion: '', fileVersion: '', isNewVersion: false }

    const hash = window.location.hash; // Obtient la partie d'ancrage de l'URL (#/admin/activity/edit/123)

    if (hash.endsWith("description")) {
    }else{   
      
      const parts = hash.split('/'); // Divise la partie d'ancrage en segments
      const value = parts[parts.length - 1]; // Récupère la dernière partie de la division
      this.loadEntity(value);

    }
  }

  loadEntity = async(login) =>{
    this.geekiEntity = (await (this.props as any).getEntityByLogin(login)).value.data;
    this.geekies = (await (this.props as any).getAdminActivityEntities()).value.data;
    this.handleLoadDesc();
  }

  setIsNewVersion = (isNew: boolean) => {
    this.setState({ isNewVersion: isNew });
  }

  updateInFile = async () => {
    const result = await this.props.updateDescription(this.geekiEntity.login,
      this.targetShortDescription,
      this.targetLongDescription);
    alertResult(result);
  }


  formatSrcDescription = async () => {
    const result = await this.props.formatText(this.srcShortDescription, this.srcLongDescription);
    const cleanShortResult = result.value.data.shortDescription;
    const cleanLongResult = result.value.data.longDescription;
    this.targetShortDescription = cleanShortResult;
    this.targetLongDescription = cleanLongResult;
    const parsedShortResult = DbParseUtils.parseDescription(cleanShortResult, false);
    const parsedLongResult = DbParseUtils.parseDescription(cleanLongResult, false);

    this.shortRef.current.setText(cleanShortResult);
    this.longRef.current.setText(cleanLongResult);
    this.shortRef.current.setHtml(parsedShortResult);
    this.longRef.current.setHtml(parsedLongResult);
  };

  formatTargetDescription = async () => {


    const result = await this.props.formatText(this.removeLinkTags(this.targetShortDescription), this.removeLinkTags(this.targetLongDescription));
    const cleanShortResult = result.value.data.shortDescription;
    const cleanLongResult = result.value.data.longDescription;
    this.targetShortDescription = cleanShortResult;
    this.targetLongDescription = cleanLongResult;
    const parsedShortResult = DbParseUtils.parseDescription(cleanShortResult, false);
    const parsedLongResult = DbParseUtils.parseDescription(cleanLongResult, false);

    this.shortRef.current.setText(cleanShortResult);
    this.longRef.current.setText(cleanLongResult);
    this.shortRef.current.setHtml(parsedShortResult);
    this.longRef.current.setHtml(parsedLongResult);
  };

  removeLinkTags = (text: string) => {
    const regex = /<Link[^>]*>(.*?)<\/Link>/gi;
    if (text != null && text !== undefined) {
      return text.replace(regex, '$1');
    } else {
      return "";
    }
  }

  transformFromTarget = (event: any) => {
    this.formatTargetDescription();
  }

  handleLoadDesc = async () => {
    const desc = await (this.props as any).getDescription(this.geekiEntity.login);
    const cleanShortResult = this.removeLinkTags(desc.value.data.shortDescription);
    const cleanLongResult = this.removeLinkTags(desc.value.data.longDescription);
    this.srcShortDescription = cleanShortResult;
    this.srcLongDescription = cleanLongResult;
    this.formatSrcDescription();
  }

  textShortTargetChanged = (event: any) => {
    this.targetShortDescription = event.target.value;
  }
  textLongTargetChanged = (event: any) => {
    this.targetLongDescription = event.target.value;
  }

  callbackFunctionGetGeeki = obj => {
    this.geekiEntity = obj;
    window.location.replace('/#/admin/geeki/edit/description/' + this.geekiEntity.login);
    this.handleLoadDesc();
  };

  render() {
    if (!isUserAuthenticated()) {
      return <AdminNotAuthenticated/>;
    } else {
    return adminLayout("Edition de la description d'un Geeki", <>
      <Row className="justify-content-center width100Perc">
        <Col md="12">
          <Row className="justify-content-center width100Perc">
            <Col md="4">
              <GeekiInput
                id="ng-geeki_id1_search"
                itemLoadKey="id"
                itemSaveKey="id"
                callbackFunction={this.callbackFunctionGetGeeki}
                disabled={false}
                value=""
                defaultValue=""
                placeholder="Sélectionnez un Geeki"
                geekies={this.geekies}
              />
            </Col>
            <Col md="4">
              <Button onClick={this.transformFromTarget}>Reformater</Button>
            </Col>
            <Col md="4">
              <Row className="justify-content-center">
                <Col md="9"><AdmLiquibaseIndicator fctSetNewVersion={this.setIsNewVersion} /></Col>
                <Col md="3">
                  <Button onClick={this.updateInFile}
                    className="geekiButton"
                    disabled={!this.state.isNewVersion}>Mettre à jour fichier</Button></Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center"
            style={{ minHeight: "100px" }}>
            <Col md="12">
              <DescriptionEditor
                minRows={3}
                ref={this.shortRef}
                onChange={this.textShortTargetChanged}
                maxRows={30}
              />
            </Col>
          </Row>
          <Row className="justify-content-center"
            style={{ minHeight: "500px" }}>
            <Col md="12">
              <DescriptionEditor
                minRows={15}
                ref={this.longRef}
                onChange={this.textLongTargetChanged}
                maxRows={30}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>,this.props.applicationProfile)
      };      
  }
}

const mapStateToProps = ({ authentication,applicationProfile }: IRootState) => ({
  account: authentication.account,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntityByLogin,
  formatText,
  updateDescription,
  getDescription
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminGeekiEditDescription);
