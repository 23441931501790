import 'semantic-ui-css/semantic.min.css'
import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import DbParseUtils2Mappings from '../util/DbParseUtils2Mappings';

export interface IProps {
  value: string;
  sex?: string;
  callbackFunction:any;
}
export interface IState {
  value: string[];
}

export default class SkillsEditor extends Component<IProps, IState> {

  dropdownRef;

  constructor(props: any) {
    super(props);
    this.dropdownRef = React.createRef();
    
    
    if(this.props.value){
      this.state={value: this.props.value.split('|')};
    }else{
      this.state={value:[]};
    }
  }

  getValuesAsString = (value) => {
    let result='';
    for(const val of value){
      if(result !==''){
        result=result+'|';
      }
      result=result+val;
    }
    return result;
  }


  handleDropdownChange = (event, { value }) => {
    this.props.callbackFunction(this.getValuesAsString(value));
    this.setState({value: value});
  };

  render(){

    const options = [];

      for (const [key, value] of DbParseUtils2Mappings.skills) {
        options.push({key:key,value:key,text:DbParseUtils2Mappings.translateSkill(key,this.props.sex)});
      } 

    return (<Dropdown
    ref={this.dropdownRef}
        placeholder='State'
        fluid
        multiple
        value={this.state.value}
        search
        selection
        options={options}
        onChange={this.handleDropdownChange}
      />);
  }
}
