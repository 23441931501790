import "./GeekiInput.scss";
import 'semantic-ui-css/semantic.min.css'

import _ from "lodash";
import { Component } from "react";
import { Search, Label } from "semantic-ui-react";
import PropTypes from "prop-types";

interface IProps  {
  callbackFunction: any;
  value: string;
  defaultValue: string;
  geekies: any;
  itemLoadKey: string; //id or login
  itemSaveKey: string; //id or login
  disabled?: boolean;
  placeholder: string;
  id?: string;
  className ?: string;
  name?: string;
}
interface IState {
  isLoading: boolean;
  results: any[];
  value: string;
  valueId: string;
}

const resultRenderer = ({ title }) => <Label content={title} />;

resultRenderer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default class GeekiInput extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state={isLoading: true,
      results: [],
      value: '',
      valueId: ''};   
  }

  componentDidMount() {
    this.initData(); 
  }

  initData = async () => {

    if (this.props.value) {
      let found = false;
      if(this.props.geekies){
      for (let i = 0; i < this.props.geekies.length; i++) {
        let geeki=this.props.geekies[i];
        if(geeki.entity){
          geeki=geeki.entity;
        }
        if (
          this.props.itemLoadKey === "login" &&
          geeki.login === this.props.value
        ) {
          found = true;
          this.setState({
            isLoading: false,
            results: this.initResult(geeki),
            value: geeki.title,
            valueId: geeki.id.toString()
          });
          break;
        } else if (
          this.props.itemLoadKey === "id" &&
          geeki.id.toString() === this.props.value
        ) {
          found = true;
          this.setState({
            isLoading: false,
            results: this.initResult(geeki),
            value: geeki.title,
            valueId: geeki.id.toString()
          });
          break;
        }
      }
    }
      if (!found) {
        this.setState({
          isLoading: false,
          results: [],
          value: "",
          valueId: ""
        });
      }
    } else {
      this.setState({
        isLoading: false,
        results: [],
        value: this.props.defaultValue,
        valueId: ""
      });
    }
  }

  closeM = () => {
    this.setState({ isLoading: false, results: [], value: "", valueId: "" });
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title, valueId: result.id });
    this.props.callbackFunction(result);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });
    
    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: "",
          valueId: ""
        });
      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.entity?result.entity.title:result.title);
      
      this.setState({
        isLoading: false,
        results: this.toResult(_.filter(this.props.geekies, isMatch))
      });
    }, 300);
  };

  toResult(src) {
    let target = [];
    for (let i = 0; i < src.length; i++) {
      let obj = src[i];
      if(obj.entity){obj=obj.entity}
      target.push({
        id: obj.id,
        key: obj.id,
        title: obj.title,
        login: obj.login
      });
    }
    return target;
  }

  initResult(geeki) {
    let target = [];
    target.push({
      id: geeki.id,
      key: geeki.id,
      title: geeki.title,
      login: geeki.login
    });
    return target;
  }

  getValueAsId = () => {
    return this.state.valueId;
  };

  getValueAsLogin = () => {
    return this.state.value;
  }

  render() {
    if (this.state) {
      const { isLoading, value, results } = this.state;

      //fluid={true}
      return (
        <Search
          name={this.props.name}
          id={"searchComponent"}
          className={"geekiInput "+this.props.className}
          key="searchComponent"
          ref={"searchComponent"}
          disabled={this.props.disabled ? this.props.disabled : false}
          placeholder={this.props.placeholder}
          minCharacters={2}
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          resultRenderer={resultRenderer}
          results={results}
          value={value ? value.toString() : ""}
          noResultsMessage="Aucun résultat"
        />
      );
    }else return null;
  }
}