import React from "react";
import "./Comics.scss";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

interface IComicsButtonInputProps {
  title: String;
  href?: any;
  onClick?: any;
}

export default class ComicsButton extends React.Component<
  IComicsButtonInputProps
> {
  render() {
    return this.doHref(this.renderButton());
  }

  renderButton() {
    if (this.props.onClick) {
      return (
        <Button className="geekiButton" onClick={this.props.onClick}>
          {this.props.title}
        </Button>
      );
    } else {
      return (
        <Button className="geekiButton" type="submit">
          {this.props.title}
        </Button>
      );
    }
  }

  doHref(content) {
    return this.props.href ? (
      <Link to={this.props.href} className="comicsButton-href">
        {content}
      </Link>
    ) : (
      content
    );
  }
}
