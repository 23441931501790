import { Route, Routes } from "react-router-dom";

import ErrorBoundaryRoute from "../../../shared/error/error-boundary-route";

import LinkStats from "./link-stats";
import LinkStatsDetail from "./link-stats-detail";
import LinkStatsUpdate from "./link-stats-update";
import LinkStatsDeleteDialog from "./link-stats-delete-dialog";

const IndexRoutes = ({ match }) => (
    <><Routes>
      <Route path={`${match.url}/new`} element={<ErrorBoundaryRoute
        path={`${match.url}/new`}
        element={<LinkStatsUpdate />}/>}/>
      <Route path={`${match.url}/:id/edit`} element={<ErrorBoundaryRoute
        path={`${match.url}/:id/edit`}
        element={<LinkStatsUpdate />}/>}/>
      <Route path={`${match.url}/:id`} element={<ErrorBoundaryRoute
        path={`${match.url}/:id`}
        element={<LinkStatsDetail />} />}/>
      <Route path={match.url} element={<ErrorBoundaryRoute path={match.url} element={<LinkStats />} />}/>
    </Routes>
    <Route path={`${match.url}/:id/delete`} element={<ErrorBoundaryRoute
      path={`${match.url}/:id/delete`}
      element={<LinkStatsDeleteDialog match={match} history={undefined} index={true}/>}/>}/>
    </>
);

export default IndexRoutes;
