import "./Geeki.scss";

import GeekiSkeleton from "../widget/image/GeekiSkeleton";
import React from "react";
import { includesUpperCaseWithoutAccents } from "../../shared/util/string-utils";
import GeekiWidget from "./GeekiWidget";

interface IProps {
  list: any[];
  isInProduction: any;
}

interface IState {
  items: any[];
  loaded: boolean;
  geekiTypes: any;
}

class GeekisWidget extends React.Component<IProps,IState> {

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loaded: false,
      geekiTypes:[]
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const items1 = [];
    let j = 0;
    if (!this.state.loaded) {
      if (this.props.list) {
        for (const value of this.props.list.values()) {        
            j = j + 1;
            items1.push(
              <div
                style={{ order: j }}
                key={"alt" + Math.random()}
                className="stretchChild"
              >
                {this.renderGeekis(value)}
              </div>
            );
          
        }
      }
      this.setState({ items: items1, loaded: true });
    }
  }

  render() {
    if (!this.state.loaded || !this.state.items || this.state.items.length === 0) {
      return <GeekiSkeleton height={200} width={200} />;
    } else {
        return <div className="geekisWidget width100Perc">{this.state.items}</div>;
    }
  }

  filterGeekis(selectedOptions,name){
    const items1 = [];
    let j = 0;
    if (this.props.list) {
        for (const value of this.props.list.values()) {
            if(selectedOptions.length ===0 || selectedOptions.includes(value.geekiType)){
                if(name==='' || includesUpperCaseWithoutAccents(value.title,name)){
                    j = j + 1;
                    items1.push(
                      <div
                        style={{ order: j }}
                        key={"alt" + Math.random()}
                        className="stretchChild"
                      >
                        {this.renderGeekis(value)}
                      </div>
                    );
                }                         
            }
        }
      }
      this.setState({geekiTypes: selectedOptions, items: items1, loaded: true });
  }



  renderGeekis(value) {
    return <GeekiWidget
    geeki={value}
    isInProduction={this.props.isInProduction}
    bottomLeftClassName="tooltipvalidated"
    />;
  }
}

export default GeekisWidget;
