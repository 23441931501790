import "../admin.scss";

import React from "react";
import { Row, Col, Input } from "reactstrap";
import FiltersBox from "../../widget/filter/FiltersBox";

interface IProps {
    filterMethod: any;
}

export default class AdminActivityFilter extends React.Component<IProps> {

    filters={id:'',title:'',geekiLogin:''};

    filter(){
        this.props.filterMethod(this.filters);
    }

    setIsNewVersion(isNew: boolean) {
        //TODO CST
    }

    handleTitleChange = (name) => {
        this.filters.title=name.target.value;
        this.filter();
    };
    handleIDChange = (name) => {
        this.filters.id=name.target.value;
        this.filter();
    };
    handleGeekiLoginChange = (name) => {
        this.filters.geekiLogin=name.target.value;
        this.filter();
    };

    render() {
        return (<FiltersBox 
            content={this.renderContent()}
            addButtonUrl={"/admin/activity/create"}
            expanded={true}/>);
    }

    renderContent(){
        return (
            <div className="width100Perc filter-box">
                <Row className="justify-content-center width100Perc">
                    <Col md="3">
                        <Input
                            key="ng-id"
                            type="text"
                            className="form-control"
                            name="id"
                            placeholder="ID"
                            onChange={this.handleIDChange}
                        />
                    </Col>
                    <Col md="3">
                        <Input
                            key="ng-city"
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Titre"
                            onChange={this.handleTitleChange}
                        />
                    </Col>
                    <Col md="3">
                        <Input
                            key="ng-geekilogin"
                            type="text"
                            className="form-control"
                            name="geekiLogin"
                            placeholder="Geeki login"
                            onChange={this.handleGeekiLoginChange}
                        />
                    </Col>
                    <Col md="3">
                    </Col>
                </Row>
            </div>);
    }
}