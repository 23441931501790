import "./Convention.scss";

import React from "react";
import { Row, Col, Input, Label } from "reactstrap";
import { DatePicker } from "@mui/x-date-pickers";
import FiltersBox from "../widget/filter/FiltersBox";

interface IProps {
    filterConventions: any;
}

export default class ConventionsFilter extends React.Component<IProps> {

    dateFrom;
    dateTo;
    name;
    postalCode;
    city;

    filter(){
        this.props.filterConventions(this.dateFrom,this.dateTo,this.name,this.city,this.postalCode);
    }

    handleDateFromChange = (date) => {
        this.dateFrom=date;
        this.filter();
        };
    handleDateToChange = (date) => {
        this.dateTo=date;
        this.filter();
    };
    handleNameChange = (name) => {
        this.name=name.target.value;
        this.filter();
    };
    handleCityChange = (city) => {
        this.city=city.target.value;
        this.filter();
    };
    handlePostalCodeChange = (postalCode) => {
        this.postalCode=postalCode.target.value;
        this.filter();
    };

    render() {
        return (<FiltersBox content={this.renderContent()} expanded={true}/>);
    }

    renderContent(){
        return (
            <div className="width100Perc filter-box">
                <Row className="justify-content-center width100Perc">
                <Col md="1">De</Col>
                    <Col md="2"><DatePicker 
                            key="ng-date_from"
                            className="form-control"
                            value=""
                            onChange={this.handleDateFromChange}
                        />
                    </Col>
                    <Col md="1">A</Col>
                    <Col md="2">
                        <DatePicker 
                            key="ng-date_to"
                            className="form-control"
                            value=""
                            onChange={this.handleDateToChange}
                        />
                    </Col>
                    <Col md="2">
                        <Input
                            key="ng-postalCode"
                            type="text"
                            className="form-control"
                            name="postalCode"
                            placeholder="Code postal"
                            onChange={this.handlePostalCodeChange}
                        />
                    </Col>
                    <Col md="2">
                        <Input
                            key="ng-city"
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="Ville"
                            onChange={this.handleCityChange}
                        />
                    </Col>
                    <Col md="2">
                        <Input
                            key="ng-name"
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Nom"
                            onChange={this.handleNameChange}
                        />
                    </Col>
                </Row>
            </div>);
    }
}