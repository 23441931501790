import "../admin.scss";
import React from "react";
import LazyLoad from 'react-lazyload';
import { includesUpperCaseWithoutAccents } from "../../../shared/util/string-utils";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

interface IProps {
}
interface IState {
    entityList: any;
}

export default class AdminConventionList extends React.Component<IProps,IState> {

    constructor(props) {
        super(props);
        this.state = {
            entityList: null
        };
      }

      setIsNewVersion(isNew: boolean) {
      }

      updateEntityList(entityList){
        this.setState({entityList: entityList});
      }

      filterEntities(entityList,filter){
         let filteredEntityList=[];
         for (let i=0;i<entityList.length;i++){
            let entity=entityList[i].entity;
            let isValid=true;
            if (filter.title != null && filter.title !== undefined && filter.title !== '') {
              if (!includesUpperCaseWithoutAccents(entity.title, filter.title)) {
                isValid = false;
              }
            }
            if (filter.id != null && filter.id !== undefined && filter.id !== '') {
              if (!includesUpperCaseWithoutAccents(entity.id, filter.id)) {
                isValid = false;
              }
            }
            /*
            if(dateFrom !=null && dateFrom!== undefined && dateFrom!==''){
                let date=new Date(convention.startDate);
                if(date <dateFrom){
                    isValid=false;
                }
            } 
            */
            if(isValid){
                filteredEntityList.push(entityList[i]);
            }
          }
          this.setState({entityList: filteredEntityList});
      }


    render() {
        return ( 
            <div className="width100Perc">
            <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
            {this.renderEntities()}
           </LazyLoad>
           </div>);
    }

    renderEntities(){
        return (this.state.entityList && this.state.entityList.length > 0 ? (
            <Table responsive aria-describedby="playlist-heading">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Geeki</th>
                  <th>Date début</th>
                  <th>Date fin</th>
                  <th>Pays</th>
                  <th>Code postal</th>
                  <th>Ville</th>
                </tr>
              </thead>
              <tbody>
                {this.state.entityList.map((entity, i) => (
                  <tr key={`entity-${i}`}>
                    <td><Link to={"/admin/convention/edit/"+entity.entity.id}>{entity.entity.id}</Link></td>
                    <td>{entity.entity.name}</td>
                    <td>{entity.entity.geekiLogin}</td>
                    <td>{entity.entity.startDate}</td>
                    <td>{entity.entity.endDate}</td>
                    <td>{entity.entity.country}</td>
                    <td>{entity.entity.postalCode}</td>
                    <td>{entity.entity.city}</td>
                  </tr>
                ))}
              </tbody>
            </Table>):<div>Aucune Convention trouvée</div>);
      }
    
}