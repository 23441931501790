import React from "react";
import { Navigate  } from "react-router-dom";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { connect } from "react-redux";
import { getEntityByLogin, getEntities } from "../../reducers/geeki.reducer";
import Links from "../widget/social/Links";
import ComicsTabs from "../widget/comics/ComicsTabs";
import WikiComicsCell from "../widget/wiki/WikiComicsCell";
import TwitterComicsCell from "../widget/twitter/TwitterComicsCell";
import TwitchComicsCell from "../widget/twitch/TwitchComicsCell";
import { geekiLabelShort } from "../config/GeekiUtil";
import ProductsCategories from "../widget/product/ProductsCategories";
import Activity from "../activity/Activity";
import GeekiSkeleton from "../widget/image/GeekiSkeleton";
import PlaylistCarouselPanel from "../widget/playlist/PlaylistCarouselPanel";
import GeekisComicsCell from "../geeki/GeekisComicsCell";
import RssComicsCell from "../widget/rss/RssComicsCell";
import SkeletonImage from "../widget/image/SkeletonImage";
import { imgUrl } from "../../shared/util/url-utils";
import IconWithSub from "../widget/icon/IconWithSub";
import GeekiNews from "../widget/news/GeekiNews";
import GeekiLayout from "../../shared/util/GeekiLayout";

export interface IProps  extends StateProps, DispatchProps{ 
  isInProduction: any
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  loaded: boolean;
  notFound: boolean;
  geekiEntity?: any;
  geekies: any[];
}

class Geeki extends React.Component<Props, IState> {
  carRef;
  TAB_PRODUCTS = -1;
  TAB_GEEKIS = -1;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      notFound: false,
      geekies: []
    };
    this.carRef = React.createRef();
  }

  componentDidMount() {
    this.getGeekiByLogin();
    this.goToHome();
  }

  componentDidUpdate() {
    if (
      this.state.geekiEntity &&
      this.state.geekiEntity.login !== this.props.match.params['id']
    ) {
      this.getGeekiByLogin();
      this.goToHome();
    }
  }



  render() {
    let tabCounts = 1;
    if (this.state.notFound) {
      return <Navigate to="/PageNotFound" />;
    }
    const { geekiEntity } = this.state;
    const products = [];
    if (geekiEntity && geekiEntity.activities && geekiEntity.activities.length > 0) {
      for (const value of geekiEntity.activities.values()) {
        if (value.img) {
          products.push(value);
        }
      }
    }

    let arrayTitles = [];
    let arrayContents = [];

    let social = geekiEntity ? (
      <Links
        key="authorSocial"
        entityUrl={geekiEntity.url}
        entityType="AUTHOR"
        entityLogin={geekiEntity.login}
        wiki={geekiEntity.wiki}
        now={geekiEntity.nolifeWiki}
        fb={geekiEntity.facebook}
        tw={geekiEntity.twitter}
        ins={geekiEntity.instagram}
        th={geekiEntity.twitch}
        pi={geekiEntity.pinterest}
        di={geekiEntity.discord}
        yo={geekiEntity.youtube}
        dai={geekiEntity.dailymotion}
        ti={geekiEntity.tipeee}
        im={geekiEntity.imdb}
        tito={geekiEntity.tiktok}
        sc={geekiEntity.soundCloud}
        pc={geekiEntity.podCloud}
        li={geekiEntity.linkedIn}
        isInProduction={this.props.isInProduction}
      />
    ) : (
      <GeekiSkeleton key="authorSocialSkeleton" height={400} />
    );
    const authorTitle = geekiEntity ? geekiLabelShort(geekiEntity) : "";
    const contentPresentation = [];
    contentPresentation.push(
      <div key={"aut" + Math.random()} className="geekiFirstRow heightAuto">
        <div key={"autddd" + Math.random()} className="socials-diff">
          <WikiComicsCell
            className="height100Perc geekiDescription width100Perc"
            entity={geekiEntity}
          />
        </div>
        <div key={"autdddd" + Math.random()} className="geekiSocials">
          {social}
        </div>
      </div>
    );

    if (geekiEntity && geekiEntity.activities && geekiEntity.activities.length > 0) {
      contentPresentation.push(
        <Activity
          page="AUTHOR"
          entity={geekiEntity}
          parent={this.carRef}
          tabIndex={1}
          geekies={this.state.geekies}
          isInProduction={this.props.isInProduction}
        />
      );
    }




    
/////TAB Presentation

    arrayTitles.push(
      <IconWithSub
        size="large"
        className="info circle"
        title="Présentation"
        isInProduction={this.props.isInProduction}
      />
    );
    arrayContents.push(contentPresentation);

    //// TAB Actualité

      if(this.state.geekiEntity && this.state.geekiEntity.news.length>0){

      arrayTitles.push(
        <IconWithSub
          size="large"
          className="bell"
          title="Actualité"
          isInProduction={this.props.isInProduction}
        />
      );
      arrayContents.push(
        <GeekiNews
          geeki={this.state.geekiEntity}
          isTwitter={false}
        />
      );
      }
      tabCounts = tabCounts + 1;
      this.TAB_PRODUCTS = tabCounts;

    
//// TAB Boutique

    if (products.length > 0) {
      arrayTitles.push(
        <IconWithSub
          size="large"
          className="shop"
          title="Boutique"
          isInProduction={this.props.isInProduction}
        />
      );
      arrayContents.push(
        <ProductsCategories key="authorProducts" activities={products} isInProduction={this.props.isInProduction} />
      );
      tabCounts = tabCounts + 1;
      this.TAB_PRODUCTS = tabCounts;
    }

    ///// TAB Playlist

    if (geekiEntity && geekiEntity.playlists && geekiEntity.playlists.length > 0) {
      arrayTitles.push(
        <IconWithSub
          size="large"
          className="video"
          title="Playlists"
          isInProduction={this.props.isInProduction}
        />
      );
      arrayContents.push(
        <PlaylistCarouselPanel 
        isInProduction={this.props.isInProduction}
        className="carouselBg carouselBgGeeki"
        items={geekiEntity.playlists} />
      );
      tabCounts = tabCounts + 1;
      this.TAB_PRODUCTS = tabCounts;
    }

    //// TAB Geekis associés

    if (geekiEntity && geekiEntity.activities.length > 0) {
      arrayTitles.push(
        <IconWithSub
          size="large"
          className="users"
          title="Réseau"
          isInProduction={this.props.isInProduction}
        />
      );
      arrayContents.push(
        <GeekisComicsCell
          key="autwor"
          inline="true"
          className="width100Perc geekiNetwork"
          cssPanel="overflowHidden"
          activities={geekiEntity.activities}
          network={geekiEntity.network}
          geeki={geekiEntity}
          geekies={this.state.geekies}
          isInProduction={this.props.isInProduction}
        />
      );
      tabCounts = tabCounts + 1;
      this.TAB_GEEKIS = tabCounts;
    }

    //// TAB Twitter
/*
    if (geekiEntity && geekiEntity.twitter) {
      const arrayTwitter = geekiEntity.twitter.split("|");
      if (arrayTwitter.length === 1) {
        const contentTwitter = [];
        contentTwitter.push(
          <TwitterComicsCell
            key="authorTweets"
            tweetId={geekiEntity.twitter}
            isInProduction={this.props.isInProduction}
          />
        );


        arrayTitles.push(
          <IconWithSub
            size="large"
            className="twitter"
            title="Tweets"
            isInProduction={this.props.isInProduction}
          />
        );
        arrayContents.push(contentTwitter);
      } else {
        for (var i = 0; i < arrayTwitter.length; i++) {
          const nextTwitter = arrayTwitter[i];
          const contentTwitter = [];
          contentTwitter.push(
            <TwitterComicsCell key="authorTweets" tweetId={nextTwitter}  isInProduction={this.props.isInProduction}/>
          );
          arrayTitles.push(
            <IconWithSub
              size="large"
              className="twitter"
              title={"Tweets [ " + nextTwitter + " ]"}
              isInProduction={this.props.isInProduction}
            />
          );
          arrayContents.push(contentTwitter);
        }
      }
    }
*/


    /*

    ////TAB Instagram
    if (geekiEntity && geekiEntity.instagram) {
      const arrayInsta = geekiEntity.instagram.split("|");
      if (arrayInsta.length == 1) {
        const contentInstagram = [];
        contentInstagram.push(<InstagramComicsCell parent={this.carRef} key="authorInsta" instaId={geekiEntity.instagram} />);
        arrayTitles.push(<IconWithTooltip size="large" className="instagram" title="Instagram" />);
        arrayContents.push(contentInstagram);
      } else {
        for (var i = 0; i < arrayInsta.length; i++) {
          const nextInsta = arrayInsta[i];
          const contentInstagram = [];
          contentInstagram.push(<InstagramComicsCell parent={this.carRef} key="authorInsta" instaId={nextInsta} />);
          arrayTitles.push(<IconWithTooltip size="large" className="instagram" title="Instagram" />);
          arrayContents.push(contentInstagram);
        }
      }
    }*/
    /*
        geekiEntity.pinterest="toto";//TODO CST remove
        if (geekiEntity && geekiEntity.pinterest) {
          const contentPinterest = [];
          contentPinterest.push(<PinterestComicsCell  parent={this.carRef} key="authorPinterest" pinterestId={geekiEntity.pinterest} />);
          arrayTitles.push("Pinterest");
          arrayContents.push(contentPinterest);
        }*/

        ////TAB Twitch
    if (geekiEntity && geekiEntity.twitch) {
      const arrayTwitch = geekiEntity.twitch.split("|");
      if (arrayTwitch.length === 1) {
        const contentTwitch = [];
        contentTwitch.push(
          <TwitchComicsCell
            parent={this.carRef}
            key="authorTwi"
            channel={geekiEntity.twitch}
          />
        );
        arrayTitles.push(
          <IconWithSub
            size="large"
            className="twitch"
            title="Twitch"
            isInProduction={this.props.isInProduction}
          />
        );
        arrayContents.push(contentTwitch);
      } else {
        for (var j = 0; j < arrayTwitch.length; j++) {
          const nextTwitch = arrayTwitch[j];
          const contentTwitch = [];
          contentTwitch.push(
            <TwitchComicsCell
              parent={this.carRef}
              key="authorTwi"
              channel={nextTwitch}
            />
          );
          arrayTitles.push(
            <IconWithSub
              size="large"
              className="twitch"
              title={"Twitch " + nextTwitch}
              isInProduction={this.props.isInProduction}
            />
          );
          arrayContents.push(contentTwitch);
        }
      }
    }

    ////TAB RSS

    if (geekiEntity && geekiEntity.rsses && geekiEntity.rsses.length > 0) {
      const photoUrl = imgUrl("/api/img/extgeeki/" + geekiEntity.login,this.props.isInProduction);
      let photoHeight=(("AUTEUR"===geekiEntity.geekiType)?181:120);
      let photoWidth=(("AUTEUR"===geekiEntity.geekiType)?135:240);
      const photo = (
        <SkeletonImage
          key={"skelImWo" + geekiEntity.login}
          src={photoUrl}
          width={photoWidth}
          height={photoHeight}
          alt={"Geekipédia oeuvre " + geekiEntity.name}
        />
      );
      const contentRss = (
        <div key={"wsrss" + Math.random()}>
          {geekiEntity.rsses && (
            <RssComicsCell rsses={geekiEntity.rsses} logo={photo} />
          )}
        </div>
      );
      arrayTitles.push(
        <IconWithSub
          size="large"
          className="rss"
          title={"Flux RSS"}
          isInProduction={this.props.isInProduction}
        />
      );
      arrayContents.push(contentRss);
      }

    const comicsTabs = (
      <ComicsTabs
        ref={this.carRef}
        key="tabs"
        titleHeader={authorTitle}
        titles={arrayTitles}
        contents={arrayContents}
      />
    );
    return (<GeekiLayout title="" component={comicsTabs}/>);
  }
  getGeekiByLogin = async () => {
    try {
      const geekiEntity: any = await (this.props as any).getEntityByLogin(
        this.props.match.params['id']
      );
      let entityList: any = this.props.geekiList;
      if (entityList == null || entityList.length === 0) {
        entityList = await (this.props as any).getEntities();
        entityList = entityList.value.data;
      }

      this.setState({
        loaded: true,
        geekiEntity: geekiEntity.value.data,
        geekies: entityList
      });
    } catch (e) {
      //TODO CST tester err 404
      console.log(e);

      this.setState({
        notFound: true
      });
    }
  };

  goToProducts = () => {
    this.carRef.current.setSelectedIndex(this.TAB_PRODUCTS);
  }
  goToGeekis = () => {
    this.carRef.current.setSelectedIndex(this.TAB_GEEKIS);
  }
  goToHome = () => {
    this.carRef.current.setSelectedIndex(1);
  }
}
const mapStateToProps = ({geeki, applicationProfile}: IRootState) => ({
  geekiEntity: geeki.entity,
  geekiList: geeki.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntityByLogin,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Geeki));
