import "../widget/comics/Comics.scss";
import "./Geeki.scss";

import React from "react";
import DbParseUtils2Mappings from "../widget/util/DbParseUtils2Mappings";
import GeekiWidget from "./GeekiWidget";

interface IProps {
  geeki: any;
  skills?: any;
  sex?: any;
  isInProduction: boolean;
}

export default class GeekiWithSkillsWidget extends React.Component<IProps> {
  render() {
    const geeki = this.props.geeki;
    let sex= (geeki.geekiType === 'AUTEUR')?geeki.sex:this.props.sex;
    const skills = this.props.skills?this.extractSkills(this.props.skills, sex):this.extractSkills(geeki.skills, sex);
    return (
      <GeekiWidget
        bottomLeft={skills}
        bottomLeftClassName="geekiWidget-skills"
        geeki={geeki}
        isInProduction={this.props.isInProduction}
      />
    );
  }
  extractSkills(skills, sex) {
    if (skills === null || skills === "") {
      return null;
    }
    let result = [];
    const splitted = skills.split("|");
    for (let i = 0; i < splitted.length; i++) {
      let skillLabel = DbParseUtils2Mappings.translateSkill(splitted[i], sex);
      if (skillLabel != null) {
        if (result.length > 0) {
          result.push(<span key={"GeekiWithSkillsWidgetSpan" + i}> - </span>);
        }
        result.push(
          <span key={"GeekiWithSkillsWidgetSpanT" + i}>{skillLabel}</span>
        );
      }
    }
    return result;
  }
}
