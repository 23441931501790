import "../modules/home/home.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GeekiLayout from "../shared/util/GeekiLayout";
import ModernComicsCell from "./widget/comics/ModernComicsCell";

export type IProps = StateProps;

export const About = (props: IProps) => {
 
  const presentationContent =
      <div className="width100Perc-fullPage" key={"prd" + Math.random()}>
        <ModernComicsCell
          inline="true"
          innerHTML={false}
          content={
            <div className="width100Perc">
              <div key={"prdd" + Math.random()} className="width100Perc">
                <h2 className="geekiText">
                  Bonjour,
                  <p>Bienvenue sur Geeki, le portail Geek. Anciennement Geekipedia! L'encyclopédie sur les acteurs de la culture Geek (auteurs, sites web, séries TV ou Web,...), la nouvelle mouture du site se veut plus ouverte sur l'actualité avec une section Agenda et une autre News.</p>
                  <p>Geekipedia était un site créé par Cyril Starczan et lancé en Janvier 2020. Il renait de ses cendres en 2024 sour le nom Geeki.Son but est de faire découvrir ou redécouvrir les différents acteurs de la culture geek francophone.</p>
                  <p>Sur Geeki vous pourrez trouver toute l'actualité de vos auteurs favoris; X anciennement Twitter, Youtube, Dailymotion, Twitch, Instagram, et bientôt encore plus...</p>
                  <p>Si vous voulez soutenir ce projet, vous pouvez le faire en commandant les articles Amazon présents sur le site, je toucherais un faible pourcentage. Je suis seul à maintenir ce site, si vous l'aimez n'hésitez pas à me soutenir de cette manière.</p>
                </h2>
              </div>
              <div key={"prdd" + Math.random()}>
                <h2 className="geekiText">
                  Merci à <Link to="/geeki/mikiramanak">Miki Ramanak</Link>,
                  <Link to="/geeki/xavierfournier">Xavier Fournier</Link>,{" "}
                  <Link to="/geeki/julienpirou">Julien Pirou</Link> et{" "}
                  <Link to="/geeki/sironimo">Sironimo</Link> pour leur
                  précieux conseils.
                </h2>
                <h2 className="geekiText">
                  Merci aussi à MetalTux, Shaken et Florestan Fournier pour
                  leur nombreux conseils.
                </h2>
              </div>
            </div>
          }
        />
      </div>
      ;
  
  return (<GeekiLayout title="A propos" component={<div className="centered-out about">
  <div className="centered-in">
    {presentationContent}
  </div></div>}/>);
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(About);
