import axios from "axios";
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from "../lib/redux-action.type";
import { cleanEntity } from "../shared/util/entity-utils";
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";

import { IRssFeed, defaultValue } from "../shared/model/rss-feed.model";

export const ACTION_TYPES = {
  FETCH_RSSFEED_LIST: "rssFeed/FETCH_RSSFEED_LIST",
  FETCH_RSSFEED: "rssFeed/FETCH_RSSFEED",
  CREATE_RSSFEED: "rssFeed/CREATE_RSSFEED",
  UPDATE_RSSFEED: "rssFeed/UPDATE_RSSFEED",
  DELETE_RSSFEED: "rssFeed/DELETE_RSSFEED",
  SET_BLOB: "rssFeed/SET_BLOB",
  RESET: "rssFeed/RESET"
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRssFeed>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type RssFeedState = Readonly<typeof initialState>;

// Reducer

const rssFeedState = (state: RssFeedState = initialState, action): RssFeedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_RSSFEED_LIST):
    case REQUEST(ACTION_TYPES.FETCH_RSSFEED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_RSSFEED):
    case REQUEST(ACTION_TYPES.UPDATE_RSSFEED):
    case REQUEST(ACTION_TYPES.DELETE_RSSFEED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_RSSFEED_LIST):
    case FAILURE(ACTION_TYPES.FETCH_RSSFEED):
    case FAILURE(ACTION_TYPES.CREATE_RSSFEED):
    case FAILURE(ACTION_TYPES.UPDATE_RSSFEED):
    case FAILURE(ACTION_TYPES.DELETE_RSSFEED):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_RSSFEED_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_RSSFEED):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_RSSFEED):
    case SUCCESS(ACTION_TYPES.UPDATE_RSSFEED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_RSSFEED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + "ContentType"]: contentType
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default rssFeedState;

const apiUrl = "api/rss-feeds";

// Actions

export const getEntities: ICrudGetAllAction<IRssFeed> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_RSSFEED_LIST,
  payload: axios.get<IRssFeed>(`${apiUrl}?cacheBuster=${new Date().getTime()}`)
});

export const getEntity: ICrudGetAction<IRssFeed> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_RSSFEED,
    payload: axios.get<IRssFeed>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<
  IRssFeed
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RSSFEED,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<
  IRssFeed
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_RSSFEED,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<
  IRssFeed
> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_RSSFEED,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
