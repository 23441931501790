import React, { useState, useEffect } from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { IRootState } from "../../reducers";
import { isUserAuthenticated, login } from "../../reducers/authentication";
import LoginModal from "./login-modal";
import GeekiLayout from "../../shared/util/GeekiLayout";

export interface IProps
  extends StateProps,
    DispatchProps
    {
      location: any,
      login
    }
    type Props = IProps & WithRouterProps<IProps>;

export const Login = (props: Props) => {
  let [showModal, setShowModal] = useState(props.showModal);
  showModal = true;

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props.showModal]);

  const handleLogin = (username, password, rememberMe = false) =>
    props.login(username, password, rememberMe);

  const handleClose = () => setShowModal(false);

  const { location } = props;
  const { from } = (location.state as any) || {
    from: { pathname: "/", search: location.search }
  };
  if (isUserAuthenticated()) {
    return <Navigate to={from} />;
  }
  return (<GeekiLayout title="Connexion" component={<LoginModal
    showModal={showModal}
    handleLogin={handleLogin}
    handleClose={handleClose}
    loginError={props.loginError}
  />}/>);
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));
