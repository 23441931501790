import React from "react";
import { Link } from "react-router-dom";
import "./Comics.scss";
import IWithTooltip from "../icon/IWithTooltip";

interface IProps {
  inline?: string;
  fullWidth?: boolean;
  footerLink?: string;
  footer?: any;
  footer2?: any;
  title?: string;
  cssTitle?: string;
  topright?: any;
  bottomLeft?: any;
  bottomLeftClassName?: string;
  innerHTML?: boolean;
  content: any;
  cssContent?: string;
  url?: string;
  globalUrl?: string;
  onClick?: any;
  className?: string;
  cssPanel ?: string;
}

export default class ModernComicsCell extends React.Component<IProps> {

  render() {
    const cssPanel = "panel comicsCellTitle modernCCPanel "+this.props.cssPanel;
    const cssContent = this.props.cssContent
      ? "modernCC-content " + this.props.cssContent
      : "modernCC-content";
    let css = this.props.inline ? "inlineBlock " : " ";
    css = this.props.fullWidth
      ? css + " comicsCellFullWidth"
      : css + " width100Perc-fullPage";
    const footer = this.props.footerLink
      ? this.renderFooterLink()
      : this.props.footer;
    const innerHTML =
      "<div className='" +
      (this.props.className ? "modernCC " + this.props.className : "modernCC") +
      "'>" +
      this.props.content +
      "</div>";
    const inner = (
      <div key={"cc" + Math.random()} className={cssPanel}>
        {!this.props.innerHTML && (
          <div key={"ccc" + Math.random()} className={cssContent}>
            {this.renderLink(
              <div
                key={"cccd" + Math.random()}
                className={
                  this.props.className
                    ? "modernCC " + this.props.className
                    : "modernCC"
                }
              >
                {this.props.content}
              </div>
            )}
          </div>
        )}
        {this.props.innerHTML && (
          <div
            key={"cccc" + Math.random()}
            className={cssContent}
            dangerouslySetInnerHTML={{ __html: innerHTML }}
          />
        )}
        {this.props.title && (
          <div key={"ccccc" + Math.random()} className={"text top-left "+this.props.cssTitle}>
            <IWithTooltip
              className="news comicsCellTitle large icon"
              title={this.props.title}
            />
            <span
              key={"cccccs" + Math.random()}
              aria-hidden="true"
              className="large"
            >
              <i>{this.props.title}</i>
            </span>
          </div>
        )}
        {this.props.topright && this.props.topright !== "" && (
          <div key={"cccccc" + Math.random()} className="text top-right">
            <span
              key={"ccccccs" + Math.random()}
              aria-hidden="true"
              className="large"
            >
              <i>{this.props.topright}</i>
            </span>
          </div>
        )}
        {this.props.bottomLeft && this.props.bottomLeft !== "" && (
          <div
            key={"ccccccc" + Math.random()}
            className={"text2 bottom-left " + this.props.bottomLeftClassName}
          >
            <span
              key={"cccccccs" + Math.random()}
              aria-hidden="true"
              className="large"
            >
              <i>{this.props.bottomLeft}</i>
            </span>
          </div>
        )}

        {this.props.footer2 && (
          <div key={"cca" + Math.random()} className="text bottom-right2">
            {this.props.footer2}
          </div>
        )}
        {this.props.footer && (
          <div key={"ccb" + Math.random()} className="text bottom-right">
            {footer}
          </div>
        )}
      </div>
    );

    if (css == null || css === "undefined" || css === "  undefined") {
      return this.renderGlobalLink(inner);
    } else {
      return this.renderGlobalLink(
        <div key={"ccd" + Math.random()} className={css}>
          {inner}
        </div>
      );
    }
  }

  renderFooterLink() {
    return this.props.footerLink.indexOf("/custom/") > -1 ? (
      <Link
        key={"cce" + Math.random()}
        to={this.props.footerLink}
        className="footerLink"
      >
        {this.props.footer}
      </Link>
    ) : (
      <a
        key={"ccf" + Math.random()}
        href={this.props.footerLink}
        target="_new"
        className="footerLink"
      >
        {this.props.footer}
      </a>
    );
  }

  renderLink(obj) {
    return this.props.url ? (
      <Link key={"ccg" + Math.random()} to={this.props.url}>
        {obj}
      </Link>
    ) : this.props.onClick ? (
      <span onClick={this.props.onClick}>{obj}</span>
    ) : (
      obj
    );
  }
  renderGlobalLink(obj) {
    return this.props.globalUrl ? (
      <Link key={"cch" + Math.random()} to={this.props.globalUrl}>
        {obj}
      </Link>
    ) : (
      obj
    );
  }
}
