import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label, Input } from "reactstrap";

import { formatText, updateConvention, getDescription } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import GeekiInput from "../geeki/GeekiInput";
import { adminLayout, alertResult } from "../util/admin-utils";
import DbParseUtils from "../../widget/util/DbParseOldUtil";
import DescriptionEditor from "../geeki/DescriptionEditor";
import AdmLiquibaseIndicator from "../AdmLiquibaseIndicator";
import { WithRouterProps } from "../../../shared/util/withRouter";
import { IConvention } from "../../../shared/model/convention.model";
import { getAdminGeekiByNewsLogin } from "../../../shared/util/entity-utils";
import { getAdminConventionEntity, getAdminGeekiEntities } from "../../../reducers/admin-reducer";
import AdminNotAuthenticated from "../AdminNotAuthenticated";
import { isUserAuthenticated } from "../../../reducers/authentication";

interface IProps extends StateProps, DispatchProps {
  formatText: any;
  isInProduction:boolean;
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  isNewVersion: boolean
}

class AdminConventionEdit extends React.Component<Props, IState> {

  srcLongDescription: string;
  targetLongDescription: string;

  longRef;
  indicatorRef;

  geekiEntity;
  geekies;
  entity: IConvention;



  constructor(props: any) {
    super(props);
    this.entity = null;
    this.state = { isNewVersion: false };
    this.srcLongDescription = '';
    this.targetLongDescription = '';

    this.longRef = React.createRef();
    this.indicatorRef = React.createRef();

    this.loadEntity();
  }

  loadEntity = async () => {
    const hash = window.location.hash; // Obtient la partie d'ancrage de l'URL (#/admin/news/edit/123)
    this.geekies = (await (this.props as any).getAdminGeekiEntities()).value.data;
    if (hash.endsWith("create")) {
      this.entity = {};
    } else {
      const parts = hash.split('/'); // Divise la partie d'ancrage en segments
      const value = parts[parts.length - 1]; // Récupère la dernière partie de la division
      const entity: any = await (this.props as any).getAdminConventionEntity(value);
      this.entity = entity.value.data;
      this.geekiEntity = getAdminGeekiByNewsLogin(this.geekies, this.entity.geekiLogin);

      const cleanLongResult = this.removeLinkTags(this.entity.description);
      this.srcLongDescription = cleanLongResult;
      this.formatSrcDescription();
    }
    this.setState({ isNewVersion: this.state.isNewVersion });///to force the render
  }


  setIsNewVersion = (isNew: boolean) =>  {
    this.setState({ isNewVersion: isNew });
  }

  updateInFile = async () => {
    this.entity.id = this.filterValue(this.entity.id);
    this.entity.name = this.filterValue(this.entity.name);
    this.entity.startDate = this.filterValue(this.entity.startDate);
    this.entity.endDate = this.filterValue(this.entity.endDate);
    this.entity.country = this.filterValue(this.entity.country);
    this.entity.city = this.filterValue(this.entity.city);
    this.entity.address = this.filterValue(this.entity.address);
    this.entity.website = this.filterValue(this.entity.website);
    this.entity.postalCode = this.filterValue(this.entity.postalCode);
    this.entity.geekiLogin = (this.geekiEntity!=null?this.geekiEntity.login:null)

    this.entity.description = this.targetLongDescription;

    const result = await this.props.updateConvention(this.entity);
    alertResult(result);
  }

  updateName = (event) => {
    this.entity.name = event.target.value;
  }
  updateStartDate = (event) => {
    this.entity.startDate = event.target.value;
  }
  updateEndDate = (event) => {
    this.entity.endDate = event.target.value;
  }
  updateCountry = (event) => {
    this.entity.country = event.target.value;
  }
  updatePostalCode = (event) => {
    this.entity.postalCode = event.target.value;
  }
  updateCity = (event) => {
    this.entity.city = event.target.value;
  }
  updateAddress = (event) => {
    this.entity.address = event.target.value;
  }
  updateWebsite = (event) => {
    this.entity.website = event.target.value;
  }


  formatSrcDescription = async () => {
    const result = await this.props.formatText('', this.srcLongDescription);
    const cleanLongResult = result.value.data.longDescription;
    this.targetLongDescription = cleanLongResult;
    const parsedLongResult = DbParseUtils.parseDescription(cleanLongResult, false);

    this.longRef.current.setText(cleanLongResult);
    this.longRef.current.setHtml(parsedLongResult);
  };

  formatTargetDescription = async () => {
    const result = await this.props.formatText('', this.targetLongDescription);
    const cleanLongResult = result.value.data.longDescription;
    this.targetLongDescription = cleanLongResult;
    const parsedLongResult = DbParseUtils.parseDescription(cleanLongResult, false);

    this.longRef.current.setText(cleanLongResult);
    this.longRef.current.setHtml(parsedLongResult);
  };

  removeLinkTags = (text: string) => {
    const regex = /<Link[^>]*>(.*?)<\/Link>/gi;
    if (text != null && text !== undefined) {
      return text.replace(regex, '$1');
    } else {
      return "";
    }
  }

  transformFromTarget = (event: any) => {
    this.formatTargetDescription();
  }

  handleLoadDesc = async () => {
    //TODO CST fix
    const desc = await (this.props as any).getDescription(this.geekiEntity.login);
    const cleanLongResult = this.removeLinkTags(desc.value.data.longDescription);
    this.srcLongDescription = cleanLongResult;
    this.formatSrcDescription();
  }

  textLongTargetChanged = (event: any) =>  {
    this.targetLongDescription = event.target.value;
  }

  callbackFunctionGetGeeki = obj => {
    this.geekiEntity = obj;
    this.handleLoadDesc();
  };

  filterValue(value) {
    if (value != null && String(value).indexOf(";") > -1) {
      alert("error: " + value);
      return "";
    } else {
      return value;
    }
  }


  render() {
    if (!isUserAuthenticated()) {
      return <AdminNotAuthenticated/>;
    } else {
    if (this.entity) {
      return adminLayout(this.entity.id ? "Edition d'une Convention" : "Saisie d'une Convention", <>
        <Row className="justify-content-center width100Perc">
          <Col md="12">
            <Row className="justify-content-center width100Perc">
              <Col md="8">
                <Button onClick={this.transformFromTarget}>Formater</Button>
              </Col>
              <Col md="4">
                <Row className="justify-content-center">

                  <Col md="9"><AdmLiquibaseIndicator ref={this.indicatorRef}
                    fctSetNewVersion={this.setIsNewVersion} /></Col>
                  <Col md="3">
                    <Button onClick={this.updateInFile}
                      className="geekiButton"
                      disabled={!this.state.isNewVersion}>Mettre à jour fichier</Button></Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Label for="ng-name">Nom</Label>
                <Input
                  id="ng-name"
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={this.entity.name}
                  onChange={this.updateName}
                  required
                />
              </Col>

              <Col md="4">
                <Label for="ng-geeki_id_search">Geeki</Label>
                <GeekiInput
                  id="ng-geeki_id_search"
                  itemLoadKey="login"
                  itemSaveKey="login"
                  callbackFunction={this.callbackFunctionGetGeeki}
                  disabled={false}
                  value={this.entity.geekiLogin}
                  defaultValue=""
                  placeholder="Sélectionnez un Geeki"
                  geekies={this.geekies}
                /></Col>
              <Col md="4">       </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Label for="ng-startDate">Date début</Label>
                <Input
                  id="ng-startDate"
                  type="text"
                  className="form-control"
                  name="startDate"
                  defaultValue={this.entity.startDate || ''}
                  onChange={this.updateStartDate}
                />
              </Col>
              <Col md="4">
                <Label for="ng-endDate">Date fin</Label>
                <Input
                  id="ng-endDate"
                  type="text"
                  className="form-control"
                  name="endDate"
                  defaultValue={this.entity.endDate || ''}
                  onChange={this.updateEndDate}
                />
              </Col>
              <Col md="4">
                <Label for="ng-country">Pays</Label>
                <Input
                  id="ng-country"
                  type="text"
                  className="form-control"
                  name="country"
                  defaultValue={this.entity.country || ''}
                  onChange={this.updateCountry}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Label for="ng-postalCode">Code postal</Label>
                <Input
                  id="ng-postalCode"
                  type="text"
                  className="form-control"
                  name="postalCode"
                  defaultValue={this.entity.postalCode || ''}
                  onChange={this.updatePostalCode}
                />
              </Col>
              <Col md="4">
                <Label for="ng-city">Ville</Label>
                <Input
                  id="ng-city"
                  type="text"
                  className="form-control"
                  name="city"
                  defaultValue={this.entity.city || ''}
                  onChange={this.updateCity}
                />
              </Col>
              <Col md="4">
                <Label for="ng-address">Adresse</Label>
                <Input
                  id="ng-address"
                  type="text"
                  className="form-control"
                  name="address"
                  defaultValue={this.entity.address || ''}
                  onChange={this.updateAddress}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Label for="ng-website">Site web</Label>
                <Input
                  id="ng-website"
                  type="text"
                  className="form-control"
                  name="website"
                  defaultValue={this.entity.website || ''}
                  onChange={this.updateWebsite}
                />
              </Col>
              <Col md="4">
              </Col>
              <Col md="4">
              </Col>
            </Row>
            <Row className="justify-content-center"
              style={{ minHeight: "500px" }}>
              <Col md="12">
                <Label for="ng-description">Description</Label>
                <DescriptionEditor
                  minRows={15}
                  ref={this.longRef}
                  onChange={this.textLongTargetChanged}
                  maxRows={30}
                  defaultValue={this.entity.description}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>,this.props.isInProduction)
    } else {
      return "";
    }
      };      
  }
}

const mapStateToProps = ({ authentication, geeki,applicationProfile }: IRootState) => ({
  account: authentication.account,
  geekiEntity: geeki.entity,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminConventionEntity,
  formatText,
  updateConvention,
  getDescription,
  getAdminGeekiEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminConventionEdit);
