import "./Activity.scss";
import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import ActivityTimeLineElement from "./ActivityTimeLineElement";
import ModernComicsCell from "../widget/comics/ModernComicsCell";

export interface IProps{
  page: string;
  sex: string;
  activities: any[];
  geekies: any[],
  parent: any;
  tabIndex: number;
  geekiLogin: string;
  geekiType: string;
  isInProduction: boolean;
}

export interface IState {
  activities: any[];
}

export default class ActivityTimeLine extends React.Component<IProps,IState> {

  constructor(props) {
    super(props);
    this.state = {
      activities: []
    };
  }

  componentDidMount() {
   this.filterActivities();
  }

  filterActivities() {
    let activities = [];
    for (var i = 0; i < this.props.activities.length; i++) {
      let exist = false;
      let newActivity = this.props.activities[i];
      for (var j = 0; j < activities.length; j++) {
        let oldActivity = activities[j];
        if (
          newActivity.from === oldActivity.from &&
      newActivity.type === oldActivity.type &&
      newActivity.episode === oldActivity.episode &&
      newActivity.video === oldActivity.video &&
      newActivity.title === oldActivity.title &&
      newActivity.youtube === oldActivity.youtube &&
      newActivity.recompense === oldActivity.recompense
        ) {
          exist = true;
        }
      }
      if (!exist) {
        activities.push(this.props.activities[i]);
      }
    }
    activities.sort((a, b) => (a.from > b.from ? 1 : b.from > a.from ? -1 : 0));

    this.setState({ 
      activities: activities.reverse()});
  }

  render() {
    if(this.props.geekies.length>0){
    const activities = this.extractActivities();
    const ulActivities = <VerticalTimeline
                          key={"btlVTL" + Math.random()} animate={false}>{activities}</VerticalTimeline>;

    const css = "sign comicsCellTitle large icon";
    return (
      <ModernComicsCell
            key={"btlCC" + Math.random()}
            inline="true"
            cssPanel="overflowHidden width100Perc wiki"
            className="width100Perc timeline"
            cssTitle={css}
            content={ulActivities}
          />
    );
      }else{
        return <>Chargement...</>
      }
  }
/*
  toto() {
    if (this.props.parent.current != null) {
      this.props.parent.current.toto(this.props.tabIndex);
    }
  }*/

  extractActivities() {
    let result = [];
    let activity;
    let activities = [];
    let currentYear = null;
    for (let i = 0; i < this.state.activities.length; i++) {
      activity = this.state.activities[i];
      if(activity.roles.length>0){
      if (currentYear === null || currentYear !== activity.from) {
        if (currentYear !== activity.from) {
          result.push(
            <ActivityTimeLineElement
              key={"actTLE" + i +"-"+Math.random()}
              year={currentYear}
              page={this.props.page}
              displayType={true}
              sex={this.props.sex}
              activities={activities}
              geekies={this.props.geekies}
              icon="down"
              parent={this.props.parent}
              tabIndex={this.props.tabIndex}
              geekiLogin={this.props.geekiLogin}
              geekiType={this.props.geekiType}
              isInProduction={this.props.isInProduction}
            />
          );
        }
        activities = [];
        currentYear = activity.from;
      }
    }
      activities.push(activity);
    }
    result.push(
      <ActivityTimeLineElement
        key={"actTLE-A-" + Math.random()}
        year={currentYear}
        page={this.props.page}
        displayType={true}
        sex={this.props.sex}
        activities={activities}
        geekies={this.props.geekies}
        icon="up"
        parent={this.props.parent}
        tabIndex={this.props.tabIndex}
        geekiLogin={this.props.geekiLogin}
        geekiType={this.props.geekiType}
        isInProduction={this.props.isInProduction}
      />
    );

    return result;
  }
}
