import "./News.scss";

import React from "react";
import DbParseUtils from "../util/DbParseUtil2";
import SkeletonImage from "../image/SkeletonImage";
import { Link } from "react-router-dom";
import ModernComicsCell from "../comics/ModernComicsCell";
import { imgUrl } from "../../../shared/util/url-utils";
import { IGeeki } from "../../../shared/model/geeki.model";


export interface IProps {
  news: any;
  index: any;
  isTwitter: boolean;
  geekies: IGeeki[];
  isInProduction: boolean;
}

export default class NewsWidget extends React.Component<IProps> {

  render() {
    const title = DbParseUtils.formatDate(this.props.news.createdDate);
    const description = DbParseUtils.parseDescription2(
      this.props.news.description,
      this.props.isTwitter
    );

    let descriptionDiv=DbParseUtils.toDiv(description);

    

    const url = this.props.news.login==null?null:"/geeki/" + this.props.news.login;

    const urlDiv =
      url!=null && this.props.isTwitter && this.props.news.loginType ? (
        <div key={"autwidphSPURL" + Math.random()}>
          <br />
          <div key={"autwidphSPURLD" + Math.random()}>
            {"https://www.geeki.fr/#/" + url}
          </div>
        </div>
      ) : (
        ""
      );
    const photo = this.renderPhoto(url);

    let content = [];
    content.push(
      <span key={"autwidphSP" + Math.random()} className="newsItem-img">
        {photo}
      </span>
    );
    
    content.push(
      <span
        key={"autwidphSPSP" + Math.random()}
        className="modernCC-widget-content newsItem-content"
      >
        <div key={"autwidphSPSPD" + Math.random()} className="newsItem-title">
          {this.props.news.title}
          <span className="newsItem-title-date">{title}</span>
        </div>
        <span
          key={"autwidphSPSPDSP" + Math.random()}
          className="newsItem-description"
          dangerouslySetInnerHTML={{ __html: descriptionDiv }}
        >
        </span>
        {this.props.news.url && this.props.isTwitter && (
          <div key={"autwidphSPURL" + Math.random()}>
            Retrouvez le lien Amazon ici: https://amzn.to/{this.props.news.url}
          </div>
        )}
        {this.props.news.url && !this.props.isTwitter && (
          <div key={"autwidphSPURL" + Math.random()}>
            Retrouvez le lien Amazon {" "}
            <a href={"https://amzn.to/" + this.props.news.url} target="_new">
              ici
            </a>
          </div>
        )}
        {this.props.news.conventionId && (
          <div key={"autwidphSPURL" + Math.random()}>
            Retrouvez le lien vers la convention <Link to={"/convention/"+this.props.news.conventionId}>ici</Link>
          </div>
        )}
        {urlDiv}
      </span>
    );


    return (
      <ModernComicsCell
        key={"autwidphMCC" + Math.random()}
        content={content}
        cssContent="newsWidget"
      />
    );
  }

  renderPhoto(url){
    const alt = "test";
    const photoUrl = this.props.news.img
      ?imgUrl("/api/img/news/" + this.props.news.img,this.props.isInProduction)
      : (this.props.news.login
      ? imgUrl("/api/img/extgeeki/" + this.props.news.login,this.props.isInProduction)
      : imgUrl("/content/images/geekiplaylist.png",this.props.isInProduction));

      //      to={this.props.news.loginType ? url : "/"}
    return (<div
      key={"autwid" + Math.random()}
      className={"geekiImg"}
    >
      {url==null?<SkeletonImage
          key={"autwidskel" + Math.random()}
          className="boxedImage"
          src={photoUrl}
          alt={alt}
        />:<Link
        key={"autwidph" + Math.random()}
        to={url}
      >
        <SkeletonImage
          key={"autwidskel" + Math.random()}
          className="boxedImage"
          src={photoUrl}
          alt={alt}
        />
      </Link>}
      
    </div>);
  }
}