import React from "react";
import "./Comics.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

interface IProps {
  titleHeader: string;
  titles: any[];
  contents: any[];
}
interface IState {
  selectedIndex: number;
}

export default class ComicsTabs extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: 1 };
  }

  resetIndex() {
    this.setState({ selectedIndex: 0 });
  }

  setSelectedIndex(i) {
    this.setState({ selectedIndex: i });
  }

  onSelect(index: number, lastIndex: number, event: Event) {
    if (index !== lastIndex) {
      this.setState({ selectedIndex: index });
    }
    return true;
  }

  render() {
    let arrayTitles = [];
    let arrayContents = [];
    arrayTitles.push(
      <Tab
        key={"tabH"}
        className="react-tabs__tab tab titleHeader"
        selectedClassName="selectedTab"
      >
        {this.props.titleHeader}
      </Tab>
    );
    for (let i = 0; i < this.props.titles.length; i++) {
      arrayTitles.push(
        <Tab
          key={"tab" + i}
          className="react-tabs__tab tab"
          selectedClassName="selectedTab"
        >
          {this.props.titles[i]}
        </Tab>
      );
    }
    arrayContents.push(
      <TabPanel key={"tabpanelH"}>{this.props.contents[0]}</TabPanel>
    );
    for (let i = 0; i < this.props.contents.length; i++) {
      arrayContents.push(
        <TabPanel key={"tabpanel" + i}>{this.props.contents[i]}</TabPanel>
      );
    }
    return (
      <Tabs
        selectedIndex={this.state.selectedIndex}
        onSelect={(index, lastIndex, event) =>
          this.onSelect(index, lastIndex, event)
        }
      >
        <TabList className="tabList">{arrayTitles}</TabList>
        {arrayContents}
      </Tabs>
    );
  }
}
