import "../../custom/widget/comics/Comics.scss";
import "../home/home.scss";

import React from "react";
import { connect } from "react-redux";

import { IRootState } from "../../reducers";
import { logout } from "../../reducers/authentication";
import GeekiLayout from "../../shared/util/GeekiLayout";

export interface ILogoutProps extends StateProps, DispatchProps {
  idToken: string;
  logoutUrl: string;
}

export class Logout extends React.Component<ILogoutProps> {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const logoutUrl = this.props.logoutUrl;
    if (logoutUrl) {
      // if Keycloak, logoutUrl has protocol/openid-connect in it
      window.location.href =
        logoutUrl.indexOf("/protocol") > -1
          ? logoutUrl + "?redirect_uri=" + window.location.origin
          : logoutUrl +
            "?id_token_hint=" +
            this.props.idToken +
            "&post_logout_redirect_uri=" +
            window.location.origin;
    }
    const content = (
      <h4 className="geekiText">
        Vous avez été déconnecté avec succès!
      </h4>
    );
    return (<GeekiLayout title="Déconnexion" component={<div className="centered-out logout">
    <div className="centered-in">
      {content}
    </div>
  </div>}/>);
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
