import { connect } from "react-redux";
import { Route, Navigate, IndexRouteProps } from "react-router-dom";
import { IRootState } from "../../reducers";
import ErrorBoundary from "../error/ErrorBoundary";

interface IOwnProps extends IndexRouteProps {
  hasAnyAuthorities?: string[];
  location ?:any;
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {
  component ?: any
}

export const PrivateRouteComponent = ({
  component: Component,
  sessionHasBeenFetched,
  isAuthorized,
  hasAnyAuthorities = [],
  ...rest
}: IPrivateRouteProps) => {
  const checkAuthorities = props =>
    isAuthorized ? (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    ) : (
      <div className="insufficient-authority">
        <div className="alert alert-danger">Vous n'etes pas autorisé à accéder à cette page.</div>
      </div>
    );

    /*
    <Navigate
          to={{
            pathname: "/login",
            search: props.location.search,
            state: { from: props.location }
          }}
        />*/
  const renderRedirect = props => {
    if (!sessionHasBeenFetched) {
      return <div />;
    } else {
      return (1==1) ? (//TODO CST
        checkAuthorities(props)
      ) : (
        <Navigate
          to={{
            pathname: "/login",
            search: props.location.search
          }}
        />
      );
    }
  };

  if (!Component)
    throw new Error(
      `A component needs to be specified for private route for path ${
        (rest as any).path
      }`
    );

  return <Route {...rest} element={renderRedirect} />;
};

export const hasAnyAuthority = (
  authorities: string[],
  hasAnyAuthorities: string[]
) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

const mapStateToProps = (
  {
    authentication: { account, sessionHasBeenFetched }
  }: IRootState,
  { hasAnyAuthorities = [] }: IOwnProps
) => ({
  isAuthorized: hasAnyAuthority(account.authorities, hasAnyAuthorities),
  sessionHasBeenFetched
});

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export const PrivateRoute = connect<StateProps, undefined, IOwnProps>(
  mapStateToProps,
  null,
  null,
 // { pure: false }
)(PrivateRouteComponent);

export default PrivateRoute;
