import { Moment } from "moment";

export interface INews {
  id?: string;
  title?: string;
  description?: string;
  createdDate?: string;
  login?: string;
  loginType?: string;
  img?: string;
  youtube?: string;
  url?: string; 
  conventionId?: string;

}
export interface IAdminNews {
  status: string;
  entity: INews;
}

export const defaultValue: Readonly<INews> = {};
