import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRootState } from "../../../reducers";
import { getEntity } from "../../../reducers/link-stats.reducer";

export interface IProps extends StateProps, DispatchProps {}
type Props = IProps & WithRouterProps<IProps>;

export class LinkStatsDetail extends React.Component<Props> {
  componentDidMount() {
    (this.props as any).getEntity(this.props.match.params["id"]);
  }

  render() {
    const { linkStatsEntity } = (this.props as any);
    return (
      <Row>
        <Col md="8">
          <h2>LinkStats{" "}[<b>{linkStatsEntity.id}</b>]</h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="entityLogin">Identifiant entité</span>
            </dt>
            <dd>{linkStatsEntity.entityLogin}</dd>
            <dt>
              <span id="entityType">Type entité</span>
            </dt>
            <dd>{linkStatsEntity.entityType}</dd>
            <dt>
              <span id="page">Page</span>
            </dt>
            <dd>{linkStatsEntity.page}</dd>
            <dt>
              <span id="facebook">Facebook</span>
            </dt>
            <dd>{linkStatsEntity.facebook}</dd>
            <dt>
              <span id="twitter">X ex Twitter</span>
            </dt>
            <dd>{linkStatsEntity.twitter}</dd>
            <dt>
              <span id="wiki">Wiki</span>
            </dt>
            <dd>{linkStatsEntity.wiki}</dd>
            <dt>
              <span id="nolifewiki">Nolife Wiki</span>
            </dt>
            <dd>{linkStatsEntity.nolifeWiki}</dd>
            <dt>
              <span id="instagram">Instagram</span>
            </dt>
            <dd>{linkStatsEntity.instagram}</dd>
            <dt>
              <span id="dailymotion">DailyMotion</span>
            </dt>
            <dd>{linkStatsEntity.dailymotion}</dd>
            <dt>
              <span id="twitch">Twitch</span>
            </dt>
            <dd>{linkStatsEntity.twitch}</dd>
            <dt>
              <span id="pinterest">Pinterest</span>
            </dt>
            <dd>{linkStatsEntity.pinterest}</dd>
            <dt>
              <span id="linkedIn">LinkedIn</span>
            </dt>
            <dd>{linkStatsEntity.linkedIn}</dd>
            <dt>
              <span id="tipeee">Tipeee</span>
            </dt>
            <dd>{linkStatsEntity.tipeee}</dd>
            <dt>
              <span id="imdb">Imdb</span>
            </dt>
            <dd>{linkStatsEntity.imdb}</dd>

            <dt>
              <span id="tiktok">TikTok</span>
            </dt>
            <dd>{linkStatsEntity.tiktok}</dd>
            <dt>
              <span id="soundcloud">SoundCloud</span>
            </dt>
            <dt>
              <span id="podcloud">PodCloud</span>
            </dt>
            <dd>{linkStatsEntity.tiktok}</dd>
            <dt>
              <span id="discord">Discord</span>
            </dt>
            <dd>{linkStatsEntity.discord}</dd>
            <dt>
              <span id="youtube">Youtube</span>
            </dt>
            <dd>{linkStatsEntity.youtube}</dd>
          </dl>
          <Button tag={Link} to="/admin/link-stats" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{" "}
            <span className="d-none d-md-inline">Retour</span>
          </Button>
          &nbsp;
          <Button
            tag={Link}
            to={`/admin/link-stats/${linkStatsEntity.id}/edit`}
            replace
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" />{" "}
            <span className="d-none d-md-inline">Editer</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ linkStats }: IRootState) => ({
  linkStatsEntity: linkStats.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkStatsDetail));
