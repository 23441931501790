import moment from "moment";

import {
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_Z
} from "../../custom/config/constants";

export const convertDateTimeFromServer = date =>
  date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null;

export const convertDateTimeToServer = date =>
  date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null;




  export const initMonths = () => {
    let result = new Map();
    result.set("01","JAN");
    result.set("02","FEV");
    result.set("03","MAR");
    result.set("04","AVR");
    result.set("05","MAI");
    result.set("06","JUIN");
    result.set("07","JUIL");
    result.set("08","AOUT");
    result.set("09","SEPT");
    result.set("10","OCT");
    result.set("11","NOV");
    result.set("12","DEC");
    return result;
  }
  let months = initMonths();

export const monthTo3Letters= month =>{
  return months.get(month);
}
