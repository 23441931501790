import "./admin.scss";
import React from "react";
import { connect } from "react-redux";
import { dropColumnCsv } from "./../../reducers/admin-reducer";
import { IRootState } from "./../../reducers";
import { adminLayout } from "./util/admin-utils";
import { Button } from "reactstrap";

interface IProps extends StateProps, DispatchProps {
    dropColumnCsv: any;
    isInProduction:boolean;
}

class AdmCsv extends React.Component<IProps> {

  onClickDropColumnCsvHandler = e => {
    this.dropColumnCsv();
  };
  dropColumnCsv = async () => {
    const result = await this.props.dropColumnCsv();
    alert(result.value.data);
  };

  render() {
    return adminLayout("Liquibase",<div>
        <Button onClick={this.onClickDropColumnCsvHandler}>Supprimer colonne</Button>
  </div>,this.props.isInProduction);

  }
}

const mapStateToProps = ({ authentication,applicationProfile }: IRootState) => ({
  account: authentication.account,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
    dropColumnCsv
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmCsv);
