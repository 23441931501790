import React from "react";
import "../CryptoTable.css";
import { CryptoTableRows } from "../data/CryptoDataType";
import { Row, Col, Table } from "reactstrap";
import CryptoPiePerSymbol from "./CryptoPiePerSymbol";
import CryptoMultiBarPerSymbol from "./CryptoMultiBarPerSymbol";
import { TableBody } from "@mui/material";

interface ChildProps {
    rows: CryptoTableRows;
}

const CryptoPie: React.FC<ChildProps> = (props: ChildProps) => {
    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {props.rows && props.rows.items.length > 0 && <CryptoMultiBarPerSymbol rows={props.rows} />}
                    </Col>
                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {props.rows && props.rows.items.length > 0 && <CryptoPiePerSymbol rows={props.rows} />}
                    </Col>
                </Row>
                </TableBody>
            </Table>
        </span>);
};

export default CryptoPie;