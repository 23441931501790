import "../../../custom/widget/comics/Comics.scss";
import "../../home/home.scss";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PasswordStrengthBar from "../../../shared/layout/password/password-strength-bar";
import { IRootState } from "../../../reducers";
import { handleRegister, reset } from "../../../reducers/register.reducer";

export interface IRegisterProps extends StateProps, DispatchProps {}

export const RegisterPage = (props: IRegisterProps) => {
  const [password, setPassword] = useState("");

  //useEffect(() => () => props.reset(), []);
  //TODO CST investigate useEffect

  const handleValidSubmit = (event, values) => {
    props.handleRegister(
      values.username,
      values.email,
      values.firstPassword
    );
    event.preventDefault();
  };

  const updatePassword = event => setPassword(event.target.value);
  /*
  const form=<div className="width100Perc"><Form id="register-form" onValidSubmit={handleValidSubmit}>
  <Input
    name="username"
    label={translate('global.form.username.label')}
    placeholder={translate('global.form.username.placeholder')}
    validate={{
      required: { value: true, errorMessage: translate('register.messages.validate.login.required') },
      pattern: { value: '^[_.@A-Za-z0-9-]*$', errorMessage: translate('register.messages.validate.login.pattern') },
      minLength: { value: 1, errorMessage: translate('register.messages.validate.login.minlength') },
      maxLength: { value: 50, errorMessage: translate('register.messages.validate.login.maxlength') }
    }}
  />
  <Input
    name="email"
    label={translate('global.form.email.label')}
    placeholder={translate('global.form.email.placeholder')}
    type="email"
    validate={{
      required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
      minLength: { value: 5, errorMessage: translate('global.messages.validate.email.minlength') },
      maxLength: { value: 254, errorMessage: translate('global.messages.validate.email.maxlength') }
    }}
  />
  <Input
    name="firstPassword"
    label={translate('global.form.newpassword.label')}
    placeholder={translate('global.form.newpassword.placeholder')}
    type="password"
    onChange={updatePassword}
    validate={{
      required: { value: true, errorMessage: translate('global.messages.validate.newpassword.required') },
      minLength: { value: 4, errorMessage: translate('global.messages.validate.newpassword.minlength') },
      maxLength: { value: 50, errorMessage: translate('global.messages.validate.newpassword.maxlength') }
    }}
  />
  <PasswordStrengthBar password={password} />
  <Input
    name="secondPassword"
    label={translate('global.form.confirmpassword.label')}
    placeholder={translate('global.form.confirmpassword.placeholder')}
    type="password"
    validate={{
      required: { value: true, errorMessage: translate('global.messages.validate.confirmpassword.required') },
      minLength: { value: 4, errorMessage: translate('global.messages.validate.confirmpassword.minlength') },
      maxLength: { value: 50, errorMessage: translate('global.messages.validate.confirmpassword.maxlength') },
      match: { value: 'firstPassword', errorMessage: translate('global.messages.error.dontmatch') }
    }}
  />
    <ComicsButton title="Enregistrer"/>
    <InfoComicsCell speech="Après vous être enregistré, nous allons vous envoyer un mail pour valider votre inscription. Vérifiez vos mails (et vos courriers indésirables)."
                    style={ { width:"100%",height:"120px" } }/>

</Form></div>;
*/
  const form = "temporairement indisponible";
  return (
    <div className="centered-out register">
      <div className="centered-in">{form}</div>
    </div>
  );
};

const mapStateToProps = ({ }: IRootState) => ({
});

const mapDispatchToProps = { handleRegister, reset };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
