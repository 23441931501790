import "./admin.scss";
import React from "react";
import { connect } from "react-redux";
import { createMajor, createMinor } from "./../../reducers/admin-reducer";
import { IRootState } from "./../../reducers";
import { adminLayout } from "./util/admin-utils";
import { Button } from "reactstrap";

interface IProps extends StateProps, DispatchProps {
  createMajor: any;
  createMinor: any;
  isInProduction:boolean;
}

class AdmLiquibase extends React.Component<IProps> {

  onClickMajorHandler = e => {
    this.createMajor();
  };
  createMajor = async () => {
    const result = await this.props.createMajor();
    alert("Nouvelle version créée: " + result.value.data);
  };
  onClickMinorHandler = e => {
    this.createMinor();
  };
  createMinor = async () => {
    const result = await this.props.createMinor();
    alert("Nouvelle version créée: " + result.value.data);
  };

  render() {
    return adminLayout("Liquibase",<div>
        Création d'une version <Button onClick={this.onClickMajorHandler}>Major</Button> <Button onClick={this.onClickMinorHandler}>Minor</Button>
  </div>,this.props.isInProduction);

  }
}

const mapStateToProps = ({ authentication,applicationProfile }: IRootState) => ({
  account: authentication.account,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  createMajor,
  createMinor
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmLiquibase);
