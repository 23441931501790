import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { Row, Col, Alert } from "reactstrap";
import { getUrlParameter } from "../../../lib/url-utils";

import { IRootState } from "../../../reducers";
import { activateAction, reset } from "../../../reducers/activate.reducer";

const successAlert = (
  <Alert color="success">
    <strong>Votre compte a été activé.</strong>
    <Link to="/login" className="alert-link">
      Veuillez-vous connecter
    </Link>
    .
  </Alert>
);

const failureAlert = (
  <Alert color="danger">
    Votre utilisateur n'a pas pu etre activé. Veuillez utiliser le formulaire d'enregistrement pour vous inscrire.
  </Alert>
);

export interface IProps  extends StateProps, DispatchProps{ }
type Props = IProps & WithRouterProps<IProps>;

export const ActivatePage = (props: Props) => {
  useEffect(() => {
    const key = getUrlParameter("key", props.location.search);
    (props as any).activateAction(key);
    return () => {
      (props as any).reset();
    };
  }, [props]);

  const content = (
    <div className="width100Perc">
      <Row className="justify-content-center">
        <Col md="8">
          {(props as any).activationSuccess ? successAlert : undefined}
          {(props as any).activationFailure ? failureAlert : undefined}
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="centered-out">
      <div className="centered-in">{content}</div>
    </div>
  );
};

const mapStateToProps = ({ activate }: IRootState) => ({
  activationSuccess: activate.activationSuccess,
  activationFailure: activate.activationFailure
});

const mapDispatchToProps = { activateAction, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivatePage));
