import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { Table } from "reactstrap";

import { IRootState } from "../../../reducers";
import { getEntities } from "../../../reducers/link-stats.reducer";
import { adminLayout } from "../util/admin-utils";

export interface IProps extends StateProps, DispatchProps{
  isInProduction:boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class LinkStats extends React.Component<Props> {
  componentDidMount() {
    (this.props as any).getEntities();
  }

  render() {
    const { linkStatsList } = (this.props as any);

    return adminLayout("Statistiques Liens",linkStatsList && linkStatsList.length > 0 ? (
      <Table responsive aria-describedby="link-stats-heading">
        <thead>
          <tr>
            <th>ID lien</th>
            <th>ID entité</th>
            <th>Type entité</th>
            <th>Nom</th>
            <th>Page</th>
            <th>FB</th>
            <th>X ex Twitter</th>
            <th>Wiki</th>
            <th>Nolife Wiki</th>
            <th>Instagram</th>
            <th>DailyMotion</th>
            <th>Twitch</th>
            <th>Pinterest</th>
            <th>Linked In</th>
            <th>Tipeee</th>
            <th>Discord</th>
            <th>Youtube</th>
            <th>Imdb</th>
            <th>TikTok</th>
            <th>SoundCloud</th>
            <th>PodCloud</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {linkStatsList.map((linkStats, i) => (
            <tr key={`entity-${i}`}>
              <td>{linkStats.id}</td>
              <td>{linkStats.entityLogin}</td>
              <td>{linkStats.entityType}</td>
              <td>{linkStats.name}</td>
              <td>{linkStats.page}</td>
              <td>{linkStats.facebook}</td>
              <td>{linkStats.twitter}</td>
              <td>{linkStats.wiki}</td>
              <td>{linkStats.nolifeWiki}</td>
              <td>{linkStats.instagram}</td>
              <td>{linkStats.dailymotion}</td>
              <td>{linkStats.twitch}</td>
              <td>{linkStats.pinterest}</td>
              <td>{linkStats.linkedIn}</td>
              <td>{linkStats.tipeee}</td>
              <td>{linkStats.discord}</td>
              <td>{linkStats.youtube}</td>
              <td>{linkStats.imdb}</td>
              <td>{linkStats.tiktok}</td>
              <td>{linkStats.soundCloud}</td>
              <td>{linkStats.podCloud}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <div className="alert alert-warning">Aucun Link Stats</div>
    ),this.props.isInProduction);

  }
}

const mapStateToProps = ({ linkStats,applicationProfile }: IRootState) => ({
  linkStatsList: linkStats.entities,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkStats));
