import { IActivityRole } from "./activityRole.model";

export interface IActivity {
  id?: number;
  type?: string;
  title?: string;
  recompense?: string;
  guest?: string;
  editor?: string;
  episode?: string;
  youtube?: string;
  comment?: string;
  url?: string;
  img?: string;
  from?: string;
  roles?: IActivityRole[];
  geekiLogin?: string;

}



export interface INetwork {
  geekiLogin: string;
  skills?: string;
}


export const defaultValue: Readonly<IActivity> = {};
