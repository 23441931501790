import { IGeeki } from "./geeki.model";

export interface IProduct {
  id?: number;
  url?: string;
  category?: string;
  geekis?: IGeeki[];
}

export const defaultValue: Readonly<IProduct> = {};
