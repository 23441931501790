import React from "react";
import "./Comics.scss";
import IWithTooltip from "../icon/IWithTooltip";
import { Link } from "react-router-dom";
import { Share } from "react-twitter-widgets";

interface IProps {
  className?: string;
  content: any;
  title?: any;
  titleLink?: any;
  collapsable?: boolean;
  transparent?: boolean;
  share?: string;
}

interface IState {
  expanded: boolean;
}

export default class ComicsPage extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  renderTitle() {
    const link = this.props.titleLink ? (
      <Link
        key={
          "comPagT" +
          Math.floor(Math.random() * 999999) +
          "-" +
          Math.floor(Math.random() * 999999)
        }
        to={this.props.titleLink}
        aria-hidden="true"
        className="large"
      >
        {this.props.title}
      </Link>
    ) : (
      <span
        key={"comPagTA" + Math.random()}
        aria-hidden="true"
        className="comicsPage-titleNoLink large"
      >
        <i key="comPagTAI">{this.props.title}</i>
      </span>
    );
    return (
      <p key="comPagTAP" className="comicsPage-title text page-top top-left">
        {link}
      </p>
    );
  }

  render() {
    let css = "comicsPage";
    if (this.props.transparent) {
      css = css + " transparent";
    }
    if (this.props.className) {
      css = css + " " + this.props.className;
    }
    const iconCss = this.state.expanded
      ? "window minimize"
      : "window maximize outline";
    const expandTitle = this.state.expanded ? "Réduire" : "Agrandir";

    if (!this.state.expanded) {
      css = css + " collapsed";
    }
    const collapsable = (
      <p key="comPagTCol" className="text page-top top-right">
        <IWithTooltip
          key="comPagTColI"
          className={iconCss}
          title={expandTitle}
          onClick={this.toggleExpand}
        />
      </p>
    );

    return (
      <article className={css}>
        {this.props.content}
        {this.props.title && this.renderTitle()}
        {this.props.share && (
          <p
            key="comPagTP"
            className="comicsPage-title text page-top top-right"
          >
            <Share key="comPagTSha" url={this.props.share} />
          </p>
        )}
        {this.props.collapsable && collapsable}
      </article>
    );
  }
}
