import React from "react";
import wikiNoLifeFetch from "./nolifeWikiFetch";
import wikiFetch from "./wikiFetch";
import DbParseUtils from "../util/DbParseUtil2";
import Description from "./Description";
import ModernComicsCell from "../comics/ModernComicsCell";

interface IProps {
  className: string;
  entity: any;
  title?: string;
}

interface IState {
  loaded: boolean;
  description: any;
  descriptionAsString: string;
}

export default class WikiComicsCell extends React.Component< IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      description: "",
      descriptionAsString: ""
    };
  }

  componentDidMount() {
    if (this.props.entity) {
      if (this.props.entity.longDescription) {
        this.setState({
          loaded: true,
          description: DbParseUtils.parseDescription2(
            this.props.entity.longDescription,
            false
          ),
          descriptionAsString: this.props.entity.longDescription
        });
      } else if (this.props.entity.wiki) {
        wikiFetch(this.props.entity.wiki).then(vids => {
          const obj = vids.query.pages;
          const ob = Object.keys(obj)[0];
          let desc = obj[ob]["extract"];
          this.setState({
            loaded: true,
            description: desc,
            descriptionAsString: desc
          });
        });
      } else if (this.props.entity.nolifeWiki) {
        wikiNoLifeFetch(this.props.entity.nolifeWiki).then(vids => {
          const desc = DbParseUtils.parse(vids.parse.text["*"]);
          this.setState({
            loaded: true,
            description: desc,
            descriptionAsString: this.props.entity.nolifeWiki
          });
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.entity) {
      if (this.props.entity.longDescription) {
        if (this.state.descriptionAsString !== this.props.entity.longDescription) {
          this.setState({
            loaded: true,
            description: DbParseUtils.parseDescription(
              this.props.entity.longDescription,
              false
            ),
            descriptionAsString: this.props.entity.longDescription
          });
        }
      } else if (this.props.entity.wiki) {
        if (this.state.descriptionAsString !== this.props.entity.wiki) {
          wikiFetch(this.props.entity.wiki).then(vids => {
            if (vids) {
              const obj = vids.query.pages;
              const ob = Object.keys(obj)[0];
              const desc = obj[ob]["extract"];

              this.setState({
                loaded: true,
                description: desc,
                descriptionAsString: this.props.entity.wiki
              });
            }
          });
        }
      } else if (this.props.entity.nolifeWiki) {
        if (this.state.descriptionAsString !== this.props.entity.nolifeWiki) {
          wikiNoLifeFetch(this.props.entity.nolifeWiki).then(vids => {
            const desc = DbParseUtils.parse(vids.parse.text["*"]);
            this.setState({
              loaded: true,
              description: desc,
              descriptionAsString: this.props.entity.nolifeWiki
            });
          });
        }
      }
    }
  }

  render() {
    const css = "comicsCellTitle sign large icon";
    if (this.props.entity) {
      if (
        (!this.props.entity.longDescription ||
          this.props.entity.longDescription === "") &&
        (!this.props.entity.wiki || this.props.entity.wiki === "") &&
        (!this.props.entity.nolifeWiki || this.props.entity.nolifeWiki === "")
      ) {
        return (
          <ModernComicsCell
            inline="true"
            cssPanel="overflowHidden wiki"
            className={this.props.className}
            cssTitle={css}
            title={this.props.title}
            content={<Description wiki="Non disponible" />}
          />
        );
      }
    }
    
    return (
      <ModernComicsCell
        key="wikicomicscell"
        inline="true"
        className={this.props.className}
        title={this.props.title}
        cssContent="width100Perc"
        content={
          <span className="modernCC-widget-content width100Perc">
            <Description
              wiki={this.state.description}
              entity={this.props.entity}
            />
          </span>
        }
      />
    );
  }
}
