import "./Convention.scss";

import React from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { getEntities } from "../../reducers/convention.reducer";
import { connect } from "react-redux";
import { geekiCenteredLayout } from "../../shared/util/layout-util";
import ModernComicsCell from "../widget/comics/ModernComicsCell";
import ComicsButton from "../widget/comics/ComicsButton";
import { submitProposal } from "../../reducers/convention.reducer";
import { Label, Form, Input, Col, Row } from "reactstrap";
import { alertResult } from "../admin/util/admin-utils";

export interface IProps extends StateProps, DispatchProps {
  submitProposal: any;
}
type Props = IProps & WithRouterProps<IProps>;


class ConventionProposal extends React.Component<Props> {

  data;

  constructor(props) {
    super(props);
    this.state = {};
    this.data = {};
  }

  handleValidSubmit = (event) => {
    event.preventDefault();
    this.postData();
  }
  async postData() {
    const result = await this.props.submitProposal
      (this.data.country, this.data.postalCode, this.data.city, this.data.address
        , this.data.name, this.data.description
        , this.data.contactName, this.data.contactEmail);
    alertResult(result);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.data[name] = value;
  };


  render() {
    return geekiCenteredLayout("Proposition d'évènement", <ModernComicsCell
      key={"autwidphMCC" + Math.random()}
      content={this.renderContent()}
      className="convention-content"
      cssContent="newsWidget"
    />);
  }

  renderContent() {
    return (<>
    <Form onSubmit={this.handleValidSubmit}>
      <Row className="justify-content-center width100Perc">
        <Col md="12" className="geekiText">
        Vous avez une convention, un évènement à nous soumettre, veuillez remplir le formulaire et nous le ferons apparaitre sur Geeki:
      </Col>
      </Row>
      <Row>
        <Col md="4" > <Label id="countryLabel" for="country-title">
          Pays
        </Label>
        <Input
          id="conv-proposal-country"
          type="text"
          name="country"
          placeholder="Votre pays"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 50,
              errorMessage: "Le pays ne doit pas dépasser 50 caractères"
            }
          }}
        /></Col>
        <Col md="4" > <Label id="postalCodeLabel" for="postalCode-title">
          Code postal
        </Label>
        <Input
          id="conv-proposal-postalCode"
          type="text"
          name="postalCode"
          placeholder="Votre code postal"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 10,
              errorMessage: "Le code postal ne doit pas dépasser 10 caractères"
            }
          }}
        />
</Col>
        <Col md="4" ><Label id="cityLabel" for="city-title">
          Ville
        </Label>
        <Input
          id="conv-proposal-city"
          type="text"
          name="city"
          placeholder="Votre ville"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 100,
              errorMessage: "La ville ne doit pas dépasser 100 caractères"
            }
          }}
        /></Col>
        </Row>
        <Row>
        <Col md="12" ><Label id="addressLabel" for="address-title">
          Adresse
        </Label>
        <Input
          id="conv-proposal-address"
          type="text"
          name="address"
          placeholder="Votre adresse"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 200,
              errorMessage: "La ville ne doit pas dépasser 200 caractères"
            }
          }}
        /></Col>
        </Row>
        <Row>
        <Col md="12" ><Label id="nameLabel" for="name-title">
          Nom de l'évènement
        </Label>
        <Input
          id="conv-proposal-name"
          type="text"
          name="name"
          placeholder="Le nom de votre évènement"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 100,
              errorMessage: "Le nom de l'évènement ne doit pas dépasser 100 caractères"
            }
          }}
        /></Col>
        </Row>
        <Row>
        <Col md="12" ><Label id="descLabel" for="desc-title">
          Description de l'évènement
        </Label>
        <Input
          id="conv-proposal-desc"
          type="textarea"
          name="desc"
          placeholder="Le description de votre évènement"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 1000,
              errorMessage: "La descrition de l'évènement ne doit pas dépasser 1000 caractères"
            }
          }}
        /></Col>
        </Row>
        <Row>
        <Col md="6" ><Label id="nameContactLabel" for="nameContact-title">
          Nom de la personne de contact
        </Label>
        <Input
          id="conv-proposal-nameContact"
          type="text"
          name="nomducontact"
          placeholder="Le nom de la personne de contact"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 100,
              errorMessage: "Le nom de la personne de contact ne doit pas dépasser 100 caractères"
            }
          }}
        /></Col>
        <Col md="6" ><Label id="emailContactLabel" for="emailContact-title">
          Email de la personne de contact
        </Label>
        <Input
          id="conv-proposal-emailContact"
          type="text"
          name="emailducontact"
          placeholder="L'email de la personne de contact"
          onChange={this.handleChange}
          validate={{
            maxLength: {
              value: 100,
              errorMessage: "L'email de la personne de contact ne doit pas dépasser 100 caractères"
            }
          }}
        /></Col>
        </Row> 
        
        <Row>
        <Col md="4" ></Col>
        <Col md="4" ><br/><ComicsButton title="Envoyer" /></Col>
        <Col md="4" ></Col>
        </Row>           

      </Form>
    </>);
  }
}

const mapStateToProps = ({ convention }: IRootState) => ({
  conventionList: convention.entities
});

const mapDispatchToProps = {
  getEntities,
  submitProposal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConventionProposal));
