import "./LoadingBarWithRibbon.scss";

import LoadingBar from "react-redux-loading-bar";

export interface IProps {
  isAdmin: boolean;
  isGeeki: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
}

const LoadingBarWithRibbon = (props: IProps) => {

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <button>
          DEV
        </button>
      </div>
    ) : <div className="ribbon dev">
    <button>
      BETA
    </button>
  </div>;
  return (
    <>
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
    </>
  );
};

export default LoadingBarWithRibbon;
