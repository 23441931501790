import "../widget/comics/Comics.scss";
import "./Geeki.scss";

import React from "react";
import { geekiLabelWithNickName } from "../config/GeekiUtil";
import IWithTooltip from "../widget/icon/IWithTooltip";
import DbParseUtils from "../widget/util/DbParseUtil2";
import Skeleton from "react-loading-skeleton";
import SkeletonImage from "../widget/image/SkeletonImage";
import { imgUrl } from "../../shared/util/url-utils";
import ModernComicsCell from "../widget/comics/ModernComicsCell";

interface IProps {
  geeki: any;
  topright?: any;
  cssPanel?: string;
  bottomLeft?: any;
  bottomLeftClassName?: any;
  isInProduction: any;
}

export default class GeekiWidget extends React.Component<IProps> {
  render() {
    let diffDaysCreate;
    let diffDaysUpdate;
    let photo;
    let url;

    const title = geekiLabelWithNickName(this.props.geeki);

    if (!this.props.geeki) {
      photo = (
        <Skeleton
          height={100}
          width={100}
        />
      );
    } else {
      let photoHeight=(("AUTEUR"===this.props.geeki.geekiType)?181:120);
      let photoWidth=(("AUTEUR"===this.props.geeki.geekiType)?135:240);
      
      diffDaysCreate = DbParseUtils.stringToDateDiffFromNow(
        this.props.geeki.createdDate
      );
      diffDaysUpdate = DbParseUtils.stringToDateDiffFromNow(
        this.props.geeki.modifiedDate
      );
      const photoUrl = imgUrl("/api/img/extgeeki/" + this.props.geeki.login,this.props.isInProduction);
      photo = (
        <div className={"geekiImg "+this.props.geeki.geekiType}>
          <SkeletonImage
            key={"autwidskel" + this.props.geeki.login}
            src={photoUrl}
            height={photoHeight}
            width={photoWidth}
            className="geekiImg"
            alt={"Geekipédia auteur " + title}
          />
        </div>
      );
      url = "/geeki/" + this.props.geeki.login;
    }

    const cssP =
      "comicsCell geekiWidget " +
      (this.props.cssPanel ? this.props.cssPanel : "");
    let bottomLeft;
    if (this.props.bottomLeft) {
      bottomLeft = this.props.bottomLeft;
    } else if (this.props.geeki && this.props.geeki.validatedDate) {
      bottomLeft = (
        <IWithTooltip
          className="certified large thumbs up outline"
          title={"Validé par l'auteur le " + this.props.geeki.validatedDate}
        />
      );
    }

    let labelNew=null;
    if (diffDaysCreate && (diffDaysCreate <= 30 || diffDaysUpdate <= 30)) {
       labelNew =
        diffDaysCreate <= 30 ? (
          <span className="new">nouveau</span>
        ) : diffDaysUpdate <= 30 ? (
          <span className="update">mis à jour</span>
        ) : (
          ""
        );
    }
      return (
        <ModernComicsCell
          globalUrl={url}
          cssPanel={cssP}
          className="stretchChild geekiWidget"
          topright={this.props.topright}
          title={title}
          cssTitle="geekiWidget-title"
          content={photo}
          footer={labelNew}
          bottomLeft={bottomLeft}
          bottomLeftClassName={this.props.bottomLeftClassName}
        />
      );  
  }
}