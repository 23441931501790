import "./Activity.scss";
import 'semantic-ui-css/semantic.min.css'
import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Icon } from "semantic-ui-react";
import ActivityTimeLineElementScroller from "./ActivityTimeLineElementScroller";
import ActivityTimeLineElementCard from "./ActivityTimeLineElementCard";
import CommentBox from "../widget/comment/CommentBox";

export interface IProps {
  year: number;
  page: string;
  sex: string;
  activities: any[];
  geekies: any[];
  parent: any;
  tabIndex: number;
  displayType: boolean;
  icon: string;
  geekiLogin: string;
  geekiType: string;
  isInProduction: boolean;
}

export default class ActivityTimeLineElement extends React.Component<IProps> {
  geeki;

  render() {
    if (this.props.year && this.props.activities.length>0 && this.props.geekies.length>0) {

      
      let ulContent=this.extractActivities();

      return (
        <VerticalTimelineElement
          key={"BtleVtle" + Math.random()}
          className={
            "vertical-timeline-element--work" +
            (this.hasScroller() ? " withScroller" : "")
          }
          contentArrowStyle={{ borderRight: "7px solid  rgb(225,225,225)" }}
          date={this.initDateComponent()}
          iconStyle={{ background: "rgb(35, 94, 111)", color: "#fff" }}
          icon={<Icon color="orange">{this.props.year}</Icon>}
        >
          <span key={"bty" + this.props.year + Math.random()}>
            <span key={"btys" + this.props.year + Math.random()}>
              <ul key={"btysu" + this.props.year + Math.random()}>{ulContent}</ul>
            </span>
          </span>
        </VerticalTimelineElement>
      );
    } else {
      return "";
    }
  }

  extractActivities() {
    let result = [];
    let activity;
    let activities = [];
    for (let i = 0; i < this.props.activities.length; i++) {
      activity = this.props.activities[i];

      if (activities.length === 0 || activity.title === this.props.activities[0].title) {
        activities.push(activity);
      } else {
        result.push(
        <ActivityTimeLineElementCard            
        key={"biTiTlGrA-"+i+"-" + result.length + this.props.year + Math.random()}
        activities={activities}
        geekies={this.props.geekies}
        page={this.props.page}
        displayType={this.props.displayType}
        sex={this.props.sex}
        geekiLogin={this.props.geekiLogin}
        geekiType={this.props.geekiType}
      /> as any);
      activities = [];
      activities.push(activity);
      }
    }
    if (activities.length > 0) {
      result.push(
        <ActivityTimeLineElementCard
          key={"biTiTlGr-B-" + result.length + this.props.year + Math.random()}
          activities={activities}
          geekies={this.props.geekies}
          page={this.props.page}
          displayType={this.props.displayType}
          sex={this.props.sex}
          geekiLogin={this.props.geekiLogin}
          geekiType={this.props.geekiType}
        /> as any);
    }
    result.push(
      <CommentBox key={"biTiTlGr-C-" + result.length + this.props.year + Math.random()} cols={40} geekiLogin={this.props.geekiLogin} year={this.props.year}/>);


    return result;
  }

  initDateComponent() {
    return this.hasScroller() ? (
      <ActivityTimeLineElementScroller
        key={"BtleBtles" + this.props.year + Math.random()}
        activities={this.props.activities}
        geekiLogin={this.props.geekiLogin}
        geekies={this.props.geekies}
        isInProduction={this.props.isInProduction}
      />
    ) : (
      ""
    );
  }

  hasScroller() {
    let activity;
    for (let i = 0; i < this.props.activities.length; i++) {
      activity = this.props.activities[i];

      if (activity.img || activity.youtube) {
        return true;
      }
    }
    return false;
  }
}
