import React from "react";
import "../CryptoTable.css";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { CryptoTableRows, colors } from "../data/CryptoDataType";
import { Row, Col, Table } from "reactstrap";
import { calculBarChartData, cloneItems, groupBarChartData } from "./CryptoGraphUtils";
import { TableBody } from "@mui/material";
import { porttcfolio } from "../data/CryptoData";

interface ChildProps {
    rows: CryptoTableRows;
    tutu?: any;
}

let arcLabel: any = [];
let arcLabelTarget: any = [];
let arcLabelExchange: any = [];


const getArcLabel = (params: any) => {
    if (arcLabel != null && arcLabelTarget != null) {
        let TOTAL = 0;
        for (let item of arcLabel) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};
const getArcLabelTarget = (params: any) => {
    if (arcLabel != null && arcLabelTarget != null) {
        let TOTAL = 0;
        for (let item of arcLabelTarget) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};

const renderPie = (data: any, target: boolean) => {
    return <PieChart
        colors={colors}
        slotProps={{
            legend: {
                padding: 0,
            },
        }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontSize: '14px',
                fontWeight: 'bold'
            },
        }}
        series={[
            {
                outerRadius: 380,
                innerRadius: 30,
                paddingAngle: 1,
                cornerRadius: 5,
                arcLabelRadius: 370,
                arcLabel: (target ? getArcLabelTarget : getArcLabel),
                cx: 500,
                data
            },
        ]}

        height={800}
    />;
}

const CryptoPiePerSymbol: React.FC<ChildProps> = (props: ChildProps) => {
    let rows = { items: [] };
    cloneItems(props,rows);
   

    let data: any = [];
    arcLabel = [];
    let dataTarget: any = [];
    arcLabelTarget = [];
    let totalTarget = 0;
    let groupedData: any = [];
    let groupedDataTarget: any = [];
    let dataExchange: any = [];
    arcLabelExchange = [];
    let groupedDataExchange: any = [];
    let sortedItemsExchange =[];
    if (rows.items) {
        calculBarChartData(arcLabel, arcLabelTarget,arcLabelExchange, rows
            , data, dataTarget,dataExchange);
        groupBarChartData(data, dataTarget,dataExchange
            ,groupedData,groupedDataTarget,groupedDataExchange);

        for (let item of groupedData) {
            item.label = item.label2 + ' $' + item.value.toFixed(0)
        }
        for (let item of groupedDataExchange) {
            item.label = item.label2.toUpperCase() + ' $' + item.value.toFixed(0)
        }
        for (let item of groupedDataTarget) {
            totalTarget += item.maxTarget;
            item.value = item.maxTarget;
            item.label = item.label2 + ' $' + item.value.toFixed(0)
        }

        sortedItemsExchange = groupedDataExchange.sort((a:any, b:any) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });
    }

    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                        Portefeuille actuel: ${porttcfolio.currentTotal.toFixed(0)} USDT
                    </Col>

                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {renderPie(groupedData, false)}
                    </Col>
                </Row>

                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                        Portefeuille actuel par exchange: ${porttcfolio.currentTotal.toFixed(0)} USDT
                    </Col>

                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {renderPie(sortedItemsExchange, false)}
                    </Col>
                </Row>

                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                    Portefeuille Bullrun: ${totalTarget.toFixed(0)} USDT
                    </Col>

                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {renderPie(groupedDataTarget, true)}
                    </Col>
                </Row>
                </TableBody>
            </Table></span>);
};

export default CryptoPiePerSymbol;