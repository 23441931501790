import { routeUrl } from "../../../shared/util/url-utils";
import DbParseUtils2Mappings from "./DbParseUtils2Mappings";
export default class DbParseUtils2 {
    

static parseDescription2(desc, isTwitter) {
        let result=[];   
        if(desc!=null){
          const regex = /<p>(.*?)<\/p>/g;        
          const paragraphs = desc.match(regex);
          if(paragraphs !=null){
            for (let i = 0; i < paragraphs.length; i++) {
              const paragraph = this.parseParagraph(paragraphs[i], i, isTwitter);
              result.push(paragraph);
            }
          }
        }
        return result;
}
    

static parseDescription(desc, isTwitter) {
    const newDiv = document.createElement('div');

    const regex = /<p>(.*?)<\/p>/g;
    const paragraphs = desc.match(regex);
    for (let i = 0; i < paragraphs.length; i++) {
        const paragraph = this.parseParagraph(paragraphs[i], i, isTwitter);
        newDiv.appendChild(paragraph as any);
    }
    return newDiv;
  }

  static parseParagraph(p, idx, isTwitter) {
    let paragraph = p.replace("<p>", "").replace("</p>", "");
    const content = [];

    const regex = /<ul>(.*?)<\/ul>/g;        
          const uls = paragraph.match(regex);
          
          if(uls !=null){
            for (let i = 0; i < uls.length; i++) {
              paragraph=paragraph.replace(uls[i],'');
              const ul = this.parseUl(uls[i], i, isTwitter);
              content.push(ul);
            }
          }
    

    if (paragraph.indexOf("<Link") > -1) {
      const parts = paragraph.split("<Link");
      this.parseLinks(parts, content, isTwitter);
    } else {
      content.push(paragraph);
    }

    if (content.length === 0) {
      return "";
    } else {
      let brs=this.parseBR(content);
      
      const newP = document.createElement('p'); 
      for(let i=0;i<brs.length;i++){        
        newP.appendChild(brs[i]);
      }
      return newP;
    }
  }

  static parseUl(p, idx, isTwitter) {
    const paragraph = p.replace("<ul>", "").replace("</ul>", "");
    
    const newUl = document.createElement('ul'); 

    const regex = /<li>(.*?)<\/li>/g;        
          const lis = paragraph.match(regex);
          const result=[];
          if(lis !=null){
            for (let i = 0; i < lis.length; i++) {
              const li = this.parseLi(lis[i], i, isTwitter);
              result.push(li);
            }
            for(let j=0;j<result.length;j++){
              newUl.appendChild(result[j]);

            }
          }
    return newUl;    
  }

  static parseLi(p, idx, isTwitter) {
    const paragraph = p.replace("<li>", "").replace("</li>", "");
    const content = [];

   

    if (paragraph.indexOf("<Link") > -1) {
      const parts = paragraph.split("<Link");
      this.parseLinks(parts, content, isTwitter);
    } else {
      content.push(paragraph);
    }

    if (content.length === 0) {
      return "";
    } else {
      let brs=this.parseBR(content);
      
      const newP = document.createElement('li'); 
      for(let i=0;i<brs.length;i++){        
        newP.appendChild(brs[i]);
      }
      return newP;
    }
  }

  static parse(data: string) {
   // const parts = data.split("</table>");
    return "TODO CST";
   /* if (parts.length === 1) {
      return DbParseUtils.parseNolifeWikiRemoveLinks(parts[0]);
    } else if (parts[0].indexOf("<table ") > 0) {
      return DbParseUtils.parseNolifeWikiRemoveLinks(
        parts[0].split("<table ")[0] + parts[1]
      );
    } else {
      return DbParseUtils.parseNolifeWikiRemoveLinks(parts[1]);
    }*/
  }

  static parseLinks(parts, content, isTwitter) {
    if (parts.length > 0) {
      if (parts[0].indexOf("</Link>") > -1) {
        this.processLink(parts[0], content, isTwitter);
        parts.splice(0, 1);
        this.parseLinks(parts, content, isTwitter);
      } else {
        content.push(parts[0]);
        parts.splice(0, 1);
        this.parseLinks(parts, content, isTwitter);
      }
    }
  }

  static processLink(str, content, isTwitter) {
    const strArray = str.split("</Link>");
    const data = strArray[0].split(">");
    const label = data[1];
    if (isTwitter) {
      const stTW = data[0].split('twitter="');
      const twitterSpan = document.createElement('span');
      if (stTW.length > 1) {
        twitterSpan.innerHTML='@'+stTW[1].split('"')[0];
      } else {
        twitterSpan.innerHTML=label;
      }
      content.push(twitterSpan);
    } else {
      const stTO = data[0].split('to="');
      if (stTO.length > 1) {
        const href = routeUrl(stTO[1].split('"')[0]);
        let link=document.createElement('a');
        link.className="link";
        link.setAttribute("href",href);
        link.setAttribute("key","lnk" +
        Math.floor(Math.random() * 999999) +
        "-" +
        Math.floor(Math.random() * 999999));
        link.innerHTML=label;
        content.push(link);
      } else {
        const labelSpan = document.createElement('span');
        labelSpan.innerHTML=label;
        content.push(labelSpan);
      }
    }

    if (strArray.length > 1 && strArray[1] !== "") {
        const labelSpan = document.createElement('span');
        labelSpan.innerHTML=strArray[1];
        content.push(labelSpan);
    }
  }

  static parseBR(content) {
    const result = [];
    let splitted;
    for (let i = 0; i < content.length; i++) {
      if (typeof content[i] === "string") {
        splitted = content[i].split("<br/>");
        let myVar=document.createElement('span');
        myVar.innerHTML=splitted[0];
        result.push(myVar);
        for (let j = 1; j < splitted.length; j++) {
            result.push(document.createElement('br'));
            myVar=document.createElement('span');
            myVar.innerHTML=splitted[j];
            result.push(myVar);
        }
      }else if (typeof content[i] === "object") {
        result.push(content[i]);
      } else {
        let myVar=document.createElement('span');
        myVar.innerHTML=content[i];
        result.push(myVar);
      }
    }

    return result;
  }

  static formatDate(d) {
    if (d) {
      const splitted = d.split("-");
      return (
        splitted[2] +
        " " +
        DbParseUtils2Mappings.monthCodeToLabel(splitted[1]) +
        " " +
        splitted[0]
      );
    }
    return "erreur";
  }

  static doInnerHTML(data : any[],tempDiv: any){
    if(tempDiv==null){
      tempDiv = document.createElement('div');
    }
    for(let i=0;i<data.length;i++){
      tempDiv.appendChild(data[i]);
    } 
    return tempDiv.innerHTML;
  }

  static toDiv(description: any){
    let descriptionDiv;
        if (typeof description === "string") {
          descriptionDiv = (
            <div
              key={"n2w" + Math.random()}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          );
        } else if (typeof description === "object") {
          let tempDiv = document.createElement('div');
          for(let i=0;i<description.length;i++){
            tempDiv.appendChild(description[i]);
          } 
          descriptionDiv = this.doInnerHTML(description,null);
        } else {
          descriptionDiv = description;
        }
        return descriptionDiv;
  }

  static stringToDateDiffFromNow(myStrDate) {
    if (myStrDate) {
      let date1 = new Date();
      let dateArr = myStrDate.split("-");
      let date2 = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      let diffTime = Math.abs(date2.getTime() - date1.getTime());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    } else {
      return -1;
    }
  }

}