import axios from "axios";
import { Exchange } from "../../data/CryptoDataType";

const geekiExchangesRequest = async (exchanges:Exchange[]) => {
    try {
        const response = await axios.post('https://www.geeki.fr/api/crypto/accounts', {exchanges:exchanges}, {
          withCredentials: false,
          headers: {
            'Accepts': 'application/json',
            'Access-Control-Allow-Credentials': true,
            "Access-Control-Allow-Origin": "*",
            'Access-Control-Allow-Headers': 'Origin'
          }
        });        
        return response;
      } catch (error) {
        console.error('Erreur lors de l\'appel API:', error);
      }
  }

  export default geekiExchangesRequest;