import "./Sponsor.scss";

import React from "react";
import { withRouter, WithRouterProps } from '../../shared/util/withRouter';
import { IRootState } from "../../reducers";
import { getRandomEntity } from "../../reducers/sponsor.reducer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { imgUrl } from "../../shared/util/url-utils";
import ModernComicsCell from "../widget/comics/ModernComicsCell";

export interface IProps extends StateProps, DispatchProps{
  isInProduction: any
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
    sponsor?: any;
}
class Sponsor extends React.Component<Props, IState> {

    constructor(props) {
        super(props);
        this.state = {};
      }
    
      componentDidMount() {
        this.getSponsors();
      }
    
      getSponsors = async () => {
        const sponsor: any = await (this.props as any).getRandomEntity();
        this.setState({
            sponsor: sponsor.value.data
        });
      };

    render() {
        if (this.state.sponsor){
        let sponsor=this.state.sponsor;
        const sponsorUrl = imgUrl("/api/img/sponsor/" + sponsor.id,this.props.isInProduction);

        return (<ModernComicsCell
                    className="presentation"
                    cssContent="width100Perc"
                    content={<span className="modernCC-widget-content">
                        <Link to={sponsor.link} target="_blank">
    <img src={sponsorUrl} alt={sponsor.name}
        className="sponsorBoxedImage"
    style={{maxHeight:"300px"}}/>Vous pouvez nous soutenir en suivant ce lien.
                        </Link></span>}
        />);
        }else{
            return "Chargement..."
        }
    }
}

const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
  });
  
  const mapDispatchToProps = {
    getRandomEntity
  };
  
  type StateProps = ReturnType<typeof mapStateToProps>;
  type DispatchProps = typeof mapDispatchToProps;
  
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sponsor));
