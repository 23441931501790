import React from "react";
import GeekiWithSkillsWidget from "../geeki/GeekiWithSkillsWidget";
import ModernComicsCell from "../widget/comics/ModernComicsCell";
import { INetwork } from "../../shared/model/activity.model";

interface IProps {
  activities: any[];
  network: INetwork[];
  inline?: string;
  cssPanel: string;
  className?: string;
  title?: string;
  geeki: any;
  geekies: any[];
  isInProduction:boolean;
}

export default class GeekisComicsCell extends React.Component<IProps> {
  containsLogin(geekisLoginsArray, login) {
    for (let geekiLogin of geekisLoginsArray) {
      if (geekiLogin === login) {
        return true;
      }
    }
    return false;
  }

  render() {
    const geekisArray = [];
    const geekisLoginsArray = [];
    if (this.props.activities) {
      let geekies=this.extractGeekies();
      for (const geeki of geekies.values()) {
        this.processNextGeeki(geeki, geekisLoginsArray, geekisArray);
      }
      
      return (
        <ModernComicsCell
              className={this.props.className}
              inline={this.props.inline}
              cssPanel={this.props.cssPanel}
              cssTitle="sign large icon"
              content={geekisArray}
              cssContent="stretchParentWrap"
            />
      );

    } else {
      return "";
    }

    
  }

extractGeekies(){
  let result=new Map();
  for(let activity of this.props.activities){
    for(let activityRole of activity.roles){
      if(activityRole.geekiLogin!==this.props.geeki.login){
        for(let geeki of this.props.geekies){
          if(geeki.login ===activityRole.geekiLogin ){
            geeki.skills=activityRole.skills;
            result.set(activityRole.geekiLogin,geeki);
            break;
          }
        }
      }
    }
  }
 
  for(let networkItem of this.props.network){
    for(let geeki of this.props.geekies){
      if(geeki.login ===networkItem.geekiLogin ){
        geeki.skills=networkItem.skills;
        result.set(networkItem.geekiLogin,geeki);
        break;
      }
    }
  }
  return result;
}

  processNextGeeki(geeki, geekisLoginsArray, geekisArray) {
    const skills = geeki.skills;
    if (!this.containsLogin(geekisLoginsArray, geeki.login)) {
      geekisLoginsArray.push(geeki.login);
      geekisArray.push(
        <GeekiWithSkillsWidget
          geeki={geeki}
          key={"awsw" + geeki.login}
          skills={skills}
          sex={this.props.geeki.sex}
          isInProduction={this.props.isInProduction}
        />
      );
    }
  }
}
