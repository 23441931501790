import axios from "axios";
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from "../lib/redux-action.type";

import { cleanEntity } from "../shared/util/entity-utils";
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";

import { ILinkStats, defaultValue } from "../shared/model/link-stats.model";

export const ACTION_TYPES = {
  FETCH_LINKSTATS_LIST: "linkStats/FETCH_LINKSTATS_LIST",
  FETCH_LINKSTATS: "linkStats/FETCH_LINKSTATS",
  CREATE_LINKSTATS: "linkStats/CREATE_LINKSTATS",
  UPDATE_LINKSTATS: "linkStats/UPDATE_LINKSTATS",
  DELETE_LINKSTATS: "linkStats/DELETE_LINKSTATS",
  RESET: "linkStats/RESET"
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ILinkStats>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false
};

export type LinkStatsState = Readonly<typeof initialState>;

const linkStatsState =  (
  state: LinkStatsState = initialState,
  action
): LinkStatsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_LINKSTATS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_LINKSTATS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_LINKSTATS):
    case REQUEST(ACTION_TYPES.UPDATE_LINKSTATS):
    case REQUEST(ACTION_TYPES.DELETE_LINKSTATS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_LINKSTATS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_LINKSTATS):
    case FAILURE(ACTION_TYPES.CREATE_LINKSTATS):
    case FAILURE(ACTION_TYPES.UPDATE_LINKSTATS):
    case FAILURE(ACTION_TYPES.DELETE_LINKSTATS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_LINKSTATS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_LINKSTATS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_LINKSTATS):
    case SUCCESS(ACTION_TYPES.UPDATE_LINKSTATS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_LINKSTATS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default linkStatsState;

const apiUrl = "api/link-stats";

// Actions

export const getEntities: ICrudGetAllAction<ILinkStats> = (
  page,
  size,
  sort
) => ({
  type: ACTION_TYPES.FETCH_LINKSTATS_LIST,
  payload: axios.get<ILinkStats>(
    `${apiUrl}?cacheBuster=${new Date().getTime()}`
  )
});

export const getEntity: ICrudGetAction<ILinkStats> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_LINKSTATS,
    payload: axios.get<ILinkStats>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<
  ILinkStats
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_LINKSTATS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<
  ILinkStats
> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_LINKSTATS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<
  ILinkStats
> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_LINKSTATS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
