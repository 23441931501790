export default class DbParseUtils2Mappings {

    static skills = DbParseUtils2Mappings.initSkills();
    static productCategories = DbParseUtils2Mappings.initProductCategories();
    static activityTypes = DbParseUtils2Mappings.initActivityTypes();
    static geekiTypes= DbParseUtils2Mappings.initGeekiTypes();
  
    render(){
      //workaround, else: instance.render is not a function
    }

    static initSkills() {
        let result = new Map();
        result.set("ADMINISTRATEUR", ["Administrateur", "Adminitratrice"]);
        result.set("ANIMATEUR", ["Animateur", "Animatrice"]);
        result.set("AUTEUR", ["Auteur", "Auteure"]);
        result.set("CHANTEUR", ["Chanteur", "Chanteuse"]);
        result.set("CADREUR", ["Cadreur", "Cadreuse"]);
        result.set("CHRONIQUEUR", ["Chroniqueur", "Chroniqueuse"]);
        result.set("COLLABORATEUR", ["Collaborateur", "Collaboratrice"]);
        result.set("COLORISTE", ["Coloriste"]);
        result.set("COMEDIEN", ["Comédien", "Comédienne"]);
        result.set("COMMENTATEUR", ["Commentateur", "Commentatrice"]);
        result.set("COMPOSITEUR", ["Compositeur", "Compositrice"]);
        result.set("CONCEPTARTIST", ["Concept artist"]);
        result.set("CONFERENCIER", ["Conférencier", "Conférencière"]);
        result.set("COORGANISATEUR", ["Co-organisateur", "Co-organisatrice"]);
        result.set("CREATEUR", ["Créateur", "Créatrice"]);
        result.set("CROSSOVER", ["Crossover"]);
        result.set("CUISINIER", ["Cuisinier", "Cuisinière"]);
        result.set("DECOR", ["Décors"]);
        result.set("DESSINATEUR", ["Dessinateur", "Dessinatrice"]);
        result.set("DEVELOPPEUR", ["Développeur", "Développeuse"]);
        result.set("DIFFUSEUR", ["Diffuseur"]);
        result.set("DIRECTEUR", ["Directeur", "Directrice"]);
        result.set("DIRECTEURARTISTIQUE", [
          "Directeur artistique",
          "Directrice artistique"
        ]);
        result.set("DIRECTEURCOLLECTION", [
          "Directeur de collection",
          "Directrice de collection"
        ]);
        result.set("DIRECTEURCOMMUNICATION", [
          "Directeur de communication",
          "Directrice de communication"
        ]);
        result.set("DIRECTEURTECHNIQUE", [
          "Directeur technique",
          "Directrice technique"
        ]);
        result.set("DOUBLEUR", ["Comédien de doublage", "Comédienne de doublage"]);
        result.set("EDITEUR", ["Editeur", "Editrice"]);
        result.set("ECRIVAIN", ["Ecrivain", "Ecrivaine"]);
        result.set("ENTREPRENEUR", ["Entrepreneur", "Entrepreneuse"]);
        result.set("FIGURANT", ["Figurant", "Figurante"]);
        result.set("FONDATEUR", ["Fondateur", "Fondatrice"]);
        result.set("FRERE", ["Frère", "Soeur"]);
        result.set("GAMEDESIGNER", ["Game Designer"]);
        result.set("HEADOFCONTENT", ["Head of Content", "Head of Content"]);
        result.set("HUMORISTE", ["Humoriste"]);
        result.set("ILLUSTRATEUR", ["Illustrateur", "Illustratrice"]);
        result.set("INFOGRAPHISTE", ["Infographiste"]);
        result.set("INVITE", ["Invité", "Invitée"]);
        result.set("GRAPHISTE", ["Graphiste"]);
        result.set("JOUEUR", ["Joueur", "Joueuse"]);
        result.set("JOURNALISTE", ["Journaliste"]);
        result.set("MARIE", ["Marié", "Mariée"]);
        result.set("MONTEUR", ["Monteur", "Monteuse"]);
        result.set("ORGANISATEUR", ["Organisateur", "Organisatrice"]);
        result.set("OTHER", "Autre");
        result.set("PAROLIER", ["Parolier", "Parolière"]);
        result.set("PHOTOGRAPHE", ["Photographe"]);
        result.set("PODCASTEUR", ["Podcasteur", "Podcasteuse"]);
        result.set("PRESENTATEUR", ["Présentateur", "Présentatrice"]);
        result.set("PRODUCTEUR", ["Producteur", "Productrice"]);
        result.set("RAPPEUR", ["Rappeur", "Rappeuse"]);
        result.set("REALISATEUR", ["Réalisateur", "Réalisatrice"]);
        result.set("REDACTEUR", ["Rédacteur", "Rédactrice"]);
        result.set("REDACTEURENCHEF", ["Rédacteur en chef", "Rédactrice en chef"]);
        result.set("SCENARISTE", ["Scénariste"]);
        result.set("STORYBOARDER", ["Storyboardeur", "Storyboardeuse"]);
        result.set("TRADUCTEUR", ["Traducteur", "Traductrice"]);
        result.set("YOUTUBEUR", ["Youtubeur", "Youtubeuse"]);
        return result;
      }
      static initProductCategories() {
        let categories = [];
        categories.push("BD");
        categories.push("BOOK");
        categories.push("ESSAI");
        categories.push("DVD");
        categories.push("SERIE");
        categories.push("JEU");
        categories.push("GAME");
        categories.push("LIVRE");
        categories.push("LIVREJEU");
        categories.push("MAGAZINE");
        categories.push("MUSIQUE");
        categories.push("NOUVELLE");
        categories.push("PUBLICITE");
        categories.push("ROMAN");
        categories.push("RECOMPENSE");
        categories.push("VIDEOGAME");
        categories.push("OTHER");
        return categories;
      }
    
      static initActivityTypes() {
        let result = new Map();
        result.set("ASSOCIATION", "Association");
        result.set("BD", "Bandes dessinées");
        result.set("BIRTH", "Naissance");
        result.set("BOOK", "Livre");
        result.set("CLIP", "Clip");
        result.set("COURTMETRAGE", "Court Métrage");
        result.set("CREATION", "Création");
        result.set("ESSAI", "Essai");
        result.set("EMISSION", "Emission");
        result.set("FAMILLE", "Famille");
        result.set("FESTIVAL", "Festival");
        result.set("FILM", "Film");
        result.set("JEU", "Jeu");
        result.set("JEUVIDEO", "Jeu vidéo");
        result.set("GAME", "Jeu");
        result.set("LIVRE", "Livre");
        result.set("LIVREJEU", "Livre Jeu");
        result.set("MAGAZINE", "Magazine");
        result.set("MUSIQUE", "Musique");
        result.set("NOUVELLE", "Nouvelles");
        result.set("PUBLICITE", "Publicité");
        result.set("RADIO", "Radio");
        result.set("ROMAN", "Roman");
        result.set("RECOMPENSE", "Récompenses");
        result.set("SALON", "Salon");
        result.set("SERIE", "Série");
        result.set("SOCIETE", "Société");
        result.set("TV", "Télévision");
        result.set("VIDEOGAME", "Jeu vidéo");
        result.set("WEBZINE", "Webzine");
    
        return result;
      }

      static monthCodeToLabel(code) {
        if ("01" === code) {
          return "Janvier";
        } else if ("02" === code) {
          return "Février";
        } else if ("03" === code) {
          return "Mars";
        } else if ("04" === code) {
          return "Avril";
        } else if ("05" === code) {
          return "Mai";
        } else if ("06" === code) {
          return "Juin";
        } else if ("07" === code) {
          return "Juillet";
        } else if ("08" === code) {
          return "Aout";
        } else if ("09" === code) {
          return "Septembre";
        } else if ("10" === code) {
          return "Octobre";
        } else if ("11" === code) {
          return "Novembre";
        } else if ("12" === code) {
          return "Décembre";
        } else {
          return code;
        }
      }

      static initGeekiTypes(){
        let result = new Map();
        result.set("AUTEUR", "Auteur");
        result.set("COLLECTIF", "Collectif");
        result.set("OEUVRE", "Oeuvre");
        result.set("EVENEMENT", "Evènement");
        return result;     
      }

      static translateSkills(s, sex, separator) {
        if(s==null || s===undefined){
          return '';
        }
        let skills = s.split(separator);
        let result = "";
        for (let i = 0; i < skills.length; i++) {
          if (result !== "") {
            result = result + ", ";
          }
          result = result + DbParseUtils2Mappings.translateSkill(skills[i], sex);
        }
        return result;
      }

      static translateSkill(s, sex) {
        let trad = DbParseUtils2Mappings.skills.get(s);
        return trad === undefined ? s : !sex || sex === "M" ? trad[0] : trad[1];
      }

      static translateActivity(s) {
        let result = DbParseUtils2Mappings.activityTypes.get(s);
        return result === undefined ? s : result;
      }
    
}