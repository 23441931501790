import "../../app.scss";

import React from "react";
import ModernComicsCell from "../../custom/widget/comics/ModernComicsCell";
import GeekiLayout from "../util/GeekiLayout";

class PageNotFound extends React.Component {
  render() {
    const content = (
      <ModernComicsCell
        className="modernCC-widget pageNotFound"
        title="Page inconnue"
        content={
          <span className="modernCC-widget-content">
            {
              <h2 className="geekiText">
                <p>La page recherchée n'existe pas</p>
              </h2>
            }
          </span>
        }
      />
    );
    return (<GeekiLayout title="Page non trouvée" component={<div className="centered-out about">
    <div className="centered-in">{content}</div>
  </div>}/>);
  }
}

export default PageNotFound;
