import GeekiCarouselPanel from "../../../custom/carousel/GeekiCarouselPanel";
import React from "react";
import PlaylistsWidget from "./PlaylistsWidget";

interface IProps {
  items: any;
  className?: string;
  isInProduction:boolean;
}

export default class PlaylistCarouselPanel extends React.Component<IProps> {
  render() {
   /* return (
      <GeekiCarouselPanel
        title="Vidéos"
        className={this.props.className?this.props.className:"width100Perc carouselBg"}
        inline="true"
        cssPanel="width100Perc overflowHidden"
        content={
          <span className="modernCC-widget-content" key={"plcapaspa"}>
            {
              <PlaylistsWidget
                key={"plcapa"}
                playlists={this.props.items}
                className="stretchparent"
                transparent={true}
              />
            }
          </span>
        }
      />
    );*/
    return (<PlaylistsWidget
      key={"plcapa"}
      playlists={this.props.items}
      isInProduction={this.props.isInProduction}
      className="stretchparent"
      transparent={true}
    />);
  }
}
