import React, { createRef } from "react";
import GeekiVideoSelector from "./GeekiVideoSelector";
import GeekiPlayer from "./GeekiPlayer";

import { ScrollMenu } from "react-horizontal-scrolling-menu";
import IWithTooltip from "../icon/IWithTooltip";

interface IProps {
  videoList?: any;
  expanded: boolean;
  loadFirst: boolean;
  closeButton: any;
  isInProduction:boolean;
}

interface IState {
  videos: any[];
  menu: any;
}

export default class GeekiPlaylistScroller2 extends React.Component<IProps,IState> {
  player;
  videoId;
  carRef;
  scrollMenuRef;

  constructor(props) {
    super(props);
    this.carRef = React.createRef();
    this.scrollMenuRef  = createRef();//= React.createRef();
    this.state = {
      videos: [],
      menu: undefined
    };
  }

  componentDidMount() {
    const width =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 240 : 320) : 640;
    const height =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 145 : 195) : 390;
    const selectorWidth =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 160 : 240) : 320;
    const selectorHeight =
      window.innerWidth <= 481 ? (window.innerWidth < 350 ? 97 : 145) : 195;

    const vids = [];
    const videoList = this.props.videoList;
    let player = (
      <GeekiPlayer
        h={height}
        w={width}
        carRef={this.carRef}
        ref={this.carRef}
      />
    );
    if (videoList !== undefined && videoList.length > 0) {
      let video;

      for (let i = 0; i < videoList.length; i++) {
        video = videoList[i];

        let selector = (
          <GeekiVideoSelector
          isInProduction={this.props.isInProduction}
            h={selectorHeight}
            w={selectorWidth}
            player={player}
            video={video}
            carRef={this.carRef}
            setVideoId={this.setVideoId}
          />
        );
        vids.push(selector);
      }

    }
    this.setState({
      videos: vids
    });
    this.player = player;
  }

  componentDidUpdate() {
    if (this.videoId) {
      this.carRef.current.setVideoId(this.videoId);
      this.videoId = undefined;
    }
  }

  setVideoId = (vId, source) => {
    this.carRef.current.setVideoId(vId, source);
    this.videoId = vId;
  }

  render() {
    let menuItems = [];
    for (let i = 0; i < this.state.videos.length; i++) {
      menuItems.push(
        <div className="menu-item" key={"gps2" + Math.random()}>
          {this.state.videos[i]}
        </div>
      );
    }

    const ArrowLeft = (
      <IWithTooltip
        className="backward comicsCellTitle large link"
        title={"Précédent"}
      />
    );
    /*
    const ArrowRight = (
      <IWithTooltip
        className="forward comicsCellTitle large link"
        title={"Suivant"}
      />
    );
    */
    const ArrowRight = (
      <button
        className="sc-bdvvtL cNNJIt rec rec-arrow rec rec-arrow-right"
        type="button"
      >
        ❯
      </button>
    );
//<ScrollMenu ref={this.scrollMenuRef}
    return (
      <div className="ytPlaylistPreview" key={"gps2" + Math.random()}>
        <div
          key={"gps2d" + Math.random()}
          className="ytPlaylistPreviewScroller"
          id="ytPlaylistPreviewScroller"
        >
          <div className="menu-bar" key={"gps2dd" + Math.random()}>
            <div className="scrollMenuBeginEnd" key={"gps2ddd" + Math.random()}>
              <span
                title="Début"
                onClick={this.gotoBegin}
                className="scroll-menu-arrow begin"
              >
                <i
                  aria-hidden="true"
                  className="chevron circle step backward comicsCellTitle large link icon"
                ></i>
              </span>
              <span
                title="Find"
                onClick={this.gotoEnd}
                className="scroll-menu-arrow end"
              >
                <i
                  aria-hidden="true"
                  className="chevron circle step forward comicsCellTitle large link icon"
                ></i>
              </span>
            </div>
            <div className="scrollMenu" key={"gps2dddd" + Math.random()}>
              <ScrollMenu
                LeftArrow={ArrowLeft}
                RightArrow={ArrowRight}
              >{menuItems}
                </ScrollMenu>
            </div>
            {this.player}
            {this.props.closeButton}
          </div>
        </div>
      </div>
    );
  }

  gotoBegin = () => {
    this.scrollMenuRef.current.scrollTo("video0");
  }
  gotoEnd = () => {
    this.scrollMenuRef.current.scrollTo(
      "video" + (this.state.videos.length - 1)
    );
  }
}
