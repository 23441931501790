import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { deleteActivity, getAdminActivityEntities } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import AdminFilteredList from "../AdminFilteredList";
import AdminActivityList from "./AdminActivityList";
import AdminActivityFilter from "./AdminActivityFilter";
import { alertResult } from "../util/admin-utils";

export interface IProps extends StateProps, DispatchProps{
  isInProduction: boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class AdminActivity extends React.Component<Props> {

  listRef;
  filterRef;
  entityList

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminActivityEntities();
    this.entityList=tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  };

  filterMethod = (filter) => {
    this.listRef.current.filterEntities(this.entityList,filter);
 }

 removeMethod = async(entityId) => {
  const result: any = await (this.props as any).deleteActivity(entityId);
  alertResult(result);
 }

  render() {
    return (<AdminFilteredList screenName="Activity"
            filterComponent={<AdminActivityFilter filterMethod={this.filterMethod}/>}
            listComponent={<AdminActivityList removeMethod={this.removeMethod}/>}
            listRef={this.listRef}
            filterRef={this.filterRef}
            versionIndicator={true}
            isInProduction={this.props.isInProduction}
    />);
  }
}


const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminActivityEntities,
  deleteActivity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminActivity));
