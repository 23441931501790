import "./Filter.scss";

import React from "react";
import ModernComicsCell from "../../widget/comics/ModernComicsCell";
import { routeUrl } from "../../../shared/util/url-utils";

interface IProps {
  content: any;
  addButtonUrl?: string;
  expanded: boolean;
}
interface IState {
  expanded: boolean;
}

export default class FiltersBox extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = { expanded: props.expanded };
  }

  expandCollapse = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (<ModernComicsCell
      key={"autwidphMCC" + Math.random()}
      content={this.renderContent()}
      className="filter-box-main width100Perc"
      cssContent="filter-box-main-content width100Perc"
    />);
  }

  renderContent() {
    return (<div className="width100Perc">
      <div className="filter-expand width100Perc">
        <span className="filter-expand-label" onClick={this.expandCollapse}>{this.state.expanded ? "Cacher les filtres" : "Afficher les filtres"}</span>
        {this.props.addButtonUrl && <span className="filter-expand-create geekiButton"><a href={routeUrl(this.props.addButtonUrl)}>Créer</a></span>}
      </div>
      {this.state.expanded && this.props.content}
    </div>);
  }
}