import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import reducer, { IRootState } from "../reducers";
import DevTools from "./devtools";
import errorMiddleware from "./error-middleware";
import notificationMiddleware from "./notification-middleware";
import loggerMiddleware from "./logger-middleware";
import { loadingBarMiddleware } from "react-redux-loading-bar";

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  loggerMiddleware
];
const composedMiddlewares = middlewares =>
  process.env.NODE_ENV === "development"
    ? compose(
        applyMiddleware(...defaultMiddlewares, ...middlewares),
        DevTools.instrument()
      )
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) =>
  createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
