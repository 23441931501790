import React from "react";

export default class AdminTest extends React.Component<{}> {

  render() {
    const result = [];
    result.push(<div>test cyril</div>);

    //  result.push(
    //  <InstagramComicsCell key="authorInsta" instaId="davy_mourier" />
    //);
    return result;
  
  }

  /*
    async function fetchInstagramPost () {
      try{
        let limit=10;
        let token=;
        axios
            .get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${props.token}`)
            .then((resp) => {
              console.log(resp.data.data);
           //     setFeedsData(resp.data.data)
            })
      } catch (err) {
          console.log('error', err)
      }
    }
 */
}
