import "./Product.scss";

import React from "react";
import { Row, Col, Input } from "reactstrap";
import DbParseUtils2Mappings from "../util/DbParseUtils2Mappings";
import FiltersBox from "../filter/FiltersBox";

interface IProps {
    filterProducts: any;
}
interface IState {
    dropdownOpen:boolean;
  }

export default class ProductsFilter extends React.Component<IProps, IState> {

    categories;
    selectedOptions;
    geekiName;
    productName;
    

    constructor(props) {
        super(props);
        this.state={dropdownOpen:false};
        this.categories = DbParseUtils2Mappings.productCategories;
        this.selectedOptions=new Map();
        for(let i=0;i<this.categories.length;i++){
            this.selectedOptions.set(this.categories[i],false);
        }
      }

    filter = () => {
        let list= [];
        this.selectedOptions.forEach((value, key) => {
            if(value){
                list.push(key);
            }
          });        
        this.props.filterProducts({list:list,geekiName:this.geekiName,productName:this.productName});
    }

    handleSelectOption = (event) => {
        this.selectedOptions.set(this.categories[event],!this.selectedOptions.get(this.categories[event]));
        this.filter();
    }
    handleGeekiNameChange = (name) => {
        this.geekiName=name.target.value;
        this.filter();
    };
    handleProductNameChange = (name) => {
        this.productName=name.target.value;
        this.filter();
    };

    render() {
        return (<FiltersBox content={this.renderContent()} expanded={true}/>);
    }

    toggleDropdown = () => {
        if(!this.state.dropdownOpen){
        this.setState({dropdownOpen: !this.state.dropdownOpen});
        }
    }
    renderContent(){        
        return (
            <div key={"pf1-" + Math.random()} className="width100Perc filter-box">
                <Row key={"pf2-" + Math.random()} className="justify-content-center width100Perc">
                    <Col md="12">
        {Array.from(this.categories.entries()).map(([key, value]) => (
            <span key={"pf5-" + Math.random()}><input key={"pf3-" + Math.random()} id={"prodFil"+key} type="checkbox" onClick={() => this.handleSelectOption(key)} checked={this.selectedOptions.get(key)} readOnly />
            <label key={"pf4-" + Math.random()}>{DbParseUtils2Mappings.translateActivity(value)}</label></span>
        ))}
                    </Col>
                </Row>
                <Row className="justify-content-center width100Perc">
                    <Col md="3">
                    <Input
                            key="ng-name"
                            type="text"
                            className="form-control"
                            name="geekiName"
                            placeholder="Auteur"
                            onChange={this.handleGeekiNameChange}
                        />
                    </Col>
                    <Col md="3">
                    <Input
                            key="ng-productName"
                            type="text"
                            className="form-control"
                            name="productName"
                            placeholder="Intitulé du produit"
                            onChange={this.handleProductNameChange}
                        />
                    </Col>
                    <Col md="6">
                    </Col>
                </Row>
            </div>);
    }
}