import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  className?: string;
  title: string;
  onClick?: any;
  href?: string;
}

export default class IWithTooltip extends React.Component<IProps> {

  render() {
    return (
      <span title={this.props.title} onClick={this.props.onClick} className="tooltipText">
        {this.renderLink()}
      </span>
    );
  }

  renderLink = () => {
    const css = this.props.className + " icon";
    return this.props.href ? (
      <Link to={this.props.href} key={"IWTT" + this.props.href}>
        <i aria-hidden="true" className={css} />
      </Link>
    ) : (
      <i aria-hidden="true" className={css} />
    );
  }
}
