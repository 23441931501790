import { TableCell, Tooltip } from "@mui/material";

interface IProps {
    row: any;
}

const CryptoRowTargetSummary: React.FC<IProps> = ({ row }) => {

    let totalPerc = 0;
    for (let i = 0; i < row.targets.length; i++) {
        totalPerc += Number(row.targets[i].bagPerc);
    }

    return <TableCell colSpan={2} align="right" className={"rowTarget summary"}>
        <Tooltip title="Total pourcentage objectif défini"><div className={totalPerc < 100 ? "summaryPercNotFull" : ""}>{totalPerc}%</div></Tooltip>
    </TableCell>;

}

export default CryptoRowTargetSummary;