import axios from "axios";
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";
import { number, string } from "prop-types";
import { IAdminNews, INews, defaultValue as defaultINewsValue } from "../shared/model/news.model";
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction } from "../lib/redux-action.type";
import { defaultValue as defaultIGeekiValue } from "../shared/model/geeki.model";
import { IAdminGeeki, IGeekiEdit } from "../shared/model/geeki.model.admin";
import { defaultValue as defaultIActivityValue } from "../shared/model/activity.model";
import { IAdminActivity,IActivityEdit} from "../shared/model/activity.model.admin";
import { IAdminPlaylist, IPlaylist, IPlaylistCreate } from "../shared/model/playlist.model";
import { IAdminPlaylistCandidate, IPlaylistCandidate } from "../shared/model/playlistcandidate.model";
import { IAdminConvention, IConvention, defaultIConventionValue } from "../shared/model/convention.model";
import { IAdminDataQuality } from "../shared/model/dataquality.model";

export const ACTION_TYPES = {
    ADM_CSV: "/adm/csv",
    CREATE_MAJOR: "adm/liqui/major",
    CREATE_MINOR: "adm/liqui/minor",
    LIQUIBASE_VERSIONS: "adm/liqui/version",
    DROP_COLUMN_CSV: "adm/dropColumnCsv",
    FORMAT_TEXT: "api/adm/formattext",
    UPDATE_DESCRIPTION: "api/adm/updatedescription",
    GET_DESCRIPTION: "api/adm/getdescription",

    FETCH_ADMIN_DATA_QUALITY: "api/adm/dataquality",

    NEWS_UPDATE: "api/adm/newsupdate",
    FETCH_ADMIN_NEWS_LIST: "api/adm/news/list",
    FETCH_ADMIN_NEWS: "api/adm/news/edit",
    
    GEEKI_UPDATE: "api/adm/geekiupdate",
    FETCH_ADMIN_GEEKI_LIST: "api/adm/geeki/list",
    FETCH_ADMIN_GEEKI: "api/adm/geeki/edit",

    ACTIVITY_UPDATE: "api/adm/activityupdate",
    FETCH_ADMIN_ACTIVITY_LIST: "api/adm/activity/list",
    FETCH_ADMIN_ACTIVITY: "api/adm/activity/edit",
    DELETE_ADMIN_ACTIVITY: "api/adm/activity/delete",

    CONVENTION_UPDATE: "api/adm/conventionupdate",
    FETCH_ADMIN_CONVENTION_LIST: "api/adm/convention/list",
    FETCH_ADMIN_CONVENTION: "api/adm/convention/edit",

    FETCH_ADMIN_PLAYLISTCANDIDATE_LIST: "api/adm/playlistcandidate/list",
    DETECT_NEWPLAYLISTS: "api/adm/playlistcandidate/detect",
    DESACTIVATE_PLAYLIST: "api/adm/playlistcandidate/desactivate",
    ACTIVATE_PLAYLIST: "api/adm/playlistcandidate/activate",

    
    FETCH_ADMIN_PLAYLIST_LIST: "api/adm/playlist/list",
    
    
   COUNT_IMAGES: "image/count"
};

const initialState = {
  loading: false,
  errorMessage: null,
  countImages: number,
  createMajor: string,
  createMinor: string,
  liquibaseVersions: string,
  dropColumnCsv: string,
  admCsv: string,
  updating: false,
  updateSuccess: false,
  formatText: string,
  updateDescription: string,
  getDescription: string,
  updateNews: string,
  newsEntities: [] as ReadonlyArray<INews>,
  newsEntity: defaultINewsValue,
  updateGeeki: string,
  geekiEntities: [] as ReadonlyArray<IGeekiEdit>,
  geekiEntity: defaultIGeekiValue,
  updateActivity: string,
  activityEntities: [] as ReadonlyArray<IActivityEdit>,
  activityEntity: defaultIActivityValue,
  playlistCandidateEntities: [] as ReadonlyArray<IPlaylistCandidate>,
  detectNewPlaylists: [] as ReadonlyArray<IPlaylistCandidate>,
  desactivatePlaylist: String,
  activatePlaylist: string,
  playlistEntities: [] as ReadonlyArray<IPlaylist>,
  updateConvention: string,
  conventionEntities: [] as ReadonlyArray<IConvention>,
  conventionEntity: defaultIConventionValue,
  dataQualityEntities: [] as ReadonlyArray<IAdminDataQuality>
};

export type AdminStateExt = Readonly<typeof initialState>;

const adminStateExt = (
  state: AdminStateExt = initialState,
  action
): AdminStateExt => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.COUNT_IMAGES):
    case REQUEST(ACTION_TYPES.CREATE_MAJOR):
    case REQUEST(ACTION_TYPES.CREATE_MINOR):
    case REQUEST(ACTION_TYPES.FORMAT_TEXT):
    case REQUEST(ACTION_TYPES.UPDATE_DESCRIPTION):
    case REQUEST(ACTION_TYPES.GET_DESCRIPTION):
    case REQUEST(ACTION_TYPES.ADM_CSV):
      case REQUEST(ACTION_TYPES.FETCH_ADMIN_DATA_QUALITY):  
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_NEWS_LIST):          
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_NEWS):
    case REQUEST(ACTION_TYPES.NEWS_UPDATE):
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_GEEKI_LIST):      
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_GEEKI):
    case REQUEST(ACTION_TYPES.GEEKI_UPDATE):
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_ACTIVITY_LIST):      
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_ACTIVITY):   
    case REQUEST(ACTION_TYPES.DELETE_ADMIN_ACTIVITY):
    case REQUEST(ACTION_TYPES.ACTIVITY_UPDATE):
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_PLAYLISTCANDIDATE_LIST): 
    case REQUEST(ACTION_TYPES.DETECT_NEWPLAYLISTS):
      case REQUEST(ACTION_TYPES.DESACTIVATE_PLAYLIST):
        case REQUEST(ACTION_TYPES.ACTIVATE_PLAYLIST):
          case REQUEST(ACTION_TYPES.FETCH_ADMIN_PLAYLIST_LIST): 
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_CONVENTION_LIST):      
    case REQUEST(ACTION_TYPES.FETCH_ADMIN_CONVENTION):
    case REQUEST(ACTION_TYPES.CONVENTION_UPDATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.COUNT_IMAGES):
    case FAILURE(ACTION_TYPES.CREATE_MAJOR):
    case FAILURE(ACTION_TYPES.CREATE_MINOR):
    case FAILURE(ACTION_TYPES.FORMAT_TEXT):
    case FAILURE(ACTION_TYPES.UPDATE_DESCRIPTION):
    case FAILURE(ACTION_TYPES.GET_DESCRIPTION):
    case FAILURE(ACTION_TYPES.ADM_CSV):
      case FAILURE(ACTION_TYPES.FETCH_ADMIN_DATA_QUALITY):      
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_NEWS):
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_NEWS_LIST):
    case FAILURE(ACTION_TYPES.NEWS_UPDATE):
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_GEEKI):
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_GEEKI_LIST):
    case FAILURE(ACTION_TYPES.GEEKI_UPDATE):
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_ACTIVITY):
    case FAILURE(ACTION_TYPES.FETCH_ADMIN_ACTIVITY_LIST):
      case FAILURE(ACTION_TYPES.DELETE_ADMIN_ACTIVITY):
    case FAILURE(ACTION_TYPES.ACTIVITY_UPDATE):
      case FAILURE(ACTION_TYPES.FETCH_ADMIN_PLAYLISTCANDIDATE_LIST):
        case FAILURE(ACTION_TYPES.DETECT_NEWPLAYLISTS):
          case FAILURE(ACTION_TYPES.DESACTIVATE_PLAYLIST):
            case FAILURE(ACTION_TYPES.ACTIVATE_PLAYLIST):        
            case FAILURE(ACTION_TYPES.FETCH_ADMIN_PLAYLIST_LIST):
        case FAILURE(ACTION_TYPES.FETCH_ADMIN_CONVENTION):
        case FAILURE(ACTION_TYPES.FETCH_ADMIN_CONVENTION_LIST):
        case FAILURE(ACTION_TYPES.CONVENTION_UPDATE):
          
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.COUNT_IMAGES):
      return {
        ...state,
        loading: false,
        countImages: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_MAJOR):
      return {
        ...state,
        loading: false,
        createMajor: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.FORMAT_TEXT):
      return {
        ...state,
        loading: false,
        formatText: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.UPDATE_DESCRIPTION):
      return {
        ...state,
        loading: false,
        updateDescription: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.GET_DESCRIPTION):
        return {
          ...state,
          loading: false,
          getDescription: action.payload.data
        };
      case SUCCESS(ACTION_TYPES.NEWS_UPDATE):
          return {
            ...state,
            loading: false,
            updateNews: action.payload.data
          };
          case SUCCESS(ACTION_TYPES.CONVENTION_UPDATE):
              return {
                ...state,
                loading: false,
                updateConvention: action.payload.data
              };
    case SUCCESS(ACTION_TYPES.CREATE_MINOR):
      return {
        ...state,
        loading: false,
        createMinor: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.LIQUIBASE_VERSIONS):
      return {
        ...state,
        loading: false,
        liquibaseVersions: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.DROP_COLUMN_CSV):
        return {
          ...state,
          loading: false,
          dropColumnCsv: action.payload.data
        };

      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_NEWS):
          return {
            ...state,
            loading: false,
            newsEntity: action.payload.data
          };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_NEWS_LIST):
        return {
        ...state,
        loading: false,
        newsEntities: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_DATA_QUALITY):
        return {
        ...state,
        loading: false,
        dataQualityEntities: action.payload.data
      };  
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_CONVENTION):
          return {
            ...state,
            loading: false,
            conventionEntity: action.payload.data
          };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_CONVENTION_LIST):
        return {
        ...state,
        loading: false,
        conventionEntities: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_PLAYLISTCANDIDATE_LIST):
        return {
        ...state,
        loading: false,
        playlistCandidateEntities: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_PLAYLIST_LIST):
        return {
        ...state,
        loading: false,
        playlistEntities: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.DETECT_NEWPLAYLISTS):
        return {
        ...state,
        loading: false,
        detectNewPlaylists: action.payload.data
      };
      case SUCCESS(ACTION_TYPES.DESACTIVATE_PLAYLIST):
        return {
          ...state,
          loading: false,
          desactivatePlaylist: action.payload.data
        };
        
      case SUCCESS(ACTION_TYPES.ACTIVATE_PLAYLIST):
        return {
          ...state,
          loading: false,
          activatePlaylist: action.payload.data
        };
        
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_GEEKI):
          return {
            ...state,
            loading: false,
            geekiEntity: action.payload.data
          };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_GEEKI_LIST):
        return {
        ...state,
        loading: false,
        geekiEntities: action.payload.data
      };

    case SUCCESS(ACTION_TYPES.GEEKI_UPDATE):
      return {
        ...state,
        loading: false,
        updateGeeki: action.payload.data
      };

      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_ACTIVITY):
          return {
            ...state,
            loading: false,
            activityEntity: action.payload.data
          };
      case SUCCESS(ACTION_TYPES.FETCH_ADMIN_ACTIVITY_LIST):
        return {
        ...state,
        loading: false,
        activityEntities: action.payload.data
      }; 
      
      case SUCCESS(ACTION_TYPES.DELETE_ADMIN_ACTIVITY):
          return {
            ...state,
            loading: false,
            activityEntity: action.payload.data
          };     
      case SUCCESS(ACTION_TYPES.ACTIVITY_UPDATE):
          return {
            ...state,
            loading: false,
            updateActivity: action.payload.data
          };
    case SUCCESS(ACTION_TYPES.ADM_CSV):
      return {
        ...state,
        loading: false,
        admCsv: action.payload.data
      };
    default:
      return state;
  }
};

export default adminStateExt;


// Actions


export const countImages = (login: string) => ({
  type: ACTION_TYPES.COUNT_IMAGES,
  payload: axios.get(
    `api/img/count/${login}?cacheBuster=${new Date().getTime()}`
  )
});
export const createMajor = () => ({
  type: ACTION_TYPES.CREATE_MAJOR,
  payload: axios.get(
    `api/adm/liqui/major?cacheBuster=${new Date().getTime()}`
  )
});

export const formatText = (shortDescription: string,longDescription: string) => ({
  type: ACTION_TYPES.FORMAT_TEXT,
  payload: axios.post(
    ACTION_TYPES.FORMAT_TEXT
    , {shortDescription:shortDescription,longDescription: longDescription})}
  );

  export const updateDescription = (geekiLogin: string,shortDescription: string,longDescription: string) => ({
    type: ACTION_TYPES.UPDATE_DESCRIPTION,
    payload: axios.post(
      ACTION_TYPES.UPDATE_DESCRIPTION
     , {geekiLogin:geekiLogin,shortDescription:JSON.stringify(shortDescription)
      ,longDescription:JSON.stringify(longDescription)}
      )}
    );


    export const getDescription = (geekiLogin: string) => ({
      type: ACTION_TYPES.GET_DESCRIPTION,
      payload: axios.get(
        ACTION_TYPES.GET_DESCRIPTION+`?cacheBuster=${new Date().getTime()}&geekiLogin=`+geekiLogin
        /*,{
          responseType: 'text',
          headers: {
            'Content-Type': 'text/plain; charset=utf-8'
          }
        }*/)}
      );
  export const updateNews = (entity: INews) => ({
    type: ACTION_TYPES.NEWS_UPDATE,
    payload: axios.post(
      ACTION_TYPES.NEWS_UPDATE
    ,entity/*{id: entity.id,createdDate:entity.createdDate,title: JSON.stringify(entity.title),img: JSON.stringify(entity.img)
      ,url:JSON.stringify(entity.url),youtube:JSON.stringify(entity.youtube)
      ,description:JSON.stringify(entity.description),login: entity.login}
    */
      )}
    );

export const dropColumnCsv = () => ({
  type: ACTION_TYPES.DROP_COLUMN_CSV,
  payload: axios.get(
    `api/adm/dropcolumncsv?cacheBuster=${new Date().getTime()}`
  )
});

export const createMinor = () => ({
  type: ACTION_TYPES.CREATE_MINOR,
  payload: axios.get(
    `api/adm/liqui/minor?cacheBuster=${new Date().getTime()}`
  )
});
export const liquibaseVersions = () => ({
  type: ACTION_TYPES.LIQUIBASE_VERSIONS,
  payload: axios.get(
    `api/adm/liqui/version?cacheBuster=${new Date().getTime()}`
  )
});

export const admCsv = (file: string) => ({
  type: ACTION_TYPES.ADM_CSV,
  payload: axios.get(
    `api/adm/csv/in/${file}?cacheBuster=${new Date().getTime()}`
  )
});

export const getAdminNewsEntity: ICrudGetAction<INews> = id => {
  const requestUrl = ACTION_TYPES.FETCH_ADMIN_NEWS+`/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ADMIN_NEWS,
    payload: axios.get<INews>(requestUrl)
  };
};
export const getAdminNewsEntities: ICrudGetAllAction<IAdminNews> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_ADMIN_NEWS_LIST,
  payload: axios.get<IAdminNews>(ACTION_TYPES.FETCH_ADMIN_NEWS_LIST+`/?cacheBuster=${new Date().getTime()}`)
});

export const getAdminGeekiEntity: ICrudGetAction<IGeekiEdit> = id => {
  const requestUrl = ACTION_TYPES.FETCH_ADMIN_GEEKI+`/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ADMIN_GEEKI,
    payload: axios.get<IGeekiEdit>(requestUrl)
  };
};
export const getAdminGeekiEntities: ICrudGetAllAction<IAdminGeeki> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_ADMIN_GEEKI_LIST,
  payload: axios.get<IAdminGeeki>(ACTION_TYPES.FETCH_ADMIN_GEEKI_LIST+`/?cacheBuster=${new Date().getTime()}`)
});
export const updateGeeki = (entity: IGeekiEdit) => ({
  type: ACTION_TYPES.GEEKI_UPDATE,
  payload: axios.post(
    ACTION_TYPES.GEEKI_UPDATE
  ,entity
    )}
  );

  export const updateActivity = (entity: IActivityEdit) => ({
    type: ACTION_TYPES.ACTIVITY_UPDATE,
    payload: axios.post(ACTION_TYPES.ACTIVITY_UPDATE,entity)}
    );
    export const getAdminActivityEntity: ICrudGetAction<IActivityEdit> = id => {
      const requestUrl = ACTION_TYPES.FETCH_ADMIN_ACTIVITY+`/${id}`;
      return {
        type: ACTION_TYPES.FETCH_ADMIN_ACTIVITY,
        payload: axios.get<IActivityEdit>(requestUrl)
      };
    };
    export const getAdminActivityEntities: ICrudGetAllAction<IAdminActivity> = (page, size, sort) => ({
      type: ACTION_TYPES.FETCH_ADMIN_ACTIVITY_LIST,
      payload: axios.get<IAdminActivity>(ACTION_TYPES.FETCH_ADMIN_ACTIVITY_LIST+`/?cacheBuster=${new Date().getTime()}`)
    });
    export const deleteActivity: ICrudDeleteAction<IActivityEdit> = id => async dispatch => {
      const requestUrl = ACTION_TYPES.DELETE_ADMIN_ACTIVITY+`/${id}`;
      const result = await dispatch({
        type: ACTION_TYPES.DELETE_ADMIN_ACTIVITY,
        payload: axios.delete(requestUrl)
      });
    //  dispatch(getEntities());
      return result;
    };
    export const getAdminDataQualityEntities: ICrudGetAllAction<IAdminDataQuality> = (page, size, sort) => ({
      type: ACTION_TYPES.FETCH_ADMIN_DATA_QUALITY,
      payload: axios.get<IAdminDataQuality>(ACTION_TYPES.FETCH_ADMIN_DATA_QUALITY+`/?cacheBuster=${new Date().getTime()}`)
    });

    //////////////////PLAYLIST CANDIDATE/////////////////////////////
    export const getAdminPlaylistCandidateEntities: ICrudGetAllAction<IAdminPlaylistCandidate> = (page, size, sort) => ({
      type: ACTION_TYPES.FETCH_ADMIN_PLAYLISTCANDIDATE_LIST,
      payload: axios.get<IAdminPlaylistCandidate>(ACTION_TYPES.FETCH_ADMIN_PLAYLISTCANDIDATE_LIST+`/?cacheBuster=${new Date().getTime()}`)
    });
    export const detectNewPlaylists: ICrudGetAllAction<IAdminPlaylistCandidate> = (page, size, sort) => ({
      type: ACTION_TYPES.DETECT_NEWPLAYLISTS,
      payload: axios.get<IAdminPlaylistCandidate>(ACTION_TYPES.DETECT_NEWPLAYLISTS+`/?cacheBuster=${new Date().getTime()}`)
    });
    export const desactivatePlaylist: ICrudGetAction<IPlaylistCandidate> = id => {
      const requestUrl = ACTION_TYPES.DESACTIVATE_PLAYLIST+`/${id}`;
      return {
        type: ACTION_TYPES.DESACTIVATE_PLAYLIST,
        payload: axios.get<IPlaylistCandidate>(requestUrl)
      };
    };

    export const activatePlaylist = (entity: IPlaylistCreate) => ({
      type: ACTION_TYPES.ACTIVATE_PLAYLIST,
      payload: axios.post(ACTION_TYPES.ACTIVATE_PLAYLIST,entity)}
      );

      //////////////////PLAYLIST/////////////////////////////
    export const getAdminPlaylistEntities: ICrudGetAllAction<IAdminPlaylist> = (page, size, sort) => ({
      type: ACTION_TYPES.FETCH_ADMIN_PLAYLIST_LIST,
      payload: axios.get<IAdminPlaylist>(ACTION_TYPES.FETCH_ADMIN_PLAYLIST_LIST+`/?cacheBuster=${new Date().getTime()}`)
    });
    

    //////////////////CONVENTION//////////////////////////////////////
    export const getAdminConventionEntity: ICrudGetAction<IConvention> = id => {
      const requestUrl = ACTION_TYPES.FETCH_ADMIN_CONVENTION+`/${id}`;
      return {
        type: ACTION_TYPES.FETCH_ADMIN_CONVENTION,
        payload: axios.get<IConvention>(requestUrl)
      };
    };
    export const getAdminConventionEntities: ICrudGetAllAction<IAdminConvention> = (page, size, sort) => ({
      type: ACTION_TYPES.FETCH_ADMIN_CONVENTION_LIST,
      payload: axios.get<IAdminConvention>(ACTION_TYPES.FETCH_ADMIN_CONVENTION_LIST+`/?cacheBuster=${new Date().getTime()}`)
    });
    export const updateConvention = (entity: IConvention) => ({
      type: ACTION_TYPES.CONVENTION_UPDATE,
      payload: axios.post(ACTION_TYPES.CONVENTION_UPDATE,entity)}
      );