import "../comics/Comics.scss";

import React from "react";
import { Follow } from "react-twitter-widgets";
import SocialItem from "../../../custom/widget/social/SocialItem";
import ModernComicsCell from "../comics/ModernComicsCell";

export interface IProps {
  page: boolean;
  isInProduction: boolean;
}

interface IState {}

export default class GeekiSocials extends React.Component<IProps,IState> {

  render() {
    const content = [];
    content.push(
      <SocialItem
        name="Bluesky"
        linkType="BS"
        url="BS"
        param="BS"
        svg="bluesky.svg"
        css="bluesky"
        key="socialBS"
        isInProduction={this.props.isInProduction}
      />
    );
    content.push(
      <SocialItem
        name="X ex Twitter"
        linkType="TWI"
        url="TWI"
        param="TWI"
        svg="twitterx.svg"
        css="twitter"
        key="socialTWI"
        isInProduction={this.props.isInProduction}
      />
    );
 /*   content.push(
      <SocialItem
        name="Instagram"
        linkType="INS"
        url="INS"
        param="INS"
        svg="instagram.svg"
        css="instagram"
        key="socialINS"
        isInProduction={this.props.isInProduction}
      />
    );*/
    /*
    content.push(
      <SocialItem
        name="Instagram"
        linkType="TI"
        url="TI"
        param="TI"
        svg="tipeee.svg"
        css="tipeee"
        key="socialTIP"
        isInProduction={this.props.isInProduction}
      />
    );*/
    content.push(<div className="xFollow" key="socialFollow"><Follow username="GeekiFr" key="follow" /></div>);
    
    content.push(
      <SocialItem
        name="Facebook"
        linkType="FB"
        url="FB"
        param="FB"
        svg="facebook.svg"
        css="facebook"
        key="socialFB"
        isInProduction={this.props.isInProduction}
      />
    );
    if (this.props.page) {
      return (content);
    } else {
      return (
        <ModernComicsCell
        key="socialMCC"
        cssPanel="geekiSocials width100Perc"
          className="modernCC-widget width100Perc"
          content={<span className="modernCC-widget-content geekiSocialsInner">{content}</span>}
        />
      );
    }
  }
}
