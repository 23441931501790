import React from "react";
import RssItem from "./RssItem";
import GeekiSkeleton from "../image/GeekiSkeleton";
import ModernComicsCell from "../comics/ModernComicsCell";

export interface IProps {
  rsses: any;
  logo: any;
}
export interface IState {
  items: any[];
  loaded: boolean;
}

export default class RssComicsCell extends React.Component<IProps,IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      items: []
    };
  }

  componentDidMount() {
    const rssArray = [];
    for (let i = 0; i < this.props.rsses.length; i++) {
      const nodeKey = "rss" + i;
      const obj = this.props.rsses[i];
      rssArray.push(
        <RssItem
          title={obj.title}
          link={obj.link}
          content={obj.content}
          key={nodeKey}
          logo={this.props.logo}
        />
      );
    }
    this.setState({
      items: rssArray,
      loaded: true
    });

    /*
  //  this.fetchData(this.state);
  this.getFeedListing(this.props.rss).then(response =>{
 // this.getFeedListing('https://www.reddit.com/.rss').then(response =>{ 
  //  this.getFeedListing(this.props.rss).then(response =>{  console.log(response);
    );
*/
  }

  render() {
    if (!this.state.loaded) {
      return this.renderLoadingView();
    }
    const items = this.state.items;
    /* var parser = new DOMParser();
    var doc = parser.parseFromString(this.props.rss, "application/xml");
    return <Rsses rss={doc}/>;*/
    /* return <FetchDataFromRssFeed rss={this.props.rss}/>;*/
    return <div key={"rsscc" + Math.random()}>{items}</div>;

  }

  renderLoadingView() {
    return <GeekiSkeleton />;
  }
}
