import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label, Input } from "reactstrap";

import { formatText, updateActivity, getDescription } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import { adminLayout, alertResult } from "../util/admin-utils";
import AdmLiquibaseIndicator from "../AdmLiquibaseIndicator";
import { WithRouterProps } from "../../../shared/util/withRouter";
import { IActivityEdit } from "../../../shared/model/activity.model.admin";
import { getAdminActivityEntity, getAdminGeekiEntities, deleteActivity } from "../../../reducers/admin-reducer";
import TableRowEditor from "../../widget/table/TableRowEditor";
import GeekiInput from "../geeki/GeekiInput";
import DescriptionEditor from "../geeki/DescriptionEditor";
import DbParseUtils from "../../widget/util/DbParseOldUtil";
import AdminNotAuthenticated from "../AdminNotAuthenticated";
import { isUserAuthenticated } from "../../../reducers/authentication";

interface IProps extends StateProps, DispatchProps {
  formatText: any;
  isInProduction: boolean;
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  isNewVersion: boolean,
  geekies: any[];
}

class AdminActivityEdit extends React.Component<Props, IState> {

  longRef;
  indicatorRef;
  rolesRef;
  actId;
  fromActId;

  entity: IActivityEdit;
  fromEntity: IActivityEdit;
  targetLongDescription: string;



  constructor(props: any) {
    super(props);
    this.entity = null;
    this.fromEntity = null;
    this.state = { isNewVersion: false, geekies: null };
    this.longRef = React.createRef();
    this.indicatorRef = React.createRef();
    this.rolesRef = React.createRef();
    this.targetLongDescription = '';

    this.loadGeekies();
  }

  loadGeekies = async () => {
    let geekies = (await (this.props as any).getAdminGeekiEntities()).value.data;
    this.loadEntity(geekies);
  }

  loadEntity = async (geekies) => {
    const hash = window.location.hash; // Obtient la partie d'ancrage de l'URL (#/admin/activity/edit/123)

    this.fromEntity = null;
    if (hash.endsWith("create")) {
      this.entity = {};
    } else {
      const parts = hash.split('/'); // Divise la partie d'ancrage en segments
      const value = parts[parts.length - 1]; // Récupère la dernière partie de la division
      const entity: any = await (this.props as any).getAdminActivityEntity(value);
      this.entity = entity.value.data;
      this.actId = this.entity.id;

    }
    this.setState({ isNewVersion: this.state.isNewVersion, geekies: geekies });///to force the render
  }

  gotoNextActivity = async () => {
    let nextId = 1 + parseInt(this.actId, 10);
    window.location.replace('/#/admin/activity/edit/' + nextId);
    this.loadEntity(this.state.geekies);
    window.location.replace('/#/admin/activity/edit/' + this.actId);
  }

  transformFromTarget = (event: any) => {
    this.formatTargetDescription();
  }
  formatTargetDescription = async () => {
    const result = await this.props.formatText('', this.targetLongDescription);
    const cleanLongResult = result.value.data.longDescription;
    this.targetLongDescription = cleanLongResult;
    const parsedLongResult = DbParseUtils.parseDescription(cleanLongResult, false);

    this.longRef.current.setText(cleanLongResult);
    this.longRef.current.setHtml(parsedLongResult);
  };
  textLongTargetChanged = (event: any) => {
    this.targetLongDescription = event.target.value;
  }

  setIsNewVersion = (isNew: boolean) => {
    this.setState({ isNewVersion: isNew });
  }

  updateInFile = async () => {
    this.entity.title = this.filterValue(this.entity.title);
    this.entity.editor = this.filterValue(this.entity.editor);
    this.entity.youtube = this.filterValue(this.entity.youtube);
    this.entity.url = this.filterValue(this.entity.url);
    this.entity.img = this.filterValue(this.entity.img);
    this.entity.from = this.filterValue(this.entity.from);
    this.entity.roles = this.rolesRef.current.getRows();
    this.entity.newsDescription = this.targetLongDescription;
    this.entity.newsCreatedDate = this.filterValue(this.entity.newsCreatedDate);

    const result = await this.props.updateActivity(this.entity);
    alertResult(result);
  }
  reloadEntity = async () => {

    let newId = this.actId;
    window.location.replace('/#/admin/activity/edit/' + newId);
    const entity: any = await (this.props as any).getAdminActivityEntity(newId);
    this.entity = entity.value.data;
    this.fromEntity = null;
    this.setState({ isNewVersion: this.state.isNewVersion });///to force the render

  }

  copyFromEntity = async () => {
    let fromId = this.fromActId;
    const fromEntity1: any = await (this.props as any).getAdminActivityEntity(fromId);
    this.fromEntity = fromEntity1.value.data;
    for (let i = 0; i < this.fromEntity.roles.length; i++) {
      let role = this.fromEntity.roles[i];
      role.id = null;
      this.entity.roles.push(role);
    }
    let fromEntity = this.fromEntity;
    this.compareAlert(this.entity.comment, fromEntity.comment, 'different comment:' + fromEntity.comment);
    this.compareAlert(this.entity.img, fromEntity.img, 'different img:' + fromEntity.img);
    this.compareAlert(this.entity.url, fromEntity.url, 'different url:' + fromEntity.url);
    this.compareAlert(this.entity.youtube, fromEntity.youtube, 'different youtube:' + fromEntity.youtube);
    this.compareAlert(this.entity.editor, fromEntity.editor, 'different editor:' + fromEntity.editor);
    this.setState({ isNewVersion: this.state.isNewVersion });///to force the render

  }

  compareAlert = (a, b, msg) => {
    let c = (a === undefined ? '' : a);
    let d = (b === undefined ? '' : b);
    if (c !== d) {
      alert(msg);
    }
  }

  deleteEntity = async () => {


    //  if(confirm('Etes vous sur de vouloir supprimer '+this.fromActId)){
    let fromId = this.fromActId;
    const result = await (this.props as any).deleteActivity(fromId);
    alertResult(result);
    //  }
  }

  handleReloadEntityKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.reloadEntity();
    }
  };

  updateActId = (event) => {
    this.actId = event.target.value;
  }
  updateFromActId = (event) => {
    this.fromActId = event.target.value;
  }

  updateTitle = (event) => {
    this.entity.title = event.target.value;
  }
  updateEditor = (event) => {
    this.entity.editor = event.target.value;
  }
  updateYoutube = (event) => {
    this.entity.youtube = event.target.value;
  }
  updateUrl = (event) => {
    this.entity.url = event.target.value.replaceAll('https://amzn.to/', '');
  }
  goToUrl = () => {
    if (this.entity.url !== undefined && this.entity.url !== '') {
      window.open('https://amzn.to/' + this.entity.url);
    }
  }

  updateImg = (event) => {
    this.entity.img = event.target.value;
    if (this.entity.img != null) {
      let splitted = this.entity.img.split('</a>');
      if (splitted.length === 2) {
        this.entity.img = splitted[0].split('<img')[1].split('src="')[1].split('"')[0];
      }
    }
  }
  updateComment = (event) => {
    this.entity.comment = event.target.value.replaceAll('\n', ' ').replaceAll('\r', ' ');
  }
  updateFrom = (event) => {
    this.entity.from = event.target.value;
  }
  updateNewsCreatedDate = (event) => {
    this.entity.newsCreatedDate = event.target.value;
  }

  updateGeeki = (event) => {
    this.entity.geekiLogin = event.login;
  }

  updateRoleTo = (roleId, value) => {
    for (let i = 0; i < this.entity.roles.length; i++) {
      let role = this.entity.roles[i];
      if (role.id === roleId) {
        role.to = value;
        break;
      }
    }
  }
  updateRoleTitle = (roleId, value) => {
    for (let i = 0; i < this.entity.roles.length; i++) {
      let role = this.entity.roles[i];
      if (role.id === roleId) {
        role.title = value;
        break;
      }
    }
  }
  updateRoleSkills = (roleId, value) => {
    for (let i = 0; i < this.entity.roles.length; i++) {
      let role = this.entity.roles[i];
      if (role.id === roleId) {
        role.skills = value;
        break;
      }
    }
  }
  updateRoleGeeki = (roleId, value) => {
    for (let i = 0; i < this.entity.roles.length; i++) {
      let role = this.entity.roles[i];
      if (role.id === roleId) {
        role.geekiLogin = value;
        break;
      }
    }
  };

  removeLinkTags = (text: string) => {
    const regex = /<Link[^>]*>(.*?)<\/Link>/gi;
    if (text != null && text !== undefined) {
      return text.replace(regex, '$1');
    } else {
      return "";
    }
  }

  filterValue = (value) => {
    if (value != null && value.indexOf(";") > -1) {
      alert("error: " + value);
      return "";
    } else {
      return value;
    }
  }


  render() {
    if (!isUserAuthenticated()) {
      return <AdminNotAuthenticated/>;
    } else {
      if (this.entity && this.state.geekies) {

        let rows = this.entity.roles ? this.entity.roles : [];

        let def = [{ prop: 'id', type: 'text' }, { prop: 'to', type: 'text' }, { prop: 'skills', type: 'skills' }
          , { prop: 'geekiLogin', type: 'geeki' }, { prop: 'title', type: 'text' }]

        return adminLayout(this.entity.id ? "Edition d'une Activité" : "Saisie d'une Activité", <>
          <Row className="justify-content-center width100Perc">
            <Col md="12">
              <Row className="justify-content-center width100Perc">
                <Col md="3">
                  <Input
                    id="ng-actid"
                    type="text"
                    className="form-control"
                    name="actid"
                    defaultValue={this.entity ? this.entity.id : ''}
                    key={'act-' + this.entity.id + '-id-' + Math.random()}
                    onChange={this.updateActId}
                    onKeyUp={this.handleReloadEntityKeyPress}
                  />
                  <Button onClick={this.reloadEntity} className="geekiButton">Charger</Button>
                  <Button onClick={this.gotoNextActivity} className="geekiButton">Suivant</Button>
                </Col>
                <Col md="5">
                  <Input
                    id="ng-fromActid"
                    type="text"
                    className="form-control"
                    name="fromActid"
                    key={'act-' + this.entity.id + '-fromid-' + Math.random()}
                    onChange={this.updateFromActId}
                  />
                  <Button onClick={this.copyFromEntity}
                    className="geekiButton">Copier de</Button>
                  <Button onClick={this.deleteEntity}
                    className="geekiButton">Supprimer</Button>
                </Col>
                <Col md="4">
                  <Row className="justify-content-center">

                    <Col md="9"><AdmLiquibaseIndicator ref={this.indicatorRef}
                      fctSetNewVersion={this.setIsNewVersion} /></Col>
                    <Col md="3">
                      <Button onClick={this.updateInFile}
                        className="geekiButton"
                        disabled={!this.state.isNewVersion}>Mettre à jour fichier</Button></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center width100Perc">
                <Col md="3">
                  <Label for="news-id">ID</Label>
                  <Input
                    id="ng-id"
                    type="text"
                    className="form-control"
                    name="id"
                    defaultValue={this.entity.id}
                    key={this.entity.id + '-id-' + Math.random()}
                    disabled={true}
                  />
                </Col>
                <Col md="3">
                  <Label for="news-title">Titre</Label>
                  <Input
                    id="ng-title"
                    type="text"
                    className="form-control"
                    name="title"
                    key={this.entity.id + '-title-' + Math.random()}
                    defaultValue={this.entity.title}
                    onChange={this.updateTitle}
                    required
                  />
                </Col>
                <Col md="3">
                  <Label for="news-from">Année</Label>
                  <Input
                    id="ng-from"
                    type="text"
                    className="form-control"
                    name="from"
                    defaultValue={this.entity.from}
                    key={this.entity.id + '-from-' + Math.random()}
                    onChange={this.updateFrom}
                    required
                  />
                </Col>
                <Col md="3">
                  <Label for="news-geekiLogin">Geeki</Label>
                  <GeekiInput
                    name="geekiLogin"
                    itemLoadKey="login"
                    itemSaveKey="login"
                    geekies={this.state.geekies}
                    callbackFunction={(e) => this.updateGeeki(e)}
                    disabled={false}
                    value={this.entity.geekiLogin}
                    key={this.entity.id + '-login-' + Math.random()}
                    defaultValue=""
                    placeholder="Sélectionnez un Geeki"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center width100Perc">
                <Col md="3">
                  <Label for="news-youtube">Youtube</Label>
                  {this.fromEntity && this.fromEntity.youtube !== this.entity.youtube && <Label for="news-youtube"
                    style={{ color: "red" }}>{this.fromEntity.youtube}</Label>}
                  <Input
                    id="ng-youtube"
                    type="text"
                    className="form-control"
                    name="youtube"
                    defaultValue={this.entity.youtube}
                    onChange={this.updateYoutube}
                    key={this.entity.id + '-youtube-' + Math.random()}
                    required
                  />
                </Col>
                <Col md="3">
                  <Label for="news-url">Url</Label>&nbsp;<Button onClick={this.goToUrl} className="geekiButton">Ouvrir lien</Button>
                  <Input
                    id="ng-url"
                    type="text"
                    className="form-control"
                    name="url"
                    defaultValue={this.entity.url}
                    key={this.entity.id + '-url-' + Math.random()}
                    onChange={this.updateUrl}
                    required
                  />
                </Col>
                <Col md="6">
                  <Label for="news-img">Img</Label>
                  <Input
                    id="ng-img"
                    type="text"
                    className="form-control"
                    name="img"
                    defaultValue={this.entity.img}
                    onChange={this.updateImg}
                    key={this.entity.id + '-img-' + Math.random()}
                    required
                  />
                </Col>
              </Row>
              <Row className="justify-content-center width100Perc">

                <Col md="3">
                  <Label for="news-editor">Editeur</Label>
                  <Input
                    id="ng-editor"
                    type="text"
                    className="form-control"
                    name="editor"
                    defaultValue={this.entity.editor}
                    onChange={this.updateEditor}
                    key={this.entity.id + '-editor-' + Math.random()}
                    required
                  />
                </Col>
                <Col md="9">
                  <Label for="news-comment">Comment</Label>
                  <Input
                    id="ng-comment"
                    type="textarea"
                    className="form-control"
                    name="comment"
                    defaultValue={this.entity.comment}
                    onChange={this.updateComment}
                    key={this.entity.id + '-comment-' + Math.random()}
                    required
                  />
                </Col>
                <Col md="4">
                </Col>
              </Row>
              <Row className="justify-content-center width100Perc">
                <Col md="12">
                  <TableRowEditor
                    geekies={this.state.geekies}
                    key={this.entity.id + '-tableroweditor-' + Math.random()}
                    rows={rows} def={def} tableHeader="Roles" ref={this.rolesRef} />
                </Col>
              </Row>
              <Row><Col md="3">
                <Label for="news-from">Date News</Label>
                <Input
                  id="ng-newsCreatedDate"
                  type="text"
                  className="form-control"
                  name="newsCreatedDate"
                  defaultValue={this.entity.newsCreatedDate}
                  key={this.entity.id + '-newsCreatedDate-' + Math.random()}
                  onChange={this.updateNewsCreatedDate}
                  required
                /> </Col>
                <Col md="9">
                  <Button onClick={this.transformFromTarget}>Formater News</Button>
                </Col>
              </Row>
              <Row className="justify-content-center"
                style={{ minHeight: "500px" }}>
                <Col md="12">
                  <Label for="news-description">Description</Label>
                  <DescriptionEditor
                    minRows={15}
                    ref={this.longRef}
                    onChange={this.textLongTargetChanged}
                    maxRows={30}
                    defaultValue={this.entity.newsDescription}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>,this.props.isInProduction)
      } else {
        return "";
      }
    };
  }
}

const mapStateToProps = ({ authentication, geeki,applicationProfile }: IRootState) => ({
  account: authentication.account,
  geekiEntity: geeki.entity,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminActivityEntity,
  formatText,
  updateActivity,
  getDescription,
  getAdminGeekiEntities,
  deleteActivity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminActivityEdit);
