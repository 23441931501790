import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';
import { getAdminPlaylistCandidateEntities, detectNewPlaylists, desactivatePlaylist, activatePlaylist } from "../../../reducers/admin-reducer";

import { getEntity, updateEntity } from "../../../reducers/playlistcandidate.reducer";
import { IRootState } from "../../../reducers";
import AdminFilteredList from "../AdminFilteredList";
import AdminPlaylistCandidateList from "./AdminPlaylistCandidateList";
import AdminPlaylistCandidateFilter from "./AdminPlaylistCandidateFilter";
import { IPlaylistCandidate } from "../../../shared/model/playlistcandidate.model";
import { alertResult } from "../util/admin-utils";
import AdminPlaylistCandidateValidator from "./AdminPlaylistCandidateValidator";
import { IPlaylist, IPlaylistCreate } from "../../../shared/model/playlist.model";

export interface IProps extends StateProps, DispatchProps { 
  isInProduction:boolean
}
type Props = IProps & WithRouterProps<IProps>;

export class AdminPlaylistCandidate extends React.Component<Props> {

  listRef;
  filterRef;
  editorRef;
  entityList;

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.editorRef = React.createRef();
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = async () => {
    const tmpEntityList: any = await (this.props as any).getAdminPlaylistCandidateEntities();
    this.entityList = tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  };

  filterMethod = (filter) => {
    this.listRef.current.filterEntities(this.entityList, filter);
  }

  desactivatePlaylist = async entityId => {
    const entityPayload: any = await (this.props as any).desactivatePlaylist(entityId);
    const entity: IPlaylistCandidate = entityPayload.value.data;
    entity.processed = true;
    this.getEntities();
    alertResult("La playlist candidate " + entity.id + " a été désactivée");
  };

  openActivatePlaylist = async entity => {
    this.editorRef.current.activatePlaylist(entity);
  }

  doActivatePlaylist = async (entityCandidate, playlistGeekis) => {
    let toto: IPlaylistCreate = { playlistCandidateId: entityCandidate.id, playlistId: entityCandidate.playlistId, name: entityCandidate.name, geekiLogins: playlistGeekis };
    const entityPayload: any = await (this.props as any).activatePlaylist(toto);
    const entity: IPlaylist = entityPayload.value.data;
    this.getEntities();
    alertResult("La playlist " + entity.id + " a été activée");
  }

  detectNewPlaylists = async () => {
    const tmpEntityList: any = await (this.props as any).detectNewPlaylists();
    this.entityList = tmpEntityList.value.data;
    this.listRef.current.updateEntityList(tmpEntityList.value.data);
  }


  render() {
    return (<AdminFilteredList screenName="Playlist Candidats"
      filterComponent={<AdminPlaylistCandidateFilter filterMethod={this.filterMethod} />}
      filterRef={this.filterRef}
      listComponent={<AdminPlaylistCandidateList
        desactivatePlaylist={this.desactivatePlaylist} activatePlaylist={this.openActivatePlaylist} detectNewPlaylists={this.detectNewPlaylists} />}
      listRef={this.listRef}
      editor={<AdminPlaylistCandidateValidator activatePlaylist={this.doActivatePlaylist}
        geekies={this.entityList} />}
      editorRef={this.editorRef}
      versionIndicator={true}
      isInProduction={this.props.isInProduction}
    />);
  }
}


const mapStateToProps = ({ applicationProfile }: IRootState) => ({
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminPlaylistCandidateEntities,
  getEntity,
  updateEntity,
  detectNewPlaylists,
  desactivatePlaylist,
  activatePlaylist
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPlaylistCandidate));
