import { IGeeki } from "./geeki.model";

export interface IPlaylist {
  id?: number;
  playlistId?: string;
  source?: string;
  name?: string;
  frequency?: number;
  geekis?: IGeeki[];
}

export interface IPlaylistCreate{
  playlistCandidateId: number;
  playlistId: string;
  name: string;
  geekiLogins: String[];
}

export interface IAdminPlaylist {
  status: string;
  entity: IPlaylist;
}

export const defaultValue: Readonly<IPlaylist> = {};
