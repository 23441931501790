import "../admin.scss";
import React from "react";
import { IPlaylistCandidate } from "../../../shared/model/playlistcandidate.model";
import { Button, Table } from "reactstrap";
import GeekiInput from "../geeki/GeekiInput";

interface IProps {
  activatePlaylist:any
  geekies: any;
}

interface IState {
    entity:IPlaylistCandidate;
}

export default class AdminPlaylistCandidateValidator extends React.Component<IProps,IState> {

   playlistGeekis={geekiLogin1:'',geekiLogin2:'',geekiLogin3:'',geekiLogin4:''};

  constructor(props) {
    super(props);
    this.state={entity:null};
  }
 activatePlaylist = async (entity) => {
    this.setState({entity:entity});

}

commitActivatePlaylist = async ( e) =>{
  let logins=[];
  if(this.playlistGeekis.geekiLogin1!==''){
      logins.push(this.playlistGeekis.geekiLogin1);
  }
  if(this.playlistGeekis.geekiLogin2!==''){
      logins.push(this.playlistGeekis.geekiLogin2);
  }
  if(this.playlistGeekis.geekiLogin3!==''){
      logins.push(this.playlistGeekis.geekiLogin3);
  }
  if(this.playlistGeekis.geekiLogin4!==''){
      logins.push(this.playlistGeekis.geekiLogin4);
  }
  this.props.activatePlaylist(this.state.entity,logins);
  this.setState({entity:null});
}

updateGeeki = (pos,e) =>{
  this.playlistGeekis['geekiLogin'+pos]=e.login;
}

  render() {
    if(this.state.entity){
      return ( <Table responsive aria-describedby="playlist-heading">
        <tr>
          <td height="200px">
        <GeekiInput
          name="geekiLogin1"
          itemLoadKey="login"
          itemSaveKey="login"
          callbackFunction={(e) => this.updateGeeki(1,e)}
          disabled={false}
          value=""
          key={this.state.entity.id + '-login1-' + Math.random()}
          defaultValue=""
          placeholder="Sélectionnez un Geeki"
          geekies={this.props.geekies}
        /></td>
          <td>
        <GeekiInput
          name="geekiLogin2"
          itemLoadKey="login"
          itemSaveKey="login"
          callbackFunction={(e) => this.updateGeeki(2,e)}
          disabled={false}
          value=""
          key={this.state.entity.id + '-login2-' + Math.random()}
          defaultValue=""
          placeholder="Sélectionnez un Geeki"
          geekies={this.props.geekies}
        /></td>
          <td>
        <GeekiInput
          name="geekiLogin3"
          itemLoadKey="login"
          itemSaveKey="login"
          callbackFunction={(e) => this.updateGeeki(3,e)}
          disabled={false}
          value=""
          key={this.state.entity.id + '-login3-' + Math.random()}
          defaultValue=""
          placeholder="Sélectionnez un Geeki"
          geekies={this.props.geekies}
        /></td>
          <td>
        <GeekiInput
          name="geekiLogin4"
          itemLoadKey="login"
          itemSaveKey="login"
          callbackFunction={(e) => this.updateGeeki(4,e)}
          disabled={false}
          value=""
          key={this.state.entity.id + '-login4-' + Math.random()}
          defaultValue=""
          placeholder="Sélectionnez un Geeki"
          geekies={this.props.geekies}
        /></td>
        </tr>
        <tr>
<td><Button onClick={this.commitActivatePlaylist} className='geekiButton'>Créer Playlist</Button></td>
        </tr>
        </Table>);
    }else{
      return <div>Test Cyril</div>;
    }
  }
}

