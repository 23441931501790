import "../modules/home/home.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { geekiCenteredLayout } from "../shared/util/layout-util";
import ModernComicsCell from "./widget/comics/ModernComicsCell";

export type IHomeProp = StateProps;

export const About = (props: IHomeProp) => {
  const content = (
    <>
      <ModernComicsCell
        inline="true"
        innerHTML={false}
        cssTitle="sign large icon"
        className="newsWidget"
        cssPanel="overflowHidden"
        content={
          <h2 className="geekiText">
            <p>Geeki est un site indépendant.</p>
            <p>Il est hébergé par :</p>
            <p>
              OVH
              <br />2 rue Kellermann
              <br />
              59100 Roubaix
              <br />
              France
            </p>
            <p>Confidentialité et Protection des données</p>
            <p>
              Conformément à la loi informatique et libertés du 06 janvier 1978,
              vous pouvez à tout moment modifier ou supprimer les renseignements
              liés à votre inscription. Ces informations vous concernant étant
              confidentielles, Geeki s’engage à les protéger.
            </p>
            <p>
              Vous disposez d’un droit d’accès, de modification, de
              rectification et de suppression des données qui vous concernent
              (art. 34 de la loi « Informatique et Libertés »). Vous pouvez
              exercer ce droit directement sur les sites concernés ou en nous
              contactant par e-mail via{" "}
              <Link to="/contact"> le formulaire dédié à cet effet</Link>.
            </p>
            <p>Formulaires de contact</p>
            <p>
              Quand vous utilisez le formulaire de contact, les données
              inscrites ne sont pas utilisées à des fins commerciales.
            </p>
            <p>Contenu embarqué depuis d’autres sites</p>
            <p>
              Les articles de ce site peuvent inclure des contenus intégrés (par
              exemple des vidéos, images, articles…). Le contenu intégré depuis
              d’autres sites se comporte de la même manière que si le visiteur
              se rendait sur cet autre site.
            </p>
            <p>
              Ces sites web pourraient collecter des données sur vous, utiliser
              des cookies, embarquer des outils de suivis tiers, suivre vos
              interactions avec ces contenus embarqués si vous disposez d’un
              compte connecté sur leur site web.
            </p>
            <p>Durées de stockage de vos données</p>
            <p>
              Pour les utilisateurs et utilisatrices qui s’enregistrent sur
              notre site (si cela est possible), nous stockons indéfiniment les
              données personnelles indiquées dans leur profil. Tous les
              utilisateurs et utilisatrices peuvent voir, modifier ou supprimer
              leurs informations personnelles à tout moment (à l’exception de
              leur nom d’utilisateur·ice). Les gestionnaires du site peuvent
              aussi voir et modifier ces informations.
            </p>
          </h2>
        }
      />
    </>
  );
  return geekiCenteredLayout("Mentions légales",content);
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(About);
