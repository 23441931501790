import { useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { getUrlParameter } from "../../../../lib/url-utils";
import { withRouter, WithRouterProps } from '../../../../shared/util/withRouter';
import { Form, Input } from "reactstrap";
import { handlePasswordResetFinish, reset } from "../../../../reducers/password-reset.reducer";
import PasswordStrengthBar from "../../../../shared/layout/password/password-strength-bar";
import ComicsButton from "../../../../custom/widget/comics/ComicsButton";

export interface IProps  extends DispatchProps{ }
type Props = IProps & WithRouterProps<IProps>;

export const PasswordResetFinishPage = (props: Props) => {
  const [password, setPassword] = useState("");
  const [key] = useState(getUrlParameter("key", props.location.search));

  const handleValidSubmit = (event, values) =>
    (props as any).handlePasswordResetFinish(key, values.newPassword);

  const updatePassword = event => setPassword(event.target.value);

  const getResetForm = () => {
    return (
      <Form onValidSubmit={handleValidSubmit}>
        <Input
          name="newPassword"
          label="Nouveau mot de passe"
          placeholder="placeholder"
          type="password"
          validate={{
            required: {
              value: true,
              errorMessage: "Nouveau mot de passe obligatoire"
            },
            minLength: {
              value: 4,
              errorMessage: "Nouveau mot de passe minimum 4"
            },
            maxLength: {
              value: 50,
              errorMessage: "Nouveau mot de passe maximum 50"
            }
          }}
          onChange={updatePassword}
        />
        <PasswordStrengthBar password={password} />
        <Input
          name="confirmPassword"
          label="Confirmation mot de passe"
          placeholder="placeholder"
          type="password"
          validate={{
            required: {
              value: true,
              errorMessage: "Confirmation mot de passe obligatoire"
            },
            minLength: {
              value: 4,
              errorMessage: "Confirmation mot de passe minimum 4"
            },
            maxLength: {
              value: 50,
              errorMessage: "Confirmation mot de passe maximum 50"
            },
            match: {
              value: "newPassword",
              errorMessage: "Ne correspond pas"
            }
          }}
        />
        <ComicsButton title="Valider" />
      </Form>
    );
  };

  const content = (
    <div className="width100Perc">
      <Row className="justify-content-center">
        <Col md="4">
          <div>{key ? getResetForm() : null}</div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="centered-out">
      <div className="centered-in">{content}</div>
    </div>
  );
};

const mapDispatchToProps = { handlePasswordResetFinish, reset };

type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  null,
  mapDispatchToProps
)(PasswordResetFinishPage));
