import "./Playlist.scss";
import "../comics/Comics.scss";

import React from "react";
import { connect } from "react-redux";
import { withRouter, WithRouterProps } from '../../../shared/util/withRouter';

import YoutubePlaylistScroller from "./YoutubePlaylistScroller";

import { getEntityByPlaylistId } from "../../../reducers/video.reducer";
import SkeletonImage from "../image/SkeletonImage";
import ModernComicsCell from "../../../custom/widget//comics/ModernComicsCell";

interface IProps extends StateProps, DispatchProps{
  playlistId: string;
  playlistName?: string;
  name: string;
  loadFirst: boolean;
  className?: string;
  isInProduction:boolean;
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
  loaded: boolean;
  error: boolean;
  expanded: boolean;
  items: any[];
  videoList?: any;
}

class YoutubePlaylist extends React.Component<Props,IState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      items: [],
      expanded: false
    };
  }

  componentDidMount() {
    this.fetchYoutubeData2();
  }

  fetchYoutubeData2 = async () => {
    if (this.state.videoList === undefined) {
      const vList: any = await (this.props as any).getEntityByPlaylistId(
        (this.props as any).playlistId
      );
      const videoList = vList.value.data;

      this.setState({
        loaded: true,
        videoList: videoList
      });
    }
  };
  showHidePlaylist = () => {
    this.setState(state => ({
      expanded: !this.state.expanded
    }));
  }

  render() {
    const items1 = [];
    let imgUrl;
    const videoList = this.state.videoList;
    if (videoList !== undefined && videoList.length > 0) {
      const value = (this.props as any).loadFirst
        ? videoList[0]
        : videoList[Math.floor(Math.random() * videoList.length)];
      imgUrl = "https://i1.ytimg.com/vi/" + value.videoId + "/mqdefault.jpg";
    } else if (this.state.loaded) {
      return "";
    }
    const imgCss = this.state.expanded ? " expanded" : "";

    if (imgUrl !== undefined) {
      items1.push(
        <div
          key={"ypvcd" + Math.random()}
          className={"playlistContainer" + imgCss}
        >
          <button onClick={this.showHidePlaylist} className={"ytPreview" + imgCss}>
            <SkeletonImage
              key={"skelImgYoutubePlaylist" + (this.props as any).playlistId}
              src={imgUrl}
              height={195}
              width={320}
            />
          </button>
          {videoList && (
            <div
              key={"ypvc" + Math.random()}
              className="videoCount"
              title={videoList.length + " vidéo"+(videoList.length>1?"s":"")}
            >{videoList.length}</div>
          )}
          {(this.props as any).playlistName && (
            <button onClick={this.showHidePlaylist} className={"ytPreview" + imgCss}>
              <div key="playlistContainer1" className="playlistContainer1">
                {" "}
                {(this.props as any).playlistName}{" "}
              </div>
            </button>
          )}
          <YoutubePlaylistScroller
            key="youtubePlaylistScroller"
            isInProduction={this.props.isInProduction}
            videoList={this.state.videoList}
            expanded={this.state.expanded}
            closeButton={
              <span
                className="ytPlaylistPreviewScrollerClose geekiButton"
                onClick={this.showHidePlaylist}
              >
                Fermer
              </span>
            }
            loadFirst={(this.props as any).loadFirst}
          />
        </div>
      );
    }

    const content = (
      <div className="playlist-content" key={"ypc" + Math.random()}>
        <article className="comic">{items1}</article>
      </div>
    );
    return (
      <ModernComicsCell
        fullWidth={true}
        className={(this.props as any).className}
        title={(this.props as any).name}
        content={<span className="modernCC-widget-content">{content}</span>}
      />
    );
  }
}
const mapStateToProps = ( IRootState) => ({});

const mapDispatchToProps = {
  getEntityByPlaylistId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(YoutubePlaylist));
