import { Porttcfolio, Exchange } from "./CryptoDataType";

export const reloadPorttcfolioFromJson = (value: string) => {

  try {
    portfolio = JSON.parse(value);
  } catch (e) {
    alert("Mauvais ancien format");
  }

  try {
    if (value.indexOf("investment") >= 0) {
      porttcfolio = JSON.parse(value);
      console.log(porttcfolio);
      
      let total=0;  
      for (let i = 0; i < porttcfolio.items.length; i++) {
        let item =  porttcfolio.items[i];
        let price = item.bagPrice;
        if (price != null) {
          total += price;
        }
      }
      porttcfolio.currentTotal=total;
    } else {
      porttcfolio = {
        currentTotal:0,
        investment: 0,
        impotsPerc: 33,
        items: JSON.parse(value),
        exchanges: []
      };
    }
  } catch (e) {
    alert("Mauvais nouveau format");

    porttcfolio = {
      currentTotal:0,
      investment: 0,
      impotsPerc: 33,
      items: [],
      exchanges: []
    };
  }

}

//todo cst old format
export let portfolio = [
  { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "USDT", "site": "bybit", "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "TAO", "site": "blofin", "bag": 5, "invested": "200", "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu" },
  { "symbol": "BTC", "site": "bybit", "bag": 5, "targets": [{ "rate": 70000 }] },
  { "symbol": "ETH", "site": "ledger", "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }] },
  { "symbol": "XRP", "site": "metamask", "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }] },
  { "symbol": "AKT", "site": "blofin", "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }] }
];

export let porttcfolio: Porttcfolio =
{
  currentTotal:0,
  investment: 0,
  impotsPerc: 33,
  items: [
    { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false, automaticUpdate: true },
    { "symbol": "USDT", "site": "bybit", "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false, automaticUpdate: true },
    { "symbol": "TAO", "site": "blofin", "bag": 5, "invested": 200, "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu", loaded: false, automaticUpdate: true },
    { "symbol": "BTC", "site": "bybit", "bag": 5, "targets": [{ "rate": 70000 }], loaded: false, automaticUpdate: true },
    { "symbol": "ETH", "site": "ledger", "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }], loaded: false, automaticUpdate: true },
    { "symbol": "XRP", "site": "metamask", "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }], loaded: false, automaticUpdate: true },
    { "symbol": "AKT", "site": "blofin", "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }], loaded: false, automaticUpdate: true }
  ],
  exchanges: []
};

export const updateExchange = (row: any) => {
  let trouve = false;
  if (porttcfolio.exchanges) {
    for (let i = 0; i < porttcfolio.exchanges.length; i++) {
      let obj = porttcfolio.exchanges[i];
      if (obj.exchange === row.exchange) {
        obj.key = row.key;
        obj.secret = row.secret;
        obj.passphrase = row.passphrase;
        obj.exclusions = row.exclusions;
        trouve = true;
      }
    }
  } else {
    porttcfolio.exchanges = [];
  }
  if (!trouve) {
    let obj: Exchange = { exchange: row.exchange, key: row.key, secret: row.secret,passphrase:row.passphrase, exclusions: row.exclusions };
    porttcfolio.exchanges.push(obj);
  }
}

export const getPositionInPorttcfolio = (ttcId: string, porttcfolio: Porttcfolio) => {
  return getPositionInSortedRows(ttcId, porttcfolio.items);
}

export const getPositionInSortedRows = (ttcId: string, sortedRows: any) => {
  for (let i = 0; i < sortedRows.length; i++) {
    if (sortedRows[i].ttcId === ttcId) {
      return i;
    }
  }
  return -1;
}

export const toFixed = (value: any, precision: number) => {
  if (value == null || value === undefined) {
    return null;
  } else if (isNaN(value)) {
    return Number(value).toFixed(precision);
  } else {
    let valueNumber: Number = value;
    return valueNumber.toFixed(precision);
  }
}


