import "../admin.scss";
import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label, Input } from "reactstrap";

import { updateGeeki } from "../../../reducers/admin-reducer";
import { IRootState } from "../../../reducers";
import { adminLayout, alertResult } from "../util/admin-utils";
import { WithRouterProps } from "../../../shared/util/withRouter";
import { IGeekiEdit } from "../../../shared/model/geeki.model.admin";
import { getAdminGeekiEntity, getAdminGeekiEntities } from "../../../reducers/admin-reducer";
import AdmLiquibaseIndicator from "../AdmLiquibaseIndicator";
import { Link } from "react-router-dom";
import AdminNotAuthenticated from "../AdminNotAuthenticated";
import { isUserAuthenticated } from "../../../reducers/authentication";


interface IProps extends StateProps, DispatchProps {
  isInProduction:boolean
}
type Props = IProps & WithRouterProps<IProps>;

interface IState {
    isNewVersion: boolean
}

class AdminGeekiEdit extends React.Component<Props,IState> {
  actId;

  longRef;
  titleRef;
  imgRef;
  urlRef;
  youtubeRef;
  indicatorRef;
  
  geekiEntity;
  geekies;
  entity: IGeekiEdit;



  constructor(props: any) {
    super(props);
    this.entity=null;
    this.state={isNewVersion:false};

    this.longRef = React.createRef();
    this.titleRef = React.createRef();
    this.imgRef = React.createRef();
    this.urlRef = React.createRef();
    this.youtubeRef = React.createRef();
    this.indicatorRef = React.createRef();

    this.loadEntity();
  }

  loadEntity = async () => {
    const hash = window.location.hash; // Obtient la partie d'ancrage de l'URL (#/admin/geeki/edit/123)
    this.geekies = (await (this.props as any).getAdminGeekiEntities()).value.data;
    if(hash.endsWith("create")){
      this.entity={};
    }else{
      const parts = hash.split('/'); // Divise la partie d'ancrage en segments
      const value = parts[parts.length - 1]; // Récupère la dernière partie de la division
      const entity: any = await (this.props as any).getAdminGeekiEntity(value);
      this.entity=entity.value.data;
    }    
    this.setState({isNewVersion:this.state.isNewVersion});///to force the render
  }


  setIsNewVersion = (isNew: boolean) => {
    this.setState({isNewVersion:isNew});
  }

  updateInFile = async () => {
    this.entity.firstName=this.filterValue(this.entity.firstName);
    this.entity.login=this.filterValue(this.entity.login);


    const result = await this.props.updateGeeki(this.entity);
    alertResult(result);
  }

  updateField = (fieldName,event) => {
    this.entity[fieldName] = event.target.value;
  }
  updateActId = (event) => {
    this.actId = event.target.value;
  }
  handleReloadEntityKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.reloadEntity();
    }
  };
  reloadEntity = async () => {
    let newId = this.actId;
    window.location.replace('/#/admin/geeki/edit/' + newId);
    const entity: any = await (this.props as any).getAdminActivityEntity(newId);
    this.entity = entity.value.data;
    this.setState({ isNewVersion: this.state.isNewVersion });///to force the render
  }



  removeLinkTags = (text: string) => {
    const regex = /<Link[^>]*>(.*?)<\/Link>/gi;
    if(text != null && text !== undefined){
      return text.replace(regex, '$1');
    }else{
       return "";
    }
  }
  
  

   handleLoadDesc = async () => {
   }
   

  callbackFunctionGetGeeki = obj => {
    this.geekiEntity = obj;
    this.handleLoadDesc();
  };

  filterValue(value) {
    if (value != null && value.indexOf(";") > -1) {
      alert("error: " + value);
      return "";
    } else {
      return value;
    }
  }


  render() {
    if (!isUserAuthenticated()) {
      return <AdminNotAuthenticated/>;
    } else {
    if(this.entity){
    return adminLayout(this.entity.id?"Edition d'un Geeki":"Saisie d'un Geeki",
    <>
    <Row className="justify-content-center width100Perc">
      <Col md="12">
        <Row className="justify-content-center width100Perc">
          <Col md="3">
            <Input
              id="ng-actid"
              type="text"
              className="form-control"
              name="actid"
              defaultValue={this.entity ? this.entity.id : ''}
              key={'act-' + this.entity.id + '-id-' + Math.random()}
              onChange={this.updateActId}
              onKeyUp={this.handleReloadEntityKeyPress}
            />
            <Button onClick={this.reloadEntity}
              className="geekiButton">Charger</Button>
               <Link to={'/admin/geeki/edit/description/'+this.entity.login}
              className="geekiButton">Description</Link>
          </Col>
          <Col md="4">
            <Row className="justify-content-center">

              <Col md="9"><AdmLiquibaseIndicator ref={this.indicatorRef}
                fctSetNewVersion={this.setIsNewVersion} /></Col>
              <Col md="3">
                <Button onClick={this.updateInFile}
                  className="geekiButton"
                  disabled={!this.state.isNewVersion}>Mettre à jour fichier</Button></Col>
            </Row>
          </Col>
        </Row>



        <Row className="justify-content-center width100Perc">
          <Col md="3">
            <Label for="news-id">ID</Label>
            <Input
              id="ng-id"
              type="text"
              className="form-control"
              name="id"
              defaultValue={this.entity.id}
              key={this.entity.id + '-id-' + Math.random()}
              disabled={true}
            />
          </Col>          
          {this.renderDateField(3,'Date création',"createdDate")}
          {this.renderDateField(3,'Date modification',"modifiedDate")}
          {this.renderDateField(3,'Date validation',"validatedDate")}
          </Row>
          <Row className="justify-content-center width100Perc">
          <Col md="3">
            <Label for="news-title">Titre</Label>
            <Input
              id="ng-title"
              type="text"
              className="form-control"
              name="title"
              key={this.entity.id + '-title-' + Math.random()}
              defaultValue={this.entity.title}
              disabled={true}
            />
          </Col>
          {this.renderDateField(3,'Date naissance',"birthDate")}
          {this.renderDateField(3,'Date décès',"deathDate")}
          </Row>

          

          <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Login',"login")}
          {this.renderField(3,'Geeki Type',"geekiType")}
          {this.renderField(3,'Sexe',"sex")}
          {this.renderField(3,'Site(s) web',"url")}

          </Row>
          
          <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Prénom',"firstName")}
          {this.renderField(3,'Nom de famille',"lastName")}
          {this.renderField(3,'Surnom',"nickName")}
          {this.renderField(3,'Nom',"name")}
        </Row>
          <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Facebook',"facebook")}
          {this.renderField(3,'X ex Twitter',"twitter")}
          {this.renderField(3,'Instagram',"instagram")}
          {this.renderField(3,'Twitch',"twitch")}
            </Row>
            <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Youtube',"youtube")}
          {this.renderField(3,'Dailymotion',"dailymotion")}
          {this.renderField(3,'Tiktok',"tiktok")}
          {this.renderField(3,'Imdb',"imdb")}
            </Row>
            <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Wikipedia',"wiki")}
          {this.renderField(3,'Nolife Wiki',"nolifeWiki")}
          {this.renderField(3,'Discord',"discord")}
          {this.renderField(3,'LinkedIn',"linkedIn")}
              </Row>
              <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Pinterest',"pinterest")}
          {this.renderField(3,'Flux rss',"rss")}
          {this.renderField(3,'SoundCloud',"soundCloud")}
          {this.renderField(3,'PodCloud',"podCloud")}
              </Row>
              <Row className="justify-content-center width100Perc">
          {this.renderField(3,'Tipeee',"tipeee")}
          <Col md="3"></Col>
              </Row>
      </Col>
    </Row>
  </>,this.props.isInProduction);
    }else{
      return "";
    }
    };      
  }

  renderField(col,label,name){
    return (<Col md={col}>
    <Label for={"news-"+name}>{label}</Label>
    <Input
      id={"ng-"+name}
      type="text"
      className="form-control"
      name={name}
      key={this.entity.id + '-'+name+'-' + Math.random()}
      defaultValue={this.entity[name]}
      onChange={(event) => this.updateField(name, event)}
      required
    />
  </Col>);
  }

  renderDateField(col,label,name){
    return (<Col md={col}>
    <Label for={"news-"+name}>{label}</Label>
    <Input
      id={"ng-"+name}
      type="text"
      className="form-control"
      name={name}
      key={this.entity.id + '-'+name+'-' + Math.random()}
      defaultValue={this.entity[name]}
      onChange={(event) => this.updateField(name, event)}
      required
    />
  </Col>);
  }
}

const mapStateToProps = ({ authentication,geeki,applicationProfile }: IRootState) => ({
  account: authentication.account,
  geekiEntity: geeki.entity,
  isInProduction: applicationProfile.inProduction
});

const mapDispatchToProps = {
  getAdminGeekiEntity,
    updateGeeki,
    getAdminGeekiEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminGeekiEdit);
