import React from "react";
import ActivityTimeLine from "./ActivityTimeLine";
import GeekiSkeleton from "../widget/image/GeekiSkeleton";

interface IProps {
  entity: any;
  parent: any;
  tabIndex: number;
  page: string;
  geekies: any[];
  isInProduction: boolean;
}
interface IState {
  login: string;
  resultTimeline: any[];
}

export default class Activity extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      resultTimeline: []
    };
  }

  componentDidMount() {
    this.extractActivities(this.props.entity.activities);
    this.extractTimeLine(this.props.entity.activities);
  }
  componentDidUpdate() {
    if (this.props.entity.login !== this.state.login) {
      this.extractActivities(this.props.entity.activities);
    }
  }

  render() {
    if (this.props.entity) {
      return (
        <div className="activity" key={"act" + Math.random() + "-" + Math.random()}>
          {this.state.resultTimeline}
        </div>
      );
    } else {
      return <GeekiSkeleton key={"act" + Math.random() + "-" + Math.random()} />;
    }
  }



  extractActivities(activities) {
    let resultTimeline = [];
    let activity;
    let types = [];
    for (let i = 0; i < activities.length; i++) {
      activity = activities[i];

      let obj;
      if (types.length === 0) {
        obj = { array: [], type: activity.type };
        types.push(obj);
      } else {
        let trouve = false;
        for (let i = 0; i < types.length; i++) {
          if (activity.type === types[i].type) {
            trouve = true;
            obj = types[i];
          }
        }
        if (!trouve) {
          obj = { array: [], type: activity.type };
          types.push(obj);
        }
      }
      obj.array.push(activity);
    }
    resultTimeline.push(
      <ActivityTimeLine
        key={"act" + Math.random() + "-" + Math.random()}
        page={this.props.page}
        parent={this.props.parent}
        tabIndex={this.props.tabIndex}
        activities={activities}
        sex={this.props.entity.sex}
        geekiLogin={this.props.entity.login}
        geekiType={this.props.entity.geekiType}
        geekies={this.props.geekies}
        isInProduction={this.props.isInProduction}
      />
    );
    this.setState({
      login: this.props.entity.login,
      resultTimeline: resultTimeline
    });
  }

  extractTimeLine(activities) {
    let resultTimeline = [];
    let activity;
    let types = [];
    for (let i = 0; i < activities.length; i++) {
      activity = activities[i];

      let obj;
      if (types.length === 0) {
        obj = { array: [], type: activity.type };
        types.push(obj);
      } else {
        let trouve = false;
        for (let i = 0; i < types.length; i++) {
          if (activity.type === types[i].type) {
            trouve = true;
            obj = types[i];
          }
        }
        if (!trouve) {
          obj = { array: [], type: activity.type };
          types.push(obj);
        }
      }
      obj.array.push(activity);
    }
    resultTimeline.push(
      <ActivityTimeLine
        key={"act" + Math.random() + "-" + Math.random()}
        page={this.props.page}
        parent={this.props.parent}
        tabIndex={this.props.tabIndex}
        activities={activities}
        sex={this.props.entity.sex}
        geekiLogin={this.props.entity.login}
        geekiType={this.props.entity.geekiType}
        geekies={this.props.geekies}
        isInProduction={this.props.isInProduction}
      />
    );
    this.setState({
      login: this.props.entity.login,
      resultTimeline: resultTimeline
    });
  }
}
