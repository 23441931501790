import axios from "axios";
import { Storage } from "../lib/storage-util";

import {
  REQUEST,
  SUCCESS,
  FAILURE
} from "./action-type.util";

export const ACTION_TYPES = {
  LOGIN: "authentication/LOGIN",
  GET_SESSION: "authentication/GET_SESSION",
  LOGOUT: "authentication/LOGOUT",
  CLEAR_AUTH: "authentication/CLEAR_AUTH",
  ERROR_MESSAGE: "authentication/ERROR_MESSAGE"
};

export const AUTH_TOKEN_KEY = "geeki-authenticationToken";

const initialState = {
  loading: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as any,
  errorMessage: null as string, // Errors returned from server side
  redirectMessage: null as string,
  sessionHasBeenFetched: false,
  idToken: null as string,
  logoutUrl: null as string,
  darkMode: false
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (
  state: AuthenticationState = initialState,
  action
): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      console.log(action.payload.data);
      return {
        ...state,
        loading: false,
        loginError: false,
        showModalLogin: false,
        loginSuccess: true
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      return {
        ...state,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({
  type: ACTION_TYPES.ERROR_MESSAGE,
  message
});

export const getSession = () => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get("api/account")
  });

  const { account } = getState().authentication;
};

export const login = (username, password, rememberMe = false) => async (
  dispatch,
  getState
) => {

  console.log("TODO CST login 1");
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post("api/authenticate", { username, password, rememberMe })
  });
  console.log("TODO CST login 2");
console.log(result.value);
console.log("TODO CST login 3");
  let bearerToken = result.value.headers.authorization;
  if(bearerToken === undefined){
   bearerToken=result.value.data["id_token"];
  }
  console.log(bearerToken);
  console.log("TODO CST login 4");

  if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
    
  console.log("TODO CST login 4.1");
    const jwt = bearerToken.slice(7, bearerToken.length);
  //  if (rememberMe) {
      Storage.local.set(AUTH_TOKEN_KEY, jwt);
  //  } else {
    //  Storage.session.set(AUTH_TOKEN_KEY, jwt);
    //}
  }else if(bearerToken){
    Storage.local.set(AUTH_TOKEN_KEY, bearerToken);
  }
  console.log("TODO CST login 5");
  await dispatch(getSession());
};

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};

export const logout = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT
  });
};

export const clearAuthentication = messageKey => (dispatch, getState) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
};

export const isUserAuthenticated = () =>{
  let userAuthenticated =Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY);
  return userAuthenticated && userAuthenticated !== undefined;
};
