import "../admin.scss";
import React from "react";
import LazyLoad from 'react-lazyload';
import { includesUpperCaseWithoutAccents } from "../../../shared/util/string-utils";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

interface IProps {
  removeMethod?: any;
}
interface IState {
  entityList: any;
}

export default class AdminActivityList extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      entityList: null
    };
  }

  setIsNewVersion(isNew: boolean) {
  }

  updateEntityList(entityList) {
    this.setState({ entityList: entityList });
  }

  filterEntities(entityList, filter) {
    if (entityList) {
      let filteredEntityList = [];
      for (let i = 0; i < entityList.length; i++) {
        let entity = entityList[i].entity;
        let isValid = true;
        if (filter.title != null && filter.title !== undefined && filter.title !== '') {
          if (!includesUpperCaseWithoutAccents(entity.title, filter.title)) {
            isValid = false;
          }
        }
        if (filter.id != null && filter.id !== undefined && filter.id !== '') {
          if (!includesUpperCaseWithoutAccents(entity.id, filter.id)) {
            isValid = false;
          }
        }
        if (filter.geekiLogin != null && filter.geekiLogin !== undefined && filter.geekiLogin !== '') {
          if (!includesUpperCaseWithoutAccents(entity.geekiLogin, filter.geekiLogin)) {
            let isRolesValid = false;
            for (let i = 0; i < entity.roles.length; i++) {
              let activityRole = entity.roles[i];
              if (includesUpperCaseWithoutAccents(activityRole.geekiLogin, filter.geekiLogin)) {
                isRolesValid = true;
              }
            }
            if (isValid && (!isRolesValid)) {
              isValid = false;
            }
          }
        }
        /*
        if(dateFrom !=null && dateFrom!== undefined && dateFrom!==''){
            let date=new Date(convention.startDate);
            if(date <dateFrom){
                isValid=false;
            }
        }   

        if(city !=null && city!== undefined && city!==''){
            if(!includesUpperCaseWithoutAccents(convention.city,city)){
                isValid=false;
            }
        }
        if(postalCode !=null && postalCode!== undefined && postalCode!==''){
            if(!includesUpperCaseWithoutAccents(convention.postalCode,postalCode)){
                isValid=false;
            }
        }
        */
        if (isValid) {
          filteredEntityList.push(entityList[i]);
        }
      }
      this.setState({ entityList: filteredEntityList });
    }
  }

  handleRemoveSpecificRow = (entityId) => () => {
    this.props.removeMethod(entityId);
  }

  render() {
    return (
      <div className="width100Perc">
        <LazyLoad height={200} offset={[-100, 0]} key={"n2ccLL-0"}>
          {this.renderEntities()}
        </LazyLoad>
      </div>);
  }

  renderEntities() {
    return (this.state.entityList && this.state.entityList.length > 0 ? (
      <Table responsive aria-describedby="playlist-heading">
        <thead>
          <tr>
            <th>ID</th>
            <th>Titre</th>
            <th>Type</th>
            <th>Date</th>
            <th>Geeki</th>
            <th>Url</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.entityList.map((entity, i) => (
            <tr key={`entity-${i}`}>
              <td><Link to={"/admin/activity/edit/" + entity.entity.id}>{entity.entity.id}</Link></td>
              <td>{entity.entity.title}</td>
              <td>{entity.entity.type}</td>
              <td>{entity.entity.from}</td>
              <td>{entity.entity.geekiLogin}</td>
              <td>{entity.entity.url}</td>
            </tr>
          ))}
        </tbody>
      </Table>) : <div>Aucune Activitée trouvée</div>);
    /*
            <td>
                <button
                  className="btn btn-outline-danger btn-sm geekiButton remove"
                  onClick={this.handleRemoveSpecificRow(entity.entity.id)}
                >
                  Supprimer
                </button></td>*/
  }

}