import { SUCCESS } from "./action-type.util";
import { axiosGet } from "../shared/util/url-utils";

export const ACTION_TYPES = {
  GET_PROFILE: "applicationProfile/GET_PROFILE"
};

const initialState = {
  ribbonEnv: "",
  inProduction: true,
  isSwaggerEnabled: false
};

export type ApplicationProfileState = Readonly<typeof initialState>;

export default (
  state: ApplicationProfileState = initialState,
  action
): ApplicationProfileState => {
  switch (action.type) {
    case SUCCESS(ACTION_TYPES.GET_PROFILE):
      const { data } = action.payload;
      return {
        ...state,
        ribbonEnv: data["display-ribbon-on-profiles"],
        //inProduction: data.profiles.active.includes("prod"), TODO CST fix
        //inProduction: false,
        inProduction: data["inProduction"],
        isSwaggerEnabled: false
      };
    default:
      return state;
  }
};

export const getProfile = () => ({
  type: ACTION_TYPES.GET_PROFILE,
  payload: axiosGet("management/info")
});
