import axios from "axios";
/*
export const imgUrl = (path,isInProduction) =>
isInProduction?("https://www.geeki.fr"+path):("http://localhost:8080"+path);
*/
export const imgUrl = (path,isInProduction) =>
"https://www.geeki.fr"+path;

  export const routeUrl = path =>
  (path.indexOf("/#")>-1?path:"/#"+path);//TODO CST fix

export const axiosGet = (path) => axios.get(path);