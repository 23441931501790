export interface ILinkStats {
  id?: number;
  entityLogin?: string;
  entityType?: string;
  name?: string;
  page?: number;
  facebook?: number;
  twitter?: number;
  wiki?: number;
  nolifeWiki?: number;
  instagram?: number;
  dailymotion?: number;
  twitch?: number;
  pinterest?: number;
  linkedIn?: number;
  tipeee?: number;
  imdb?: number;
  tiktok?: number;
  soundCloud?: number;
  podCloud?: number;
  discord?: number;
  youtube?: number;
}

export const defaultValue: Readonly<ILinkStats> = {};
