import "./SkeletonImage.scss";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface ItProps {
  width?: any;
  height?: number;
  className?: string;
}

export default class GeekiSkeleton extends React.Component<ItProps> {

  render() {
    return (
      <SkeletonTheme baseColor="#DFDFDF" highlightColor="#DFDFDF">
        <Skeleton
          width={this.props.width}
          height={this.props.height}
          className={this.props.className}
        />
      </SkeletonTheme>
    );
  }
}
